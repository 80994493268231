import React, { useEffect } from "react";
import UserForm from "../../components/User/UserForm";
import classes from "../Background.module.css";
import { json, useActionData, useNavigate } from "react-router-dom";
import axios from "axios";
import { getAuthToken } from "../../utils/auth";
import { useDispatch } from "react-redux";
import { alert } from "../../utils/alert";

const AddUserPage = () => {
  const data = useActionData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //useEffect to check when a new user is added successfully
  useEffect(() => {
    if (data && data.status === 200) {
      //set an alert
      alert("Added User Successfully!", "green", dispatch);
      //navigate back
      navigate("..");
    }
  }, [data, navigate, dispatch]);

  return (
    <main className={classes.main}>
      <UserForm method="post" data={data} title={"Add User"} />
    </main>
  );
};

export default React.memo(AddUserPage);


//Add user action
export const action = async ({ request }) => {
  const data = await request.formData();
  const token = getAuthToken();
  const apiURL = process.env.REACT_APP_API_URL



  //Building the request body from the form data
  const requestBody = {
    firstname: data.get("firstName").trim(),
    lastname: data.get("lastName").trim(),
    email_addr: data.get("email").trim(),
    company: data.get("company"),
    role: data.get("role"),
    contact_number: data.get("contactNumber").trim(),
  };


  //API function to add a user
  const response = await axios
    .post(
      `${apiURL}/registration`,
      requestBody,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const errorObject = {
        status: error.response.status,
      };
      return errorObject;
    });
  console.log(response);
  if (response.status !== 200) {
    let message;
    if (response.status === 400) {
      message = "E-mail is already used";
    }
    if (response.status === 401) {
      message = "Unauthorized Request";
    }
    return json({
      message: message,
      status: response.status,
    });
  }

  return response;
};
