import React from "react";
import ReactDOM from "react-dom";
import classes from "./FormLoading.module.css";

//Loading component when submitting new contracts
const FormLoading = () => {
  return (
    <>
      {ReactDOM.createPortal(
        <div className={classes["loading-container"]}>
          <div className={classes["loading-spinner"]}></div>
          <h2>Submitting Contract</h2>
        </div>,
        document.getElementById("backdrop-root")
      )}
    </>
  );
};

export default FormLoading;
