import React, { useState } from "react";
import RateCardTable from "../../components/RateCard/RateCardTable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getAuthToken, getTokenData } from "../../utils/auth";
import { alert } from "../../utils/alert";
import { useDispatch, useSelector } from "react-redux";
import Papa from "papaparse";
import { loadRateCard } from "../../pages/Manager/RateCardPage";
import classes from "./RateCard.module.css";

const RateCard = (props) => {
  const [year, setYear] = useState(Number(new Date().getFullYear()));
  const [data, setData] = useState(props.data);
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL
  const { name, company } = getTokenData();


  const addYearClickHandler = () => {
    //Check if the rate card of the year has no values, if true set year to current year
    if (data[year].length === 0) {
      console.log("close");
      const keys = Object.keys(data);
      const lastKey = keys[keys.length - 1];
      const { [lastKey]: _, ...newObj } = data;
      setData(newObj);
      setYear(Number(new Date().getFullYear()));
      return;
    }
    //Adds another year to data
    const newYear =
      Math.max(...Object.keys(data).map((num) => parseInt(num))) + 1;
    setYear(newYear);
    setData({ ...data, [newYear]: [] });
  };

  const updateClickHandler = () => {
    setIsUpdating(!isUpdating);
  };

  const uploadFileHandler = async (csvData) => {
    let method = "post";
    if (isUpdating) {
      method = "put";
    }
    const requestBody = {
      year: year,
      ratecards: csvData,
    };


    const token = getAuthToken();
    //API call to upload csv data to database
    //If method is put deletion of previous rate card occurs first
    //If method is post a new rate card is uploaded
    const response = await axios[method](
      `${apiURL}/ratecard`,
      requestBody,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    let message = "Uploaded Rate Card Successfully";
    if (isUpdating) {
      message = "Updated Rate Card Successfully";
    }

    if (response.status === 200) {
      const resData = await loadRateCard();
      alert(message, "green", dispatch);
      console.log(resData);
      setData(resData);
      setYear(Number(new Date().getFullYear()));
      setIsUpdating(false);
    }
  };

  const downloadClickHandler = (data) => {
    //Logs Download
    fetch(`${apiURL}/analytics/savedlhistory`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          "content": `Rate Card downloaded by ${name}`,
          "company": `${company}`
      })
  })
  
     .then(response => {
      if (!response.ok) {
          throw new Error('Failed to log download');
        }
        console.log('Download logged successfully');
      })
      .catch(error => {
        console.error('Error logging download:', error);
      });
      //Logs Download end

    const csvString = Papa.unparse(data);
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Rate Card Year - ${year}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const downloadTemplateClickHandler = () => {
    const base64String =
      "ZGVzY3JpcHRpb24sbW9udGhseSxxdWFydGVybHksc2VtaWFubnVhbCxhbm51YWwsY2F0ZWdvcnkKRGVzY3JpcHRpb24gb2YgdGhlIEFzc2V0LE1vbnRobHkgUmF0ZSBpbiBOdW1iZXIsUXVhcnRlcmx5IFJhdGUgaW4gTnVtYmVyLFNlbWlhbm51YWwgUmF0ZSBJbiBOdW1iZXIsQW5udWFsIFJhdGUgSW4gTnVtYmVyLFRyYWluL1N0YXRpb24vT3RoZXJz";
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = `Rate Card Template`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const userRole = useSelector((state) => state.user.role);

  if (!data[year]) {
    return (
      <div>
        <p style={{ margin: "auto", textAlign: "center" }}>
          Current Year Does Not Exist
        </p>
        <RateCardTable
          data={!isUpdating ? data[year] : null}
          uploadFile={uploadFileHandler}
        />
      </div>
    );
  }

  return (
    Object.keys(data).length !== 0 && (
      <>
        <div className={classes.menu}>
          <div
            className={`${classes.block}  ${
              data[year].length === 0 || isUpdating
                ? classes["margin-auto"]
                : ""
            }`}
          >
            <div className={classes.actions}>
              {userRole === "UTASI_MANAGER" && (
                <>
                  {!isUpdating && (
                    <button
                      onClick={addYearClickHandler}
                      className={
                        data[year].length !== 0 ? classes.add : classes.cancel
                      }
                    >
                      {data[year].length !== 0 ? "Add Year" : "Cancel"}
                    </button>
                  )}
                  {data[year].length !== 0 && (
                    <button
                      onClick={updateClickHandler}
                      className={isUpdating ? classes.cancel : classes.update}
                    >
                      {isUpdating ? "Cancel" : "Update"}
                    </button>
                  )}
                </>
              )}
              <select
                disabled={data[year].length === 0 || isUpdating}
                value={year}
                onChange={(e) => {
                  setYear(Number(e.target.value));
                }}
              >
                {Object.keys(data).map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {userRole === "UTASI_MANAGER" && (
              <button
                className={classes.template}
                onClick={downloadTemplateClickHandler}
              >
                Download Template
              </button>
            )}
          </div>
          {data[year].length !== 0 && !isUpdating && (
            <div className={classes.navigation}>
              <button onClick={() => navigate("..")}>Back to Main Menu</button>

              <button onClick={() => downloadClickHandler(data[year])}>
                Download
              </button>
            </div>
          )}
        </div>
        <RateCardTable
          data={!isUpdating ? data[year] : null}
          uploadFile={uploadFileHandler}
        />
      </>
    )
  );
};
export default RateCard;
