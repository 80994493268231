import axios from "axios";
import React, { useEffect, useState } from "react";
import useInput from "../../hooks/useInput";
import CryptoJS from "crypto-js";
import { useSubmit } from "react-router-dom";
import { alert } from "../../utils/alert";
import { useDispatch } from "react-redux";
import classes from "./ChangePasswordForm.module.css";
const key = process.env.REACT_APP_KEY;
const apiURL = process.env.REACT_APP_API_URL



const ChangePasswordForm = ({ email, maskedEmail, otpValidity }) => {
  const [otp, setOtp] = useState("");
  const [isValidOtp, setIsValidOtp] = useState(otpValidity);
  const [oldPass, setOldPass] = useState("");
  const submit = useSubmit();
  const dispatch = useDispatch();
  

  //Function to verify if the entered OTP matches the sent OTP
  const verifyClickHandler = async (event) => {
    event.preventDefault();
    if (!otp) {
      return;
    }
    const requestBody = { email_addr: email, code: otp };
    const response = await axios.post(
      `${apiURL}/auth/confirmchangepass`,
      requestBody
    );
    const validity = response.data.isCorrectCode;
    if (validity) {
      setOldPass(CryptoJS.AES.encrypt(response.data.oldpass, key).toString());
      setIsValidOtp(true);
    } else {
      setOtp("");
      setIsValidOtp(false);
    }
  };

  //Function to request for an OTP
  const requestClickHandler = () => {
    //Extra validation to add cooldown to OTP to avoid spam
    if (countdown !== 0) {
      return;
    }
    setCountdown(120);
    const requestBody = { email_addr: email };
    axios.post(
      `${apiURL}/auth/reqchangepass`,
      requestBody
    );
    setIsValidOtp(undefined);
  };
  //Field validation for not empty
  const isNotEmpty = (value) => value.trim() !== "";
  //Field validation to follow the requested password requirements
  const isStrong = (value) => {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*=_+<>{}/]).{8,}$/.test(
      value
    );
  };

  //Using custom hook useInput for input fields functions
  const {
    value: newPassValue,
    isValid: newPassIsValid,
    hasError: newPassHasError,
    valueChangeHandler: newPassChangeHandler,
    inputBlurHandler: newPassBlurHandler,
    reset: resetNewPass,
  } = useInput(isNotEmpty && isStrong);
  const {
    value: confirmPassValue,
    isValid: confirmPassIsValid,
    hasError: confirmPassHasError,
    valueChangeHandler: confirmPassChangeHandler,
    inputBlurHandler: confirmPassBlurHandler,
    reset: resetConfirmPass,
  } = useInput((value) => value === newPassValue && isNotEmpty);

  let passwordIsValid = false;
  let passwordIsOld = false;

  //Checks if passwords are valid on every update
  if (confirmPassIsValid && newPassIsValid) {
    const oldpass = CryptoJS.AES.decrypt(oldPass, key).toString(
      CryptoJS.enc.Utf8
    );
    if (oldpass === newPassValue) {
      passwordIsOld = true;
    } else {
      passwordIsValid = true;
    }
  }

  //Function called to update the password in the database
  const updatePasswordSubmitHandler = async (event) => {
    event.preventDefault();
    //Validation to check if password is valid
    if (!passwordIsValid) {
      return;
    }

    const requestBody = {
      email_addr: email,
      newpass: newPassValue,
    };
    //Api call to update the user's password
    await axios.post(
      `${apiURL}/auth/changepass`,
      requestBody
    );
    //useInput functions to reset the values of the fields
    resetNewPass();
    resetConfirmPass();

    //Submit function to logout the user and an alert function to display an alert
    alert("Password Change Successfully", "green", dispatch);
    return submit(null, { action: "/logout", method: "post" });
  };

  const [countdown, setCountdown] = useState(60);


  //useEffect for the countdown to decrease every second
  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => setCountdown(countdown - 1), 1000);
    }
  }, [countdown]);
  return (
    <>
      {!isValidOtp && (
        <form className={classes["otp-form"]}>
          <h1>
            To change your password, please enter the OTP sent to your email.
          </h1>
          <p className={classes.code}>a code has been sent to {maskedEmail}</p>
          {isValidOtp === false && (
            <p className={classes.invalid}>Invalid OTP</p>
          )}
          <input
            id="otp"
            name="otp"
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => {
              setIsValidOtp(undefined);
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
              setOtp(e.target.value.toUpperCase());
            }}
            required
          />
          <h2 className={classes.resend}>
            Didn't receive a code?
            <span
              onClick={requestClickHandler}
              className={countdown !== 0 ? classes["disabled-span"] : ""}
            >
              {countdown === 0 ? " Resend" : ` Resend in ${countdown}s`}
            </span>
          </h2>
          <button disabled={otp.trim() === ""} onClick={verifyClickHandler}>
            Verify
          </button>
        </form>
      )}
      {isValidOtp && (
        <form
          onSubmit={updatePasswordSubmitHandler}
          className={classes["otp-form"]}
        >
          <h1 htmlFor="newPassword">Change Password</h1>
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            placeholder="Enter New Password"
            onChange={newPassChangeHandler}
            onBlur={newPassBlurHandler}
            value={newPassValue}
            required
          />
          {newPassHasError && (
            <p className={classes.error}>
              {newPassValue
                ? "Password must be at least 8 characters and include a number, uppercase letter, lowercase letter, and special character."
                : "Password must not be empty."}
            </p>
          )}
          {passwordIsOld && (
            <p className={classes.error}>
              New password must not match with the current
            </p>
          )}
          <input
            id="confirmPassword"
            name="confirmPassword"
            type="password"
            placeholder="Confirm New Password"
            onChange={confirmPassChangeHandler}
            onBlur={confirmPassBlurHandler}
            value={confirmPassValue}
            required
          />
          {confirmPassHasError && (
            <p className={classes.error}>Password does not match</p>
          )}
          <button disabled={!passwordIsValid}>Update Password</button>
        </form>
      )}
    </>
  );
};

export default ChangePasswordForm;
