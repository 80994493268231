import React, { useEffect, useState } from "react";
import axios from "axios";
import classes from "./DashboardTable.module.css";
import { DASHBOARD_HEADERS, DASHBOARD_LABEL } from "../../utils/table-headers";
import { getTokenData } from "../../utils/auth";

const DashboardTable = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [apiData, setApiData] = useState([]);
  const percentageColumnKeys = ['per1', 'per2', 'per3'];
  const { name, company } = getTokenData();

  // Function to convert data to CSV
  const convertToCSV = () => {
    const csvData = [];

    csvData.push(DASHBOARD_HEADERS.map(header => header.header));

    DASHBOARD_LABEL.forEach(label => {
      const rowData = [label.label];
      DASHBOARD_HEADERS.slice(1).forEach(header => {
        rowData.push(apiDataMapping[label.key] && apiDataMapping[label.key][header.key]);
      });
      csvData.push(rowData);
    });

    const csvString = csvData.map(row => row.join(',')).join('\n');

    // Create a Blob and download the CSV file
    const blob = new Blob([csvString], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'dashboard_data.csv';
    link.click();

    fetch(`${apiURL}/analytics/savedlhistory`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          "content": `Dashboard Table List downloaded by ${name}`,
          "company": `${company}`
      })
  })
  
     .then(response => {
      if (!response.ok) {
          throw new Error('Failed to log download');
        }
        console.log('Download logged successfully');
      })
      .catch(error => {
        console.error('Error logging download:', error);
      });
  };

  // Function to convert data to CSV
  // const convertToCSV = () => {
  //   const csvData = [];

  //   csvData.push(DASHBOARD_HEADERS.map(header => header.header));

  //   DASHBOARD_LABEL.forEach(label => {
  //     const rowData = [label.label];
  //     DASHBOARD_HEADERS.slice(1).forEach(header => {
  //       rowData.push(apiDataMapping[label.key] && apiDataMapping[label.key][header.key]);
  //     });
  //     csvData.push(rowData);
  //   });

  //   const csvString = csvData.map(row => row.join(',')).join('\n');

  //   // Create a Blob and download the CSV file
  //   const blob = new Blob([csvString], { type: 'text/csv' });
  //   const link = document.createElement('a');
  //   link.href = URL.createObjectURL(blob);
  //   link.download = 'dashboard_data.csv';
  //   link.click();
  // };

  useEffect(() => {
    // Fetch the API data when the component mounts
    axios
      .get(`${apiURL}/analytics/gen_tbl`)
      .then((response) => {
        setApiData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching API data:", error);
      });
  }, {}); 

  // Create a mapping between API keys and dashboard headers and labels
  const apiDataMapping = apiData.reduce((result, item) => {
    const keyParts = item.key.split("_");
    const label = DASHBOARD_LABEL.find((label) => keyParts[0] === label.key);
    const header = DASHBOARD_HEADERS.find((header) => keyParts[1] === header.key);

    if (label && header) {
      if (!result[label.key]) {
        result[label.key] = {};
      }

      result[label.key][header.key] = item.value;
    }

    return result;
  }, {});

  
  return (
    <div className={classes.tableContainer}>
      <div className={classes.top}>
        <div>
          <button onClick={convertToCSV}>Download</button>
        </div>
      </div>
      <table className={classes.table}>
        <thead>
          <tr>
            {DASHBOARD_HEADERS.map((header) => (
              <th key={header.key} className={header.key === '' ? classes.grayColumn : ''}>
                {header.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {DASHBOARD_LABEL.map((label) => (
            <tr key={label.key}>
              <th className={classes.grayColumn}>{label.label}</th>
              {DASHBOARD_HEADERS.slice(1).map((header) => {
                const cellValue =
                  apiDataMapping[label.key] && apiDataMapping[label.key][header.key];

                const isPercentageColumn = percentageColumnKeys.includes(header.key);
                const numericValue = cellValue ? parseFloat(cellValue) : 0;

                let cellClassName = '';
                if (isPercentageColumn) {
                  if (numericValue > 0) {
                    cellClassName = classes.greenCell;
                  } else if (numericValue < 0) {
                    cellClassName = classes.redCell;
                  } else {
                    cellClassName = classes.blackCell;
                  }
                }

                return (
                  <td
                    key={header.key}
                    className={`${isPercentageColumn ? cellClassName : ''} ${
                      header.key === '' ? classes.grayColumn : ''
                    }`}
                  >
                    {cellValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardTable;
