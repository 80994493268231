import React, { useEffect, useState } from "react";
import classes from "./styles/RevenuePerStationPage.module.css";
import { useNavigate } from "react-router-dom";
import HorizontalBarChart from "../../components/Dashboard/HorizontalBarChart";
import RevenuePerStationDownload from "./RevDatePicker";
import Papa from "papaparse";


const RevenuePerStationPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [revStationData, setRevStationData] = useState({
    ytd: [],
    curmon: [],
  });
  const [selectedRadio, setSelectedRadio] = useState(0);
  const [title, setTitle] = useState(
    "YTD REVENUE - " +
      new Date().toLocaleDateString(Date, { year: "numeric", month: "long" })
  );
  const [ytdData, setYtdData] = useState([]);
  const [curmonData, setCurmonData] = useState([]);
  const navigate = useNavigate();
  const radioLabels = ["YTD", "Current Month"];
  const [selectedDate, handleDateChange] = React.useState(new Date());

  async function fetchData(isCurrentMonth) {
    const endpoint = isCurrentMonth ? "curmon" : "ytd";
    try {
      const response = await fetch(`${apiURL}/analytics/revperstation`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setRevStationData((prevData) => ({ ...prevData, [endpoint]: data[endpoint] }));
        if (!isCurrentMonth) {
          setYtdData(data.ytd);
        } else {
          setCurmonData(data.curmon);
        }
        /*const formattedApiData = formatApiData(data);
        setRevStationData((prevData) => ({ ...prevData, [endpoint]: formattedApiData[endpoint] }));
        if (!isCurrentMonth) {
          setYtdData(formattedApiData.ytd);
        } else {
          setCurmonData(formattedApiData.curmon);
        }*/
      } else {
        console.error("Error fetching data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData(selectedRadio === 1); // Fetch data when component mounts or when radio button changes
  }, [selectedRadio]);

  const radioInputChangeHandler = (event) => {
    const selectedIndex = parseInt(event.target.value, 10);
    setSelectedRadio(selectedIndex);

    if (selectedIndex === 1) {
      setTitle(
        "Revenue in Millions - " +
          new Date().toLocaleDateString(Date, { month: "long" })
      );
    } else {
      setTitle(
        "YTD REVENUE - " +
          new Date().toLocaleDateString(Date, {
            year: "numeric",
            month: "long",
          })
      );
    }
  };

  const backClickHandler = () => {
    navigate("..");
  };

  const downloadClickHandler = () => {
    const dataToDownload = selectedRadio === 0 ? revStationData.ytd : revStationData.curmon;
    const formattedData = dataToDownload.map(item => ({
      Station: item.label,
      Actual: parseFloat(item.value[0]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      Budget: parseFloat(item.value[1]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
      Percentage: item.value[2],
    }));
  
    const csvData = Papa.unparse(formattedData, {
      header: true,
      quotes: true,
    });
  
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
  
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "current_month_revenue_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  

  const [aspectRatio, setAspectRatio] = useState(null);
  const [reload, setReload] = useState(false);

  // useEffect that checks if the user is resizing
  useEffect(() => {
    function handleResize() {
      const { innerWidth, innerHeight } = window;
      const newAspectRatio = innerWidth / innerHeight;
      setAspectRatio(newAspectRatio);
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // useEffect that reloads the chart once the user is done resizing
  useEffect(() => {
    let identifier;

    if (aspectRatio !== null) {
      identifier = setTimeout(() => {
        setReload((r) => !r);
      }, 100);
    }

    return () => {
      clearTimeout(identifier);
    };
  }, [aspectRatio, setReload]);


  // useEffect that reloads the chart once the user is done resizing or when the data changes
  useEffect(() => {
    let identifier;

    if (aspectRatio !== null) {
      identifier = setTimeout(() => {
        setReload((r) => !r);
      }, 100);
    }

    return () => {
      clearTimeout(identifier);
    };
  }, [aspectRatio, setReload, selectedRadio, revStationData]);


  
  //chart arrangement
  const order = [
    "FERNANDO POE JR.", "BALINTAWAK", "MONUMENTO", "5TH AVENUE", "R. PAPA",
    "ABAD SANTOS", "BLUMENTRITT", "TAYUMAN", "BAMBANG", "DOROTEO JOSE",
    "CARRIEDO", "CENTRAL TERMINAL", "UNITED NATIONS", "PEDRO GIL",
    "QUIRINO AVENUE", "VITO CRUZ", "GIL PUYAT", "LIBERTAD", "EDSA",
    "BACLARAN", "REDEMPTORIST", "MIA", "ASIA WORLD", "NINOY AQUINO", "DR. SANTOS"
  ];

  const getChartDataInOrder = (chartData) => {
    return order.map((station) => {
      const stationData = chartData.find((item) => item.label === station);
      return stationData || { label: station, value: [0, 0, "0.00%"] };
    });
  };

  const orderedYtdData = getChartDataInOrder(revStationData.ytd);
  const orderedCurmonData = getChartDataInOrder(revStationData.curmon);

  //chart arrangement end

  return (
    <main className={classes.main}>
       <div className={classes.title}>
        <p></p>
        <div className={classes.buttons}>
          <button className={classes.back} onClick={backClickHandler} style={{marginBottom: "16px"}}>
            Back to Main Menu
          </button>
          <RevenuePerStationDownload />
          {/*<button onClick={downloadClickHandler}>Download Current Month</button>*/}
        </div>
      </div>

      <div className={classes.radiobuttons}>
        <div className={classes.radio}>
          {radioLabels.map((label, index) => (
            <label className={classes["radio-button"]} key={index}>
              <input
                type="radio"
                name="radio"
                defaultChecked={index === 0 ? true : false}
                value={index}
                onChange={radioInputChangeHandler}
              />
              {label}
            </label>
          ))}
        </div>
      </div>
      <div className={classes.container}>
      <p>Tick to display</p>
        <div className={classes.navigation}>
          {selectedRadio === 0 && revStationData.ytd.length === 0 ? (
            <p style={{marginTop:"40px", marginLeft: "37%", fontSize: "16px", fontWeight:"400"}}>No data available</p>
          ) : (
            <HorizontalBarChart
            key={`${reload}-${selectedRadio}`}
            chartData={selectedRadio === 0 ? orderedYtdData : orderedCurmonData}//revStationData.ytd : revStationData.curmon changed
            title={title}
          />
          )}
        </div>
          </div>

      <div className={classes.tableContainer}>
        <table className={classes.table}>
          <thead>
            <tr>
              <th>Station</th>
              <th>Actual</th>
              <th>Budget</th>
              <th>%</th>
            </tr>
          </thead>
          <tbody>
            {selectedRadio === 0 && revStationData.ytd.length === 0 ? (
              <tr>
                <td colSpan="4" style={{fontSize: "16px", fontWeight:"400"}}>No data available</td>
              </tr>
            ) : (
              (selectedRadio === 0 ? orderedYtdData : orderedCurmonData).map(//revStationData.ytd : revStationData.curmon changed
                (item, index) => (
                  <tr key={index}>
                    <td className={classes.label}>{item.label}</td>
                    <td className={classes.actual}>{parseFloat(item.value[0]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                    <td className={classes.budget}>{parseFloat(item.value[1]).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</td>
                    <td
                      className={
                        item.value[2] > 0
                          ? classes.green
                          : item.value[2] === 0
                          ? classes.black
                          : classes.red
                      }
                    >
                      {item.value[2]}
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default RevenuePerStationPage;