import axios from "axios";
import React, { useState } from "react";
import { useLoaderData } from "react-router-dom";
import UserTable from "../../components/UserTable";
import { getAuthToken } from "../../utils/auth";

const ManageUsersPage = () => {
  const [data, setData] = useState(useLoaderData());


  const updateData = async () => {
    //user loader function to get updated list
    const resData = await loader();
    //set updated data
    setData(resData);
  };

  return (
    <main>
      <UserTable
        data={data.users}
        refresh={Math.random()}
        update={updateData}
      />
    </main>
  );
};

export default ManageUsersPage;


//loader function to get all users of the system
export const loader = async () => {
  const token = getAuthToken();
  const apiURL = process.env.REACT_APP_API_URL

  const response = await axios.get(
    `${apiURL}/users`,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  return response.data;
};
