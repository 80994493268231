import React, { Suspense, useEffect, useState } from "react";
import { Await, defer, useActionData, useLoaderData } from "react-router-dom";
import SubmissionTable from "../../components/SubmissionTable";
import { getAuthToken, getTokenData } from "../../utils/auth";
import axios from "axios";
import Loading from "../../components/UI/Loading";
import { useDispatch } from "react-redux";
import { alert } from "../../utils/alert";
import { notify, pushHistory } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import classes from "./styles/ManSub.module.css";

const apiURL = process.env.REACT_APP_API_URL;

const ManageSubmissionsPage = () => {
  const { submissions } = useLoaderData();
  const actionData = useActionData();
  const dispatch = useDispatch();

  const apiURL = process.env.REACT_APP_API_URL;
  const [bvaData, setBvaData] = useState({
    Revenue: [],
    "Fill Rate": []
  });
  const [selectedData, setSelectedData] =  useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [fillRateData, setFillRateData] = useState([]);
   
  const radioLabels = ["Revenue", "Fill Rate"];
  const [selectedRadio, setSelectedRadio] = useState(0);

  const [aspectRatio, setAspectRatio] = useState(null);
  const [reload, setReload] = useState(false);
  // fetchData from API
  async function fetchData(isRevenue) {
    const endpoint = isRevenue ? "Revenue" : "Fill Rate";
    try {
      const response = await fetch(`${apiURL}/analytics/rev_bva`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setBvaData({ ...bvaData, [endpoint]: data[endpoint]});
        setSelectedData(isRevenue ? data.Revenue : data["Fill Rate"])
    } else {
        console.error("error fetching data");
      } 
    } catch (error) {
        console.error("Error fetching data:", error)
      }
    }

    useEffect(() => {
    fetchData(radioLabels[selectedRadio] === "Revenue");
  }, [selectedRadio])

  function radioInputChangeHandler(event) {
    setSelectedRadio(parseInt(event.target.value, 10));
  }

  useEffect(() => {
    function handleResize() {
      const { innerWidth, innerHeight } = window;
      const newAspectRatio = innerWidth / innerHeight;
      setAspectRatio(newAspectRatio);
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let identifier;

    if (aspectRatio !== null) {
      identifier = setTimeout(() => {
        setReload((r) => !r);
      }, 100);
    }

    return () => {
      clearTimeout(identifier);
    };
  }, [aspectRatio, setReload]);



  const navigate = useNavigate();

  function backClickHandler() {
    navigate("..");
  }


  function downloadClickHandler() {
    // Fetch both revenue and fill rate data
    const revenueData = bvaData.Revenue;
    const fillRateData = bvaData["Fill Rate"];
  
    // Combine data
    const combinedData = combineData(revenueData, fillRateData);
  
    // Convert combined data to CSV
    const csvData = convertDataToCSV(combinedData);
  
    // Create a Blob and create a link to trigger the download
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "currentmonth_data.csv";
    link.click();
  }
  
  function combineData(revenueData, fillRateData) {
    const combinedData = [];
  
    for (let i = 0; i < revenueData.length; i++) {
      combinedData.push({
        label: revenueData[i].label,
        revenueActual: revenueData[i].value[0],
        revenueBudget: revenueData[i].value[1],
        fillRateActual: fillRateData[i].value[0],
        fillRateBudget: fillRateData[i].value[1],
      });
    }
  
    return combinedData;
  }
  function convertDataToCSV(data) {
    // Example CSV format (customize based on your data structure)
    let csvString = "Label,Revenue_Actual,Revenue_Budget,FillRate_Actual,FillRate_Budget\n";
    data.forEach((item) => {
      csvString += `${item.label},${item.revenueActual},${item.revenueBudget},${item.fillRateActual},${item.fillRateBudget}\n`;
    });
  
    return csvString;
  }
  
  const [assetFileSubmitted, setAssetFileSubmitted] = useState(false);
  const [orFileSubmitted, setOrFileSubmitted] = useState(false);


  const handleAssetFileSubmit = () => {
    setAssetFileSubmitted(true);
  };

  const handleOrFileSubmit = () => {
    setOrFileSubmitted(true);
  };

  //useEffect to check successful action
useEffect(() => {
  if (actionData) {
    if (actionData.status === 200) {
      alert("Contract Action Successful!", "green", dispatch);
      if (actionData.data) {
        if (actionData.data.assetFileSubmitted) {
          handleAssetFileSubmit();
        }
        if (actionData.data.orFileSubmitted) {
          handleOrFileSubmit();
        }
      }
    } else {
      alert("Something went wrong!", "red", dispatch);
    }
  }
}, [actionData, dispatch]);


  return (
    <main>
      <div className={classes.buttons}>
          {/* <button onClick={downloadClickHandler}>Download</button> */}
      <Suspense fallback={<Loading />}>
      <Await resolve={submissions}>
      {(loadedSubmissions) => {
        console.log('loadedSubmissions', loadedSubmissions);
        return loadedSubmissions.length > 0 ? (
          <SubmissionTable 
          tableData={loadedSubmissions} 
          assetFileSubmitted={assetFileSubmitted}
          />
          ) : (
            <p>NO SUBMISSIONS</p>
            );
          }}
          </Await>
        </Suspense>
      </div>
    </main>
  );
};

export default ManageSubmissionsPage;

const LoadSubmissions = async () => {
  const token = getAuthToken();
  const { company, role } = getTokenData();
  

  let response;

  if (role === "ADMIN_ADMIN") {
    // If the user is an "ADMIN_ADMIN," fetch all submissions
    response = await axios.get(`${apiURL}/contract/submissions`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    // Apply role-based filtering
    response = await axios.get(`${apiURL}/contract/submissions`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const filteredSubmissions = response.data.filter((submission) => {
      if (company === "UTASI") {
        return submission.status !== "RETURNED";
      } else {
        return (
          submission.status !== "PENDING" &&
          submission.status !== "RETURNED" &&
          submission.status !== "DISAPPROVED"
        );
      }
    });

    let statusOrder;

    if (company === "UTASI") {
      statusOrder = {
        PENDING: 1,
        DISAPPROVED: 2,
        "FOR INVOICING": 3,
        INVOICED: 4,
        "FOR APPROVAL": 5,
        "ISSUED OR": 6,
        COMPLETE: 7,
        DONE: 8,
      };
    }
    if (company === "LRMC") {
      statusOrder = {
        PENDING: 7,
        DISAPPROVED: 5,
        "FOR INVOICING": 2,
        INVOICED: 3,
        "FOR APPROVAL": 1,
        "ISSUED OR": 4,
        COMPLETE: 5,
        DONE: 6,
      };
    }

    if (company === "ADMIN") {
      statusOrder = {
        PENDING: 7,
        DISAPPROVED: 5,
        "FOR INVOICING": 2,
        INVOICED: 3,
        "FOR APPROVAL": 1,
        "ISSUED OR": 4,
        COMPLETE: 5,
        DONE: 6,
      };
    }

    const sortedSubmissions = [...filteredSubmissions].sort((a, b) => {
      const statusA = a.status.toUpperCase();
      const statusB = b.status.toUpperCase();

      if (statusOrder[statusA] < statusOrder[statusB]) {
        return -1; // a should come before b
      } else if (statusOrder[statusA] > statusOrder[statusB]) {
        return 1; // b should come before a
      } else {
        const dateA = new Date(a.date_submitted);
        const dateB = new Date(b.date_submitted);
        return dateB - dateA; // sort by date
      }
    });

    return sortedSubmissions;
  }
};

export const loader = () => {
  return defer({ submissions: LoadSubmissions() });
};

export const action = async ({ request }) => {
  const data = await request.formData();
  const { uid, name } = getTokenData();
  const token = getAuthToken();

  let requestBody = {
    contract_number: data.get("contractNumber"),
    status: data.get("status"),
    submitted_by: data.get("submittedBy"),
  };
  if (data.get("feedback")) {
    requestBody = { ...requestBody, feedback: data.get("feedback") };
  }

   // Check if the action involves asset file submission
   const assetFileSubmitted = data.get("assetFileSubmitted") === "true";
   const orFileSubmitted = data.get("orFileSubmitted") === "true";

   if (assetFileSubmitted) {
    // Set the status to "INVOICED" for asset file submission
    requestBody.status = "INVOICED";
  } else if (orFileSubmitted) {
    // Set the status to "COMPLETE" for or file submission
    requestBody.status = "ISSUED OR";
  } else if (requestBody.status === "PENDING") {
    requestBody.status = "";
  }
  


  let notificationRequestBody = {
    sender: uid,
    contract_number: data.get("contractNumber"),
    receiver: "",
  };
  let contractHistoryMessage;

  switch (data.get("status")) {
    case "FOR APPROVAL": {
      contractHistoryMessage = `Contract has been approved by UTASI Manager ${name} and is now awaiting for LRMC approval`;
      notificationRequestBody = {
        ...notificationRequestBody,
        user_role: "LRMC_MANAGER",
        notification: `UTASI has sent Contract #${data.get(
          "contractNumber"
        )} for your approval`,
      };
      break;
    }
    case "RETURNED": {
      contractHistoryMessage = `Contract has been returned by UTASI Manager ${name} and has been assigned to Encoder ${data.get(
        "name"
      )} for resubmission`;

      notificationRequestBody = {
        ...notificationRequestBody,
        receiver: data.get("submittedBy"),
        user_role: "ENCODER",
        notification: `UTASI Manager ${name} has assigned you Contract #${data.get(
          "contractNumber"
        )}`,
      };
      break;
    }
    case "FOR INVOICING": {
      contractHistoryMessage = `Contract has been approved by LRMC Manager ${name} and is now for invoicing`;
      notificationRequestBody = {
        ...notificationRequestBody,
        // user_role: "UTASI_MANAGER",
        user_role: "ENCODER",
        notification: `LRMC Manager ${name} has approved Contract #${data.get(
          "contractNumber"
        )}`,
      };
      break;
    }
    case "DISAPPROVED": {
      contractHistoryMessage = `Contract has been disapproved by LRMC Manager ${name} and is due for changes`;
      notificationRequestBody = {
        ...notificationRequestBody,
        user_role: "UTASI_MANAGER",
        notification: `LRMC Manager ${name} has disapproved Contract #${data.get(
          "contractNumber"
        )}`,
      };
      break;
    }
    default:
      return null;
  }

  console.log(notificationRequestBody);
  notify(notificationRequestBody);
  console.log(contractHistoryMessage);
  let filter = "LRMC";
  if (data.get("status") === "FOR APPROVAL") {
    filter = "ALL";
  }
  if (data.get("status") === "RETURNED") {
    filter = "UTASI";
  }
  pushHistory({
    contract_number: data.get("contractNumber"),
    content: contractHistoryMessage,
    company: filter,
  });

  const response = await axios.patch(`${apiURL}/contract/status`, requestBody, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return response;
};
