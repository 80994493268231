import React from "react";
import classes from "./Loading.module.css";

//General loading component
const Loading = () => {
  return (
    <div className={classes["loading-container"]}>
      <div className={classes["loading-spinner"]}></div>
    </div>
  );
};

export default Loading;
