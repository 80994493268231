import React, { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import axios from "axios";
import Loading from "../components/UI/Loading";
import { getAuthToken } from "../utils/auth";
import ContractItem from "../components/ContractItem";
import ContractHistory from "../components/ContractHistory";
import { loadHistory } from "./Encoder/EditContractPage";


const ContractDetailPage = () => {
  const { contractData, contractHistory } = useLoaderData();
  return (
    <main>
      <Suspense fallback={<Loading />}>
        <Await resolve={contractData}>
          {(loadedContractData) => <ContractItem item={loadedContractData} />}
        </Await>
        <Await resolve={contractHistory}>
          {(loadedHistoryData) => (
            <ContractHistory history={loadedHistoryData} />
          )}
        </Await>
      </Suspense>
    </main>
  );
};

export default ContractDetailPage;

//API function to load the details of a contract submission based on the contract number
const loadContract = async (params) => {
  const token = getAuthToken();
  const apiURL = process.env.REACT_APP_API_URL


  const requestBody = {
    sales_order_code: params.contractNumber,
  };
  const response = await axios
    .post(
      `${apiURL}/contract`,
      requestBody,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .catch(function (error) {
      // handle error
      console.log(error);
    });
  console.log(response);
  console.log(response.data.contract);
  return {
    ...response.data.contract,
    start_date: new Date(response.data.contract.start_date)
      .toISOString()
      .split("T")[0],
      end_date: new Date(response.data.contract.end_date)
      .toISOString()
      .split("T")[0],
    assets: response.data.contract.assets.map((asset) => {
      return {
        ...asset,
        asset_date_created: new Date(asset.asset_date_created)
          .toISOString()
          .split("T")[0],
        asset_live_date: new Date(asset.asset_live_date)
          .toISOString()
          .split("T")[0],
          asset_end_date: new Date(asset.asset_end_date)
          .toISOString()
          .split("T")[0],
      };
    }),
  };
};


//defered loader to navigate to details before loading all the details
export const loader = ({ params }) => {
  return defer({
    contractData: loadContract(params),
    contractHistory: loadHistory(params),
  });
};
