import React from "react";
import { Link } from "react-router-dom";
import classes from "./RadioNavigation.module.css";
const RadioNavigation = () => {
  return (
    <div className={classes.group}>
      <label className={`${classes["fs-7"]} fst-italic my-auto`}>
        Tick to display information
      </label>
      <Link to="bva" className={`${classes.link} my-auto`}>
        <label className={`${classes["radio-button"]} my-auto`}>
          <input type="radio" name="radio" />
          BvA – Current Month Revenue
        </label>
      </Link>
      <Link to="rps" className={`${classes.link} my-auto`}>
        <label className={`${classes["radio-button"]} my-auto`}>
          <input type="radio" name="radio" />
          Revenue – Per Station
        </label>
      </Link>
      <Link to="frr" className={`${classes.link} my-auto`}>
        <label className={`${classes["radio-button"]} my-auto`}>
          <input type="radio" name="radio" />
          Fill Rate Report
        </label>
      </Link>
      <Link to="rate" className={`${classes.link} my-auto`}>
        <label className={`${classes["radio-button"]} my-auto`}>
          <input type="radio" name="radio" />
          Rate Card
        </label>
      </Link>
      <Link to="/contracts/submissions" className={`${classes.link} my-auto`}>
        <label className={`${classes["radio-button"]} my-auto`}>
          <input type="radio" name="radio" />
          Contract Status
        </label>
      </Link>
      <Link to="/contracts/submissions?status=INVOICED" className={classes.link}>
        <label className={`${classes["radio-button"]} my-auto`}>
          <input type="radio" name="status" value="INVOICED" />
          Invoiced Contracts
          {/* comment */}
        </label>
      </Link>
      <Link to="/contracts/submissions?status=COMPLETE" className={classes.link}>
        <label className={`${classes["radio-button"]} my-auto`}>
          <input type="radio" name="status" value="ISSUED OR" />
          Invoices and ORs
        </label>
      </Link>
    </div>
  );
};

export default RadioNavigation;
