import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  alerts: [],
};
const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    addAlert(state, action) {
      state.alerts.push(action.payload);
    },
    removeAlert(state, action) {
      state.alerts = state.alerts.filter(
        (alert) => alert.id !== action.payload.id
      );
    },
  },
});

export default alertSlice;
export const alertActions = alertSlice.actions;
