import React, { Suspense, useState } from "react";
import { Await, Link, defer, useLoaderData, useSearchParams } from "react-router-dom";
import SubmissionTable from "../../components/SubmissionTable";
import { getAuthToken, getTokenData } from "../../utils/auth";
import axios from "axios";
import Loading from "../../components/UI/Loading";
const apiURL = process.env.REACT_APP_API_URL

const SubmissionPage = () => {
  const { submissions } = useLoaderData();

  return (
    <main>
      <Suspense fallback={<Loading />}>
        <Await resolve={submissions}>
          {(loadedSubmissions) =>
            loadedSubmissions.length > 0 ? (
              <SubmissionTable tableData={loadedSubmissions}/>
            ) : (
              <>
                <SubmissionTable tableData={[]} />
                <p className="pt-2">
                  You have no assigned contracts. Create a new submission{" "}
                  <Link to="/contracts/new">click here</Link>
                </p>
              </>
            )
          }
        </Await>
      </Suspense>
    </main>
  );
};

export default SubmissionPage;

//API function that loads the submissions of a user
const loadSubmissions = async () => {
  const token = getAuthToken();
  const { uid } = getTokenData();

  const requestBody = {
    submitted_by: uid,
  };

  const response = await axios.post(
    `${apiURL}/contract/subby`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  //Sorting submissions based on priority for encoders lower is first
  const statusOrder = {
    RETURNED: 0,
    PENDING: 1,
    DISAPPROVED: 2,
    "FOR INVOICING": 3,
    "INVOICED": 4,
    "FOR APPROVAL": 5,
    "ISSUED OR": 6,
    COMPLETE: 7,
    DONE: 8,
  };

  const sortedSubmissions = [...response.data].sort((a, b) => {
    const statusA = a.status.toUpperCase();
    const statusB = b.status.toUpperCase();

    if (statusOrder[statusA] < statusOrder[statusB]) {
      return -1; // a should come before b
    } else if (statusOrder[statusA] > statusOrder[statusB]) {
      return 1; // b should come before a
    } else {
      const dateA = new Date(a.date_submitted);
      const dateB = new Date(b.date_submitted);
      return dateB - dateA; // sort by date
    }
  });

  return sortedSubmissions;
};

//defered loader to navigate before waiting for load
export const loader = () => {
  return defer({ submissions: loadSubmissions() });
};