import { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import classes from "./AlertModal.module.css";
import alertSlice from "../../store/alert-slice";

const Overlay = ({ alert }) => {
  const dispatch = useDispatch();


  //useEffect that adds an alert to the alert array in redux
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(alertSlice.actions.removeAlert({ id: alert.id }));
    }, alert.duration);

    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, alert]);

  const handleClose = () => {
    dispatch(alertSlice.actions.removeAlert({ id: alert.id }));
  };

  return (
    <>
      <div className={`${classes["alert-modal"]} ${classes[alert.color]}`}>
        <p className={classes["alert-message-design"]}>{alert.message}</p>
        <button className={classes["close-button"]} onClick={handleClose}>
          &times;
        </button>
      </div>
    </>
  );
};

const AlertModal = () => {
  const alerts = useSelector((state) => state.alert.alerts.slice().reverse());

  return (
    <>
      {ReactDOM.createPortal(
        <div className={classes.alert}>
          {alerts.map((alert) => (
            <Overlay key={alert.id} alert={alert} message={alert.message} />
          ))}
        </div>,
        document.getElementById("alert-root")
      )}
    </>
  );
};

export default AlertModal;
