import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Papa from 'papaparse';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { getTokenData } from "../../utils/auth";
import { format } from 'date-fns';

const FillRateDownload = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const apiURL = process.env.REACT_APP_API_URL;
  const { name, company } = getTokenData();

  const fetchData = async () => {
    // Get the current month and year
    const currentDate = new Date();
    const start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  
    try {
      const response = await fetch(`${apiURL}/analytics/exportfillrate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start_date: start.toISOString(),
          end_date: end.toISOString(),
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log(data);
        // Download CSV
        downloadCSV(data);
      } else {
        console.error('Error fetching data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const downloadCSV = (data) => {

    //Logs Download
    fetch(`${apiURL}/analytics/savedlhistory`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          "content": `Fill Rate Report downloaded by ${name}`,
          "company": `${company}`
      })
  })
  
     .then(response => {
      if (!response.ok) {
          throw new Error('Failed to log download');
        }
        console.log('Download logged successfully');
      })
      .catch(error => {
        console.error('Error logging download:', error);
      });
      //Logs Download end

    try {
      // Create a mapping of category names to their respective data
      const categoryMapping = {
        Trains: [],
        Stations: [],
        'Pillars & Viaducts': [],
      };
  
      // Iterate over properties of the data object
      for (const category in data) {
        if (Object.hasOwnProperty.call(data, category)) {
          const categoryData = data[category];
  
          // Check if categoryData is an array
          if (Array.isArray(categoryData)) {
            // Transform data to add headers and separate values
            const transformedData = categoryData.map((item) => ({
              AssetName: item.name,
              ...item.value.reduce((acc, val, index) => {
                const monthName = new Date(2000, index, 1).toLocaleString('en-US', { month: 'short' });
                acc[`${monthName}`] = val;
                return acc;
              }, {}),
            }));
  
            // Add transformed data to the respective category
            categoryMapping[category] = transformedData;
          } else {
            // Handle differently if categoryData is not an array
            // For example, you might want to log or handle this differently
            console.error(`Category data for "${category}" is not an array.`);
          }
        }
      }
  
      // Create CSV for each category
      let csvData = '';
      for (const categoryName in categoryMapping) {
        if (Object.hasOwnProperty.call(categoryMapping, categoryName)) {
          const categoryData = categoryMapping[categoryName];
          const categoryCsvData = Papa.unparse(categoryData, {
            header: true,
            quotes: true,
            delimiter: ',',
          });
  
          // Add category CSV data with separator
          csvData += `${categoryName}\n${categoryCsvData}\n\n`;
        }
      }
  
      // Create and trigger download for the combined CSV
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
  
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'fillrate_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error parsing data for CSV:', error);
    }
  };






  
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              margin="dense" 
              helperText=""
              sx={{
                "& .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root": {
                  marginTop: "16px", 
                },
              }}
            />
          )}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              margin="dense" 
              helperText=""
              sx={{
                "& .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root": {
                  margin: "16px", 
                  padding: "16px",
                  height: "75px",
                },
              }}
            />
          )}
        />
      </LocalizationProvider>

      <Button variant="contained" onClick={fetchData} style={{
        backgroundColor: "#4472c4",
        color: "white",
        border: "none",
        borderRadius: "5px",
        padding: "2px 26px",
        paddingLeft: "20px",
        paddingRight: "20px",
        fontWeight: "400",
        marginRight: "16px",
        fontSize: "14px",
        marginTop: "16px",
        textTransform: "none",
        width: "100%"
      }}>
        Download
      </Button>
    </div>
  );
};

export default FillRateDownload;
