import axios from "axios";
import { getAuthToken } from "./auth";
import { notificationActions } from "../store/notification-slice";
const token = getAuthToken();
const apiURL = process.env.REACT_APP_API_URL


//API function that notifies the chosen user bsaed on the request body
export async function notify(requestBody) {
  const response = await axios.post(
    `${apiURL}/notif/send`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  console.log("Notification", response);
}

//API function that pushes a contract history to the databse
export async function pushHistory(requestBody) {
  // {
  //   "contract_number": "<insert contract_number>"
  //   "content": "<insert history content>"
  // }
  const response = await axios.post(
    `${apiURL}/contract/history`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  console.log("History", response);
}

//API function that marks a specific notification as read base on the id
export async function markNotificationAsRead(id) {
  await axios.patch(
    `${apiURL}/notif/markread`,
    { notif_id: id },
    { headers: { Authorization: `Bearer ${token}` } }
  );
}

//API function that marks all of the notifications for a specific user or role as read
export async function markAllNotificationsAsRead(uid, userRole) {
  await axios.patch(
    `${apiURL}/notif/markallread`,
    { user_id: uid, user_role: userRole },
    { headers: { Authorization: `Bearer ${token}` } }
  );
}

//API function that checks if a user has unread notifications and updates the redux state to display a red dot on the notif bell
export async function checkHasUnread(requestBody, dispatch, id) {
  const response = await axios.post(
    `${apiURL}/notif`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );
  const matchingData = response.data.filter((data) => data.isRead === 0);
  const count = matchingData.length;
  if (count === 1) {
    const gg = response.data.filter((data) => data.notif_id === id);
    dispatch(
      notificationActions.setHasUnread(gg[0].isRead === 0 ? false : true)
    );
  }
}
