import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  hasUnread: false,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setHasUnread(state, action) {
      state.hasUnread = action.payload;
    },
  },
});

export default notificationSlice;
export const notificationActions = notificationSlice.actions;
