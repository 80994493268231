import { createSlice } from "@reduxjs/toolkit";
const userSlice = createSlice({
  name: "user",
  initialState: {
    role: "",
  },
  reducers: {
    setUser(state, action) {
      state.role = action.payload.role;
    },
    removeUser(state) {
      state.role = "";
    },
  },
});

export default userSlice;

export const userActions = userSlice.actions;
