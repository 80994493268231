import React from "react";
import classes from "./SubNavigation.module.css";

//Header shown for pages under /users
const UserNavigation = () => {
  return (
    <header className={classes.header}>
      <h1>Manage Users</h1>
    </header>
  );
};

export default UserNavigation;
