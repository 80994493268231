import React, { useEffect, useState } from "react";
import classes from "./ApprovalTable.module.css";
import { APPROVAL_HEADERS } from "../../utils/table-headers";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { getTokenData } from "../../utils/auth";

const ApprovalTable = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [forApprovalData, setForApprovalData] = useState([]);
  const { name, company } = getTokenData();

  useEffect(() => {
    fetch(`${apiURL}/analytics/dayspending_tbl`)
      .then((response) => response.json())
      .then((data) => setForApprovalData(data))
      .catch((error) => console.error("Error fetching API data:", error));
  }, []);

  // Navigate Open button to submissions
  const navigate = useNavigate();
  function buttonClickHandler() {
    navigate("/contracts/submissions");
  }
  //Download Button for Approval
  const downloadCSV = () => {
    const csvContent = [
      APPROVAL_HEADERS.map((header) => header.header), 
      ...forApprovalData.map((row) =>
        APPROVAL_HEADERS.map((header) => row[header.key])
      ), 
    ]
    .map((row) => row.map((value) => `"${value}"`).join(","))
    .join("\n");
    const csvData = forApprovalData.reduce((csv, row) => {
      const rowValues = APPROVAL_HEADERS.map((header) => row[header.key]);
      return csv + rowValues.join(",") + "\n";
    }, APPROVAL_HEADERS.map((header) => header.header).join(",") + "\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "approval_data.csv");

    fetch(`${apiURL}/analytics/savedlhistory`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          "content": `Approval List downloaded by ${name}`,
          "company": `${company}`
      })
    })

   .then(response => {
    if (!response.ok) {
        throw new Error('Failed to log download');
      }
      console.log('Download logged successfully');
    })
    .catch(error => {
      console.error('Error logging download:', error);
    });
};
  return (
    <>
      <div className={classes.top}>
        <p className={classes.title}>For Approval</p>
        <div className={classes.appButton}>
          <button onClick={downloadCSV}>Download</button>{" "}
        </div>
        <button onClick={buttonClickHandler}>Open List</button>
      </div>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>#</th>
            {APPROVAL_HEADERS.map((header) => (
              <th key={header.key}>{header.header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {forApprovalData.length > 0 ? (
            forApprovalData.map((data, index) => (
              <tr key={data.client}>
                <td>{index + 1}</td>
                {APPROVAL_HEADERS.map((header) => (
                  <td key={header.key}
                  style={{
                    textAlign:
                      header.key === "date_submitted" || header.key === "pending"
                        ? "center"
                        : "left",
                  }}>{data[header.key]}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={APPROVAL_HEADERS.length + 1}>No data available</td>
            </tr>
          )}
        </tbody>
      </table>
      <p className={classes.bottom}>Click open to go to Approval Page</p>
    </>
  );
};

export default ApprovalTable;

