import axios from "axios";
import React, { Suspense } from "react";
import { Await, defer, useRouteLoaderData } from "react-router-dom";
import UserForm from "../components/User/UserForm";
import { getAuthToken, getTokenData } from "../utils/auth";
import Loading from "../components/UI/Loading";
import classes from "./Background.module.css";

const ManageProfilePage = () => {
  const { profileData } = useRouteLoaderData("profile");

  return (
    <main className={classes.main}>
      <Suspense fallback={<Loading />}>
        <Await resolve={profileData}>
          {(loadedProfileData) => (
            <UserForm method="post" user={loadedProfileData} />
          )}
        </Await>
      </Suspense>
    </main>
  );
};

export default ManageProfilePage;

//API function to load profile based on current user uid
const loadProfile = async () => {
  const token = getAuthToken();
  const { uid } = getTokenData();
  const apiURL = process.env.REACT_APP_API_URL

  const requestBody = { user_id: uid };
  const response = await axios.post(
    `${apiURL}/users/user`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  const userData = response.data.user_info;

  const user = {
    role: userData[0].role,
    uid: uid,
    contactNumber: userData[0].contact_number,
    imageUrl: userData[0].photo,
    company: userData[0].company,
    firstName: userData[0].first_name,
    lastName: userData[0].last_name,
    email: userData[0].email,
  };
  return user;
};

export const loader = () => {
  return defer({ profileData: loadProfile() });
};
