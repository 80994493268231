import React, { useEffect, useState } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
} from "react-router-dom";
import RootLayout from "./pages/roots/Root";
import DashboardPage from "./pages/Manager/DashboardPage";
import ContractRoot from "./pages/roots/ContractRoot";
import SubmissionPage, {
  loader as submissionLoader,
} from "./pages/Encoder/SubmissionPage";
import AuthenticationPage, {
  action as authAction,
} from "./pages/AuthenticationPage";
import ContractDetailPage, {
  loader as contractDetailLoader,
} from "./pages/ContractDetailPage";
import { tokenLoader } from "./utils/auth";
import { action as logoutAction } from "./pages/Logout";
import NewContractPage from "./pages/Encoder/NewContractPage";
import ManageUsersPage, {
  loader as manageUsersLoader,
} from "./pages/Administrator/ManageUsersPage";
import UserRoot from "./pages/roots/UserRoot";
import AddUserPage, {
  action as addUserAction,
} from "./pages/Administrator/AddUserPage";
import ManageSubmissionsPage, {
  loader as manageSubmissionsLoader,
  action as manageSubmissionsAction,
} from "./pages/Manager/ManageSubmissionsPage";
import ManageProfilePage, {
  loader as ManageProfileLoader,
} from "./pages/ManageProfilePage";
import EditProfilePage, {
  action as editProfileAction,
} from "./pages/EditProfilePage";
import ProfileRoot from "./pages/roots/ProfileRoot";
import RateCardPage, {
  loader as RateCardLoader,
} from "./pages/Manager/RateCardPage";
import DashboardRoot from "./pages/roots/DashboardRoot";
import ChangePasswordPage, {
  loader as changePasswordLoader,
} from "./pages/ChangePasswordPage";
import ForgotPasswordPage, {
  action as forgotPasswordAction,
} from "./pages/ForgotPasswordPage";
import EditUserPage, {
  action as editUserAction,
  loader as editUserLoader,
} from "./pages/Administrator/EditUserPage";
import EditContractPage, {
  loader as editContractLoader,
} from "./pages/Encoder/EditContractPage";
import { action as mutateContractAction } from "./components/ContractForm";
import CurrentMonthRevenuePage from "./pages/Manager/CurrentMonthRevenuePage";
import RevenuePerStationPage from "./pages/Manager/RevenuePerStationPage";
import FillRateReportPage from "./pages/Manager/FillRateReportPage";
import NotFoundPage from "./pages/ErrorPage";
import { redirect } from "react-router-dom";
// import InvoiceORPage from "./components/InvoiceOR";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    id: "root",
    loader: tokenLoader,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "dashboard",
        element: <DashboardRoot />,
        children: [
          { path: "", element: <DashboardPage /> },
          { path: "rate", element: <RateCardPage />, loader: RateCardLoader },
          { path: "bva", element: <CurrentMonthRevenuePage /> },
          { path: "rps", element: <RevenuePerStationPage /> },
          { path: "frr", element: <FillRateReportPage /> },
          // { path: "invoice", element: <InvoiceORPage /> },
          
        ],
      },
      {
        path: "contracts",
        element: <ContractRoot />,
        children: [
          {
            path: "track",
            children: [
              {
                index: true,
                element: <SubmissionPage />,
                loader: submissionLoader,
              },
              {
                path: ":contractNumber",
                element: <ContractDetailPage />,
                loader: contractDetailLoader,
              },
              {
                path: "edit",
                children: [
                  {
                    index: true,
                    element: <Navigate to="../" replace />,
                  },
                  {
                    path: ":contractNumber",
                    element: <EditContractPage />,
                    loader: editContractLoader,
                    action: mutateContractAction,
                  },
                ],
              },
            ],
          },
          {
            path: "new",
            element: <NewContractPage />,
            action: mutateContractAction,
          },

          {
            path: "submissions",
            action: manageSubmissionsAction,
            children: [
              {
                index: true,
                element: <ManageSubmissionsPage />,
                loader: manageSubmissionsLoader,
              },
              {
                path: ":contractNumber",
                element: <ContractDetailPage />,
                loader: contractDetailLoader,
              },
              {
                path: "edit",
                children: [
                  {
                    index: true,
                    element: <Navigate to="../" replace />,
                  },
                  {
                    path: ":contractNumber",
                    element: <EditContractPage />,
                    loader: editContractLoader,
                    action: mutateContractAction,
                  },
                ],
              },
              
            ],
          },
        ],
      },
      {
        path: "users",
        element: <UserRoot />,
        children: [
          {
            index: true,
            element: <ManageUsersPage />,
            loader: manageUsersLoader,
          },
          { path: "add", element: <AddUserPage />, action: addUserAction },
          {
            path: "edit",
            children: [
              {
                index: true,
                element: <Navigate to="../" replace />,
              },
              {
                path: ":uid",
                element: <EditUserPage />,
                loader: editUserLoader,
                action: editUserAction,
              },
            ],
          },
        ],
      },
      {
        path: "profile",
        element: <ProfileRoot />,
        id: "profile",
        loader: ManageProfileLoader,
        children: [
          {
            index: true,
            element: <ManageProfilePage />,
          },
          {
            path: "edit",
            element: <EditProfilePage />,
            action: editProfileAction,
          },
          {
            path: "password",
            element: <ChangePasswordPage />,
            loader: changePasswordLoader,
          },
        ],
      },
      { path: "logout", action: logoutAction },
    ],
  },
  { path: "login", element: <AuthenticationPage />, action: authAction },
  {
    path: "password",
    element: <ForgotPasswordPage />,
    action: forgotPasswordAction,
  },
]);

function InactivityChecker({ children }) {
  const [inactiveTime, setInactiveTime] = useState(0);

  const handleUserActivity = () => {
    setInactiveTime(0);
  };

  useEffect(() => {
    const activityTimer = setInterval(() => {
      setInactiveTime((prevInactiveTime) => prevInactiveTime + 1);
    }, 1000);

    document.addEventListener("mousemove", handleUserActivity);

    return () => {
      clearInterval(activityTimer);
      document.removeEventListener("mousemove", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    if (inactiveTime >= 300) {
      // Logout the user after 5 minutes of inactivity
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("user");
      router.navigate(redirect("/login"));
    }
  }, [inactiveTime]);

  return <>{children}</>;
}

function App() {
  return (
    <InactivityChecker>
      <RouterProvider router={router} />
    </InactivityChecker>
  );
}

export default App;
