import React, { useState } from "react";
import classes from "./UserForm.module.css";
import {
  Form,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { alert } from "../../utils/alert";

const UserForm = ({ method, user, data, title }) => {
  const userRole = useSelector((state) => state.user.role);
  const [selectedCompany, setSelectedCompany] = useState(
    user ? user.company : ""
  );
  const navigation = useNavigation();
  const isSubmitting = navigation.state === "submitting";
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL


  const isDisabled = !(
    path.startsWith("/profile/edit") ||
    path.startsWith("/users/add") ||
    path.startsWith("/users/edit")
  );

  //Cancels editing the user
  const cancelHandler = (event) => {
    event.preventDefault();
    if (path.startsWith("/users/edit")) {
      navigate("../..");
    } else navigate("..");
  };

  //Starts editing profile details
  const editHandler = () => {
    navigate("edit");
  };

  //Navigate to change password page and API to request an OTP
  const changePasswordHandler = async () => {
    const requestBody = { email_addr: user.email };
    navigate("password");
    alert("An OTP has been sent to your email", "green", dispatch);
    await axios.post(
      `${apiURL}/auth/reqchangepass`,
      requestBody
    );
  };
  return (
    <>
      <Form method={method} className={classes.form}>
        {title && <h1 className={classes.title}>{title}</h1>}
        {data && data.message && (
          <p className={classes.alert}>{data.message}</p>
        )}
        <p>
          <label htmlFor="firstName">First Name</label>
          <input
            id="firstName"
            name="firstName"
            type="text"
            placeholder="Enter First Name"
            defaultValue={user ? user.firstName : ""}
            disabled={isDisabled}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/\d/g, "");
            }}
            required
          />
        </p>
        <p>
          <label htmlFor="lastName">Last Name</label>
          <input
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Enter Last Name"
            defaultValue={user ? user.lastName : ""}
            disabled={isDisabled}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/\d/g, "");
            }}
            required
          />
        </p>
        <p>
          <label htmlFor="email">Email Address</label>
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Enter Email Address"
            defaultValue={user ? user.email : ""}
            disabled={isDisabled}
            required
          />
        </p>
        <p>
          <label htmlFor="contactNumber">Contact Number</label>
          <input
            id="contactNumber"
            type="tel"
            name="contactNumber"
            placeholder="Enter Contact Number"
            defaultValue={user ? user.contactNumber : ""}
            disabled={isDisabled}
            maxLength={11}
            onChange={(e) => {
              e.target.value = e.target.value.replace(/\D/g, "");
            }}
            required
          />
        </p>
        <p>
          <label htmlFor="company">Company</label>
          <select
            id="company"
            name="company"
            disabled={isDisabled}
            required
            defaultValue={selectedCompany}
            onChange={(event) => setSelectedCompany(event.target.value)}
          >
            <option value="" style={{ color: "gray" }} disabled hidden>
              Select Company
            </option>
            {/*Checker if the user is an admin, If admin allow other options for the role and company */}
            {userRole === "ADMIN_ADMIN" ? (
              <>
                <option value="UTASI">UTASI</option>
                <option value="LRMC">LRMC</option>
                <option value="ADMIN">ADMIN</option>
              </>
            ) : (
              <option value={user.company}>{user.company}</option>
            )}
          </select>
        </p>
        <p>
          <label htmlFor="role">Role</label>
          <select
            id="role"
            name="role"
            disabled={isDisabled}
            required
            defaultValue={user ? user.role : ""}
          >
            <option value="" style={{ color: "gray" }} disabled hidden>
              Select Role
            </option>
            {/*Checker if the user is an admin, If admin allow other options for the role and company */}
            {userRole === "ADMIN_ADMIN" ? (
              <>
                {selectedCompany === "LRMC" && (
                  <>
                    <option value="MANAGER">MANAGER</option>
                  </>
                )}
                {selectedCompany === "UTASI" && (
                  <>
                    <option value="MANAGER">MANAGER</option>
                    <option value="ENCODER">ENCODER</option>
                  </>
                )}
                {selectedCompany === "ADMIN" && (
                  <option value="ADMIN">ADMIN</option>
                )}
              </>
            ) : (
              <option value={user.role}>{user.role}</option>
            )}
          </select>
        </p>
        <div className={classes.actions}>
          {location.pathname === "/profile" ? (
            <>
              <button
                type="button"
                onClick={editHandler}
                className={classes.dark}
              >
                Edit
              </button>
              <button onClick={changePasswordHandler} className={classes.dark}>
                Change Password
              </button>
            </>
          ) : (
            <>
              <button disabled={isSubmitting} className={classes.light}>
                {isSubmitting ? "Processing..." : "Save"}
              </button>
              <button onClick={cancelHandler} className={classes.dark}>
                Cancel
              </button>
            </>
          )}
        </div>
      </Form>
    </>
  );
};

export default UserForm;
