import React from "react";
import { Form } from "react-router-dom";
import classes from "./SubNavigation.module.css";

//Header shown for pages under /profile
const ProfileNavigation = () => {
  return (
    <header className={classes.header}>
      <h1>Manage Profile</h1>

      <Form
        //Form submission for logout path
        action="/logout"
        method="post"
        className="ms-2"
        style={{ heigh: "100%", display: "flex", alignItems: "center" }}
      >
        <button className={classes["btn-logout"]}>Logout</button>
      </Form>
    </header>
  );
};

export default ProfileNavigation;
