import React from "react";
import ReactDOM from "react-dom";
import classes from "./ImageModal.module.css";
const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const Overlay = (props) => {
  return (
    <div className={classes.modal}>
      <div className={classes["modal-content"]}>
        <div className={classes["image-container"]}>
          <img src={props.image} alt="bigger" />
          <button onClick={props.onClose}>x</button>
        </div>
      </div>
    </div>
  );
};

//Modal to display image on the asset breakdown
const ImageModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <Overlay onClose={props.onClose} image={props.image} />,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ImageModal;
