import React, { useRef, useState } from "react";
import classes from "./ContractHistory.module.css";
import { formatDateTime } from "../utils/converters";
import { useSelector } from "react-redux";

function ContractHistory(props) {
  const [activeIndex, setActiveIndex] = useState(null);
  const userRole = useSelector((state) => state.user.role);

  let filteredArray;

  // Update the filtering logic for "ADMIN_ADMIN" role
  if (userRole === "ADMIN_ADMIN") {
    filteredArray = props.history;
  } else {
    //Switch case to filter the history based on the user role
    switch (userRole) {
      case "LRMC_MANAGER":
        filteredArray = props.history.filter(
          (obj) => obj.company === "LRMC" || obj.company === "ALL"
        );
        break;
      case "UTASI_MANAGER":
        filteredArray = props.history;
        break;
      case "UTASI_ENCODER":
        filteredArray = props.history.filter(
          (obj) => obj.company === "UTASI" || obj.company === "ALL"
        );
        break;
      default:
        filteredArray = [];
    }
  }

  const contentRef = useRef(null);
  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const sortedHistory = [...filteredArray].sort((a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateB - dateA;
  });

  return (
    <div className={classes.accordion}>
      <div className={classes.item}>
        <div
          className={`${classes.title} ${
            activeIndex === 0 ? classes.active : ""
          }`}
          onClick={() => handleClick(0)}
        >
          <h3>Contract History</h3>
        </div>
        <div
          className={`${classes.content} ${
            activeIndex === 0 ? classes.active : ""
          }`}
        >
          <table>
            <thead>
              <tr>
                <th>Details</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {sortedHistory.map((history, index) => {
                return (
                  <tr ref={contentRef} key={index}>
                    <td>{history.content}</td>
                    <td>{formatDateTime(history.created_at)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ContractHistory;
