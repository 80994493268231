import React, { useRef, useEffect, useState } from "react";
import * as echarts from "echarts";

const VerticalBarChart = (props) => {
  const chartRef = useRef(null);
    
  useEffect(() => {
    const { chartData, title } = props;

        // // Clear the existing chart instance if it exists
        // if (chartRef.current) {
        //   echarts.dispose(chartRef.current);
        // }
    

    if (chartData && chartData.length > 0) {
  
     const myChart = echarts.init(chartRef.current);

      var app = {};
      const posList = [
        "left",
        "right",
        "top",
        "bottom",
        "inside",
        "insideTop",
        "insideLeft",
        "insideRight",
        "insideBottom",
        "insideTopLeft",
        "insideTopRight",
        "insideBottomLeft",
        "insideBottomRight",
      ];
      app.configParameters = {
        rotate: {
          min: -90,
          max: 90,
        },
        align: {
          options: {
            left: "left",
            center: "center",
            right: "right",
          },
        },
        verticalAlign: {
          options: {
            top: "top",
            middle: "middle",
            bottom: "bottom",
          },
        },
        position: {
          options: posList.reduce(function (map, pos) {
            map[pos] = pos;
            return map;
          }, {}),
        },
        distance: {
          min: 0,
          max: 100,
        },
      };
      app.config = {
        rotate: 90,
        align: "left",
        verticalAlign: "middle",
        position: "insideBottom",
        distance: 15,
        onChange: function () {
          const labelOption = {
            rotate: app.config.rotate,
            align: app.config.align,
            verticalAlign: app.config.verticalAlign,
            position: app.config.position,
            distance: app.config.distance,
          };
          myChart.setOption({
            series: [
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
              {
                label: labelOption,
              },
            ],
          });
        },
      };
      const labelOption = {
        show: true,
        position: app.config.position,
        distance: app.config.distance,
        align: app.config.align,
        verticalAlign: app.config.verticalAlign,
        rotate: app.config.rotate,
        //formatter: "{c} {name|{a}}",
        formatter: "{c} Mil",
        fontSize: 11,
        rich: {
          name: {},
        },
      };

      const option = {
        title: {
          top: 32,
          text: title,
          left: "center",
        },
        tooltip: {
          // trigger: "axis",
          trigger: "item",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          show: false,
        },
        dataset: {
          label: {
            normal: {
              show: true,
              position: "top",
            },
          },
          dimensions: ["Actual (in Mil)", "Budget (in Mil)"],
          source: props.chartData.map((item) => [item.label, ...item.value]),
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '7%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: "category",
          data: chartData.map((item) => item.label),
          barMinHeight: [30, 20],
          axisLabel: {
            rotate: -45, // Rotate the x-axis labels
            interval: 0, // Show all labels, even if they overlap
            fontSize: 20,
            
          },
        },
        yAxis: {
          type: "value",
          // splitLine: { show: false }, // Hide grid lines
          // splitArea: { show: false },
          axisLabel: {
            fontSize: 20,
          },
        },
        series: [
          {
            name: "Actual",
            type: "bar",
            barGap: 0,
            label: {
              // ...labelOption,
              show: true, // Show data labels
              position: "top", // Adjust the position as needed
              barCategoryGap: "30%",
              barGap: "30%",
              fontSize: 20,
              formatter: "{c} Mil",
            },
            emphasis: {
              focus: "series",
            },
            //data: chartData.map((item) => item.value[0]),
            // data: actualData,
            data: chartData.map((item) => parseFloat(item.value[0]).toFixed(2)),
            itemStyle: {
              color: "#0070c0",
              fontSize: "14px"
            },
          },
          {
            name: "Budget",
            type: "bar",
            label: {
              // ...labelOption,
              show: true, // Show data labels
              position: "top", // Adjust the position as needed
              barCategoryGap: "30%", 
              barGap: "30%",
              fontSize: 20,
              formatter: "{c} Mil",
            },
            emphasis: {
              focus: 'series'
            },
            //data: chartData.map((item) => item.value[1]),
            data: chartData.map((item) => parseFloat(item.value[1]).toFixed(2)),
            itemStyle: {
              color: "#ffc000",
              fontSize: "14px"
            },
          }
          ],
      };
      myChart.setOption(option);
      } 
  }, [props]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "950px",
        height: "500px",
        marginLeft: "13%",
        marginTop: "-40px"
      }}
    />
  );
};

export default VerticalBarChart;