import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigation, useSubmit } from "react-router-dom";
import { getAuthToken, getTokenData } from "../utils/auth";
import { convertToBase64, toPHPValue } from "../utils/converters";
import { CONTRACT_HEADERS, ASSET_HEADERS } from "../utils/table-headers";
// import { SUBMISSION_HEADERS } from "../utils/table-headers";
import classes from "./ContractForm.module.css";
import statusClasses from "./SubmissionTable.module.css";
import axios from "axios";
import FormLoading from "./UI/FormLoading";
import { notify, pushHistory } from "../utils/api";
import feedbackSVG from "../assets/actions/feedback.svg";
import ImageModal from "./UI/ImageModal";
import { titleCase } from "../utils/converters";
import FeedbackModal from "./UI/FeedbackModal";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { color } from "echarts";
// import { useSelectedStations, useSelectedAssetStations } from "../hooks/selectedStations";

const ContractForm = (props) => {
  const [contractFile, setContractFile] = useState(
    props.item && props.item.contract_file
  );
  const [contractFileName, setContractFileName] = useState(
    props.item && props.item.contract_file.split("/").pop()
  );
  const [isContractFileChange, setIsContractFileChange] = useState(false);
  const [isAssetImageChange, setIsAssetImageChange] = useState([]);
  const [assetImage, setAssetImage] = useState([]);
  const [isAssetFileChange, setIsAssetFileChange] = useState([]);
  const [assetFile, setAssetFile] = useState([]);
  const [assetFileName, setAssetFileName] = useState([]);
  const submit = useSubmit();
  const navigation = useNavigation();
  const userRole = useSelector((state) => state.user.role);
  const [feedbackModal, setFeedbackModal] = useState();
  const [imageModal, setImageModal] = useState();

  // const [selectedStations, setSelectedStations] = useState([]);

  const [orFile, setOrFile] = useState([]);
  const [orFileName, setOrFileName] = useState([]);
  const [isOrFileChange, setIsOrFileChange] = useState([]);

  const stationOptions = [
    { value: "station1", label: "Baclaran" },
    { value: "station2", label: "EDSA" },
    { value: "station3", label: "Libertad" },
    { value: "station4", label: "Gil Puyat" },
    { value: "station5", label: "Vito Cruz" },
    { value: "station6", label: "Quirino" },
    { value: "station7", label: "Pedro Gil" },
    { value: "station8", label: "United Nations" },
    { value: "station9", label: "Central Terminal" },
    { value: "station10", label: "Carriedo" },
    { value: "station11", label: "Doroteo Jose" },
    { value: "station12", label: "Bambang" },
    { value: "station13", label: "Tayuman" },
    { value: "station14", label: "Blumentritt" },
    { value: "station15", label: "Abad Santos" },
    { value: "station16", label: "R.Papa" },
    { value: "station17", label: "5th Avenue" },
    { value: "station18", label: "Monumento" },
    { value: "station19", label: "Balintawak" },
    { value: "station20", label: "Fernando Poe Jr." },
    { value: "station21", label: "MIA" },
    { value: "station22", label: "Asia World" },
    { value: "station23", label: "Ninoy Aquino" },
    { value: "station24", label: "Dr. Santos" },
    { value: "station25", label: "Redemptorist" },
  ];

  const initialStations =
    props.item && props.item.assets
      ? props.item.assets.map((asset) => ({
          value: asset.asset_station,
          label: asset.asset_station,
        }))
      : [];

  const [assetStations, setAssetStations] = useState(initialStations);

  // Initialize assetStations with the initial values
  useEffect(() => {
    setAssetStations(initialStations);
  }, [props.item]);

  const handleDropdownStation = (assetOption, index) => {
    console.log("DROPPDOWNNASSET", assetOption);
    var updatedAssetStations = [...assetStations];
    updatedAssetStations[index] = assetOption;
    setAssetStations(updatedAssetStations);
  };

  // Updates the table headers according to the role
  let UPDATED_ASSET_HEADERS = ASSET_HEADERS;

  if (
    (userRole === "UTASI_ENCODER" ||
      userRole === "LRMC_MANAGER" ||
      userRole === "UTASI_MANAGER" ||
      userRole === "ADMIN_ADMIN") &&
    props.item &&
    props.item.status === "FOR INVOICING"
  ) {
    UPDATED_ASSET_HEADERS = [
      ...ASSET_HEADERS,
      { key: "asset_file", header: "Invoice File" },
    ];
  } else if (
    (userRole === "UTASI_ENCODER" ||
      userRole === "LRMC_MANAGER" ||
      userRole === "UTASI_MANAGER" ||
      userRole === "ADMIN_ADMIN") &&
    props.item &&
    props.item.status === "INVOICED"
  ) {
    UPDATED_ASSET_HEADERS = [
      ...ASSET_HEADERS,
      { key: "asset_file", header: "Invoice File" },
      { key: "or_file", header: "OR File" },
    ];
  }

  // Custom Style for disabled dropdown
  const customStyles = {
    multiValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "black" : "#0000",
      borderBottom: "solid 1px lightgray",
      marginTop: "4px",
    }),
  };

  function toPHPValue(data, key) {
    if (key === "total_amount" || key === "asset_amount") {
      // Check if data is a number
      if (typeof data === "number") {
        return data.toLocaleString("en-US", {
          style: "currency",
          currency: "Php",
        });
      }
    }
    return data;
  }

  //Styling for each td in the table
  const customStyling = (data, key, index) => {
    if (key === "asset_invoice_numbers") {
      return props.item.assets
        .map((asset) => asset.asset_invoice_number)
        .join(", ");
    }

    if (key === "asset_stations") {
      console.log("AAPPIIII", props.item.assets);
      return props.item.assets.map((asset) => asset.asset_station).join(", ");
    }

    // if (key === "total_amount" || key === "asset_amount") {
    //   return toPHPValue(data);
    // }

    if (key === "total_amount" || key === "asset_amount") {
      return toPHPValue(data, key);
    }

    if (key === "asset_image") {
      //function to set an image for the image modal
      function viewImageClickHandler() {
        setImageModal(assetImage[index] ? assetImage[index] : data);
      }
      return (
        <>
          {assetImage[index] ? (
            <img
              onClick={viewImageClickHandler}
              src={assetImage[index]}
              alt={`Updated Asset #${index + 1}`}
              className={classes["asset-image"]}
            />
          ) : (
            data && (
              <img
                onClick={viewImageClickHandler}
                src={data}
                alt={`Asset #${index + 1}`}
                className={classes["asset-image"]}
              />
            )
          )}
          <label
            htmlFor={`upload-image-${index}`}
            className={classes["label-image"]}
          >
            {data || assetImage[index] ? "Replace" : "Upload"}
          </label>
          <input
            className={classes["upload-image"]}
            id={`upload-image-${index}`}
            type="file"
            accept="image/*"
            multiple={false}
            onChange={(event) => handleAssetImageChange(index, event)}
          />
        </>
      );
    }
    if (key === "asset_file") {
      //function to open the invoice file in a new tab
      const openAssetFileClickHandler = () => {
        window.open(data, index);
      };
      return (
        <>
          {/* displays a button if the submission has an invoice file for the specific asset */}
          {data && (
            <button
              className={classes["view-file-btn"]}
              onClick={openAssetFileClickHandler}
            >
              View File
            </button>
          )}
          {assetFile[index] && (
            <p className={classes["selected-filename"]}>
              {assetFileName[index]}
            </p>
          )}
          {/* label linked to the input for custom styled input */}
          {!assetFile[index] && (
            <label
              htmlFor={`file-input-${index}`}
              className={classes["label-file"]}
            >
              {data ? "Replace" : "Upload"}
            </label>
          )}
          {/* input area that is hidden */}
          <input
            id={`file-input-${index}`}
            className={classes["upload-file"]}
            type="file"
            accept=".pdf"
            multiple={false}
            onChange={(event) => handleAssetFileChange(index, event)}
          />
          {/* Remove file function when the manager decides not the current file */}
          {assetFile[index] && (
            <button
              className={classes["remove-file-btn"]}
              onClick={(event) => handleAssetFileChange(index, event)}
            >
              Remove File
            </button>
          )}
        </>
      );
    }

    // function to open the OR file in a new tab
    if (key === "or_file") {
      const openORFileClickHandler = () => {
        window.open(data, index);
      };
      return (
        <>
          {/* displays a button if the submission has an OR file for the specific asset */}
          {data && (
            <button
              className={classes["view-or-btn"]}
              onClick={openORFileClickHandler}
            >
              View File
            </button>
          )}
          {orFile[index] && (
            <p className={classes["selected-orfilename"]}>
              {orFileName[index]}
            </p>
          )}
          {/* label linked to the input for custom styled input */}
          {!orFile[index] && (
            <label
              htmlFor={`orfile-input-${index}`}
              className={classes["label-orfile"]}
            >
              {data ? "Replace" : "Upload"}
            </label>
          )}
          {/* input area that is hidden */}
          <input
            id={`orfile-input-${index}`}
            className={classes["upload-file"]}
            type="file"
            accept=".pdf"
            multiple={false}
            onChange={(event) => handleOrFileChange(index, event)}
          />
          {/* Remove file function when the manager decides not the current file */}
          {orFile[index] && (
            <button
              className={classes["remove-file-btn"]}
              onClick={(event) => handleOrFileChange(index, event)}
            >
              Remove File
            </button>
          )}
        </>
      );
    }
    return data;
  };

  let assetRows;

  //Rearranges the assets to group together similar assets based on their type
  if (props.item) {
    const assetGroups = props.item.assets.reduce((groups, asset) => {
      const key = asset["asset_type"];
      groups[key] = groups[key] || { ids: [], quantity: 0 };
      groups[key].ids.push(asset["asset_id"]);
      groups[key].quantity += parseInt(asset["asset_quantity"]);
      return groups;
    }, {});
    assetRows = Object.entries(assetGroups).map(([type, group]) => (
      <tr key={type}>
        <td>{group.ids.join(", ")}</td>
        <td>{type}</td>
        <td>{group.quantity}</td>
      </tr>
    ));
  }

  useEffect(() => {
    console.log("sdfsd", props);
    if (Object.keys(assetStations).length > 0) {
      // localStorage.setItem("assetStations", JSON.stringify(assetStations));
      console.log(props.item.assets);
      props.item.assets.map((asset) => asset.asset_station).join(", ");
    }
  }, [assetStations]);

  //Function to submit the contract, Calls the action depending on the method
  const submitContractHandler = async (event) => {
    event.preventDefault();

    try {
      // Check if any asset file has been submitted
      const assetFileSubmitted = assetFile.some((file) => file !== undefined);
      // Update the contract status based on asset file submission
      const updatedContract = { ...props.item };
      if (assetFileSubmitted) {
        updatedContract.status = "INVOICED";
      } else {
        updatedContract.status = "FOR INVOICING";
      }

      await submit(
        {
          contractItem: JSON.stringify(props.item),
          contractFile: contractFile,
          flags: JSON.stringify({
            isContractFileChange,
            isAssetImageChange,
            isAssetFileChange,
            isOrFileChange,
          }),
          media: JSON.stringify({ assetImage, assetFile, orFile }),
          assetStations: JSON.stringify(assetStations),
        },
        {
          action:
            props.method === "post"
              ? "/contracts/new"
              : `/contracts/track/edit/:${encodeURIComponent(
                  props.item.contract_number.replace("/", "%2F")
                )}`,
          method: props.method,
        }
      );
    } catch (error) {
      console.error("Error submitting the contract:", error);
    }
  };

  //Function to handle file changes for the contract file
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const contractFile = await convertToBase64(selectedFile).catch(
      () => undefined
    );
    setContractFileName(selectedFile ? selectedFile.name : undefined);
    setContractFile(contractFile);
    setIsContractFileChange(contractFile ? true : false);
  };

  //Function to handle image changes for each invoice
  const handleAssetImageChange = async (index, event) => {
    const selectedImage = event.target.files[0];
    const imageFile = await convertToBase64(selectedImage).catch(
      () => undefined
    );
    setAssetImage((prevAssetImage) => {
      const updatedAssetImage = [...prevAssetImage];
      updatedAssetImage[index] = imageFile;
      return updatedAssetImage;
    });
    setIsAssetImageChange((prevIsAssetImageChange) => {
      const updatedIsAssetImageChange = [...prevIsAssetImageChange];
      updatedIsAssetImageChange[index] = imageFile ? true : false;
      return updatedIsAssetImageChange;
    });
  };

  //Function to handle file changes for each invoice
  const handleAssetFileChange = async (index, event) => {
    let invoiceFile;
    let fileName;
    if (event.type === "change") {
      const selectedFile = event.target.files[0];
      fileName = selectedFile.name;
      invoiceFile = await convertToBase64(selectedFile).catch(() => undefined);
    } else {
      const fileInput = document.getElementById(`file-input-${index}`);
      if (fileInput) {
        fileInput.value = "";
      }
      invoiceFile = undefined;
    }
    setAssetFileName((prevAssetFileName) => {
      const updatedAssetFileName = [...prevAssetFileName];
      updatedAssetFileName[index] = fileName;
      return updatedAssetFileName;
    });
    setAssetFile((prevAssetFile) => {
      const updatedAssetFile = [...prevAssetFile];
      updatedAssetFile[index] = invoiceFile;
      return updatedAssetFile;
    });
    setIsAssetFileChange((prevIsAssetFileChange) => {
      const updatedIsAssetFileChange = [...prevIsAssetFileChange];
      updatedIsAssetFileChange[index] = invoiceFile ? true : false;
      return updatedIsAssetFileChange;
    });
  };

  //Function to handle OR changes for each invoice
  const handleOrFileChange = async (index, event) => {
    let orFileHandler;
    let orFileNameHandler;
    if (event.type === "change") {
      const selectedOrFile = event.target.files[0];
      orFileNameHandler = selectedOrFile.name;
      orFileHandler = await convertToBase64(selectedOrFile).catch(
        () => undefined
      );
    } else {
      const orFileInput = document.getElementById(`orfile-input-${index}`);
      if (orFileInput) {
        orFileInput.value = "";
      }
      orFileHandler = undefined;
    }
    setOrFileName((prevOrFileName) => {
      const updatedOrFileName = [...prevOrFileName];
      updatedOrFileName[index] = orFileNameHandler;
      return updatedOrFileName;
    });
    setOrFile((prevOrFile) => {
      const updatedOrFile = [...prevOrFile];
      updatedOrFile[index] = orFileHandler;
      return updatedOrFile;
    });
    setIsOrFileChange((prevIsOrFileChange) => {
      const updatedIsOrFileChange = [...prevIsOrFileChange];
      updatedIsOrFileChange[index] = orFileHandler ? true : false;
      return updatedIsOrFileChange;
    });
  };

  //Function to contract file into a new tab
  const viewFileClickHandler = (event) => {
    event.preventDefault();

    const openedWindow = window.open(
      props.item.contract_file,
      props.item.contract_number
    );

    if (openedWindow) {
      openedWindow.focus();
    }
  };

  //Function to close the modal
  const closeModalHandler = () => {
    setFeedbackModal(null);
    setImageModal(null);
  };

  //Function to set the feedback for the feedback modal
  const feedbackClickHandler = (item) => {
    setFeedbackModal({
      contractNumber: item.contract_number,
      feedback: item.feedback,
    });
  };
  // RETURN STATEMENT
  return (
    <>
      {/* Conditionally render feedback modal if feedback is not undefined */}
      {feedbackModal && (
        <FeedbackModal feedback={feedbackModal} onClose={closeModalHandler} />
      )}
      {/* Conditionally render image modal if image is not undefined */}
      {imageModal && (
        <ImageModal image={imageModal} onClose={closeModalHandler} />
      )}
      {props.method === "patch" && (
        <div className={classes["status-feedback"]}>
          <p>Status</p>
          {userRole === "UTASI_ENCODER" ? (
            <>
              {![
                "PENDING",
                "RETURNED",
                "DISAPPROVED",
                "FOR INVOICING",
                "INVOICED"
              ].includes(props.item.status) ? (
                <div
                  className={`${statusClasses.done} ${statusClasses.status}`}
                >
                  Done
                </div>
              ) : (
                <div
                  className={`${
                    statusClasses[
                      props.item.status.toLowerCase().replace(" ", "-")
                    ]
                  } ${statusClasses.status}`}
                >
                  {titleCase(props.item.status)}
                </div>
              )}
            </>
          ) : (
            <div
              className={`${
                statusClasses[props.item.status.toLowerCase().replace(" ", "-")]
              } ${statusClasses.status}`}
            >
              {titleCase(props.item.status)}
            </div>
          )}
          <p>Feedback</p>
          <img
            src={feedbackSVG}
            alt="feedback-btn"
            onClick={() => feedbackClickHandler(props.item)}
          />
        </div>
      )}
      <div className={classes.contract}>
        {/* FORM LOADING */}
        {navigation.state !== "idle" && <FormLoading />}
        <div className={classes["contract-details"]}>
          <div className={classes.group}>
            <table>
              {[0, 1].map((number) => (
                <React.Fragment key={number}>
                  <thead>
                    <tr>
                      {CONTRACT_HEADERS[number].map((header) => (
                        <th key={header.key}>{header.header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* DROPDOWNNNN */}
                      {CONTRACT_HEADERS[number].map((header) => (
                        <td name={header.key} key={header.key}>
                          {props.item
                            ? customStyling(props.item[header.key], header.key)
                            : `‎`}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </React.Fragment>
              ))}
            </table>
            <div className={classes["file-actions"]}>
              {props.method === "patch" && (
                <button className={classes.btn} onClick={viewFileClickHandler}>
                  View Contract File
                </button>
              )}
              {props.item && (
                <div className={classes["file-input"]}>
                  <label htmlFor="file-upload">Upload File</label>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                  />
                  <p>
                    {contractFileName ? contractFileName : "No File Chosen"}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className={classes.group}>
            <table className={classes["contract-details-assets"]}>
              <thead>
                <tr>
                  {CONTRACT_HEADERS[2].map((header) => (
                    <th key={header.key}>{header.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{assetRows}</tbody>
            </table>
          </div>
        </div>
        <div className={classes["contract-assets"]}>
          <h2>Asset Breakdown</h2>
          <table>
            <thead>
              <tr>
                {UPDATED_ASSET_HEADERS.map((header) => (
                  <th key={header.key}>{header.header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {props.item ? (
                props.item.assets.map((asset, index) => (
                  <tr key={index}>
                    {UPDATED_ASSET_HEADERS.map((header) => (
                      <td key={header.key}>
                        {header.dropdown && header.key === "asset_station" ? (
                          <ReactSelect
                            value={assetStations[index]}
                            onChange={(option) =>
                              handleDropdownStation(option, index)
                            }
                            options={stationOptions}
                            closeMenuOnSelect={true}
                            hideSelectedOptions={false}
                            allowSelectAll={false}
                            styles={customStyles}
                          />
                        ) : (
                          customStyling(asset[header.key], header.key, index)
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  {UPDATED_ASSET_HEADERS.map((header) => (
                    <td key={header.key}>‎</td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
          {props.item && (
            <button
              className={classes["submit-btn"]}
              disabled={
                props.method === "post"
                  ? !contractFile ||
                    assetImage.length === 0 ||
                    assetImage.includes(undefined)
                  : false
              }
              onClick={submitContractHandler}
            >
              {props.method === "post"
                ? "Submit New Contract"
                : "Submit Edited Contract"}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default ContractForm;

export const action = async ({ request }) => {
  //Pull all date from the form data
  let method = request.method;
  method = method.toString().toLowerCase();
  const data = await request.formData();
  const contractItem = JSON.parse(data.get("contractItem"));
  const {
    isContractFileChange,
    isAssetImageChange,
    isAssetFileChange,
    isOrFileChange,
  } = JSON.parse(data.get("flags"));
  const { assetImage, assetFile, orFile } = JSON.parse(data.get("media"));
  const { uid, name, role } = getTokenData();
  const token = getAuthToken();
  const apiURL = process.env.REACT_APP_API_URL;

  // Get selected Stations from the request data
  // const selectedStations = JSON.parse(data.get("selectedStations"));
  const assetStations = JSON.parse(data.get("assetStations"));

  //Get current date and time for the date submitted
  const now = new Date();
  now.setUTCHours(now.getUTCHours() + 8);

  // Determine if asset_file has been submitted
  // const assetFileSubmitted = assetFile.some((file) => file !== undefined);
  // const orFileSubmitted = orFile.some((file) => file !== undefined);
  const assetFileSubmitted = assetFile.some(
    (file) => file !== undefined && file !== ""
  );
  const orFileSubmitted = orFile.some(
    (file) => file !== undefined && file !== ""
  );

  console.log("ASSETFILEE", assetFileSubmitted);
  console.log("ORFILEE", orFileSubmitted);
  //Build the request body for the new or updated contract
  const requestBody = {
    ...contractItem,
    submitted_by: role === "ENCODER" ? uid : contractItem.submitted_by,
    status:
      role === "ENCODER"
        ? assetFileSubmitted
          ? orFileSubmitted
            ? "COMPLETE" // Set to "COMPLETE" when both assetFile and orFile are submitted
            : "INVOICED" // Set to "INVOICED" when only assetFile is submitted
          : orFileSubmitted
          ? "ISSUED OR"
          : "PENDING"
        : "PENDING", // Set to "PENDING" for other roles
    date_submitted: now.toISOString(),
    contract_file: data
      .get("contractFile")
      .replace("data:application/pdf;base64,", ""),
    contract_file_change: isContractFileChange,
    assets: contractItem.assets.map((asset, index) => {
      return {
        ...asset,
        asset_image: assetImage[index] ? assetImage[index] : asset.asset_image,
        asset_image_change: isAssetImageChange[index]
          ? isAssetImageChange[index]
          : false,
        asset_file: assetFile[index]
          ? assetFile[index].replace("data:application/pdf;base64,", "")
          : asset.asset_file,
        asset_file_change: isAssetFileChange[index]
          ? isAssetFileChange[index]
          : asset.asset_file_change,
        or_file: orFile[index]
          ? orFile[index].replace("data:application/pdf;base64,", "")
          : asset.or_file,
        or_file_change: isOrFileChange[index]
          ? isOrFileChange[index]
          : asset.or_file_change,
        // asset_stations: selectedStations[index],
        asset_stations: assetStations[index],
      };
    }),
  };

  console.log("REQUESTBODY", requestBody);

  //API function to update or post the contract submission
  const response = await axios[method](
    `${apiURL}/contract/submit`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  let notificationMessage;
  let contractHistoryMessage;

  //Switch case to update the history and notification message for different scenarios
  switch (role) {
    case "ENCODER": {
      if (method === "patch") {
        notificationMessage = `An Encoder has submitted an edited version of Contract #${contractItem.contract_number} for your review.`;

        contractHistoryMessage = `Edited Contract resubmission by ${name}`;
        if (isContractFileChange) {
          contractHistoryMessage += " (Contract file has been changed";
        }
        const changedAssets = [];
        isAssetImageChange.forEach((assetImageChange, index) => {
          if (assetImageChange) {
            changedAssets.push(`Asset #${index + 1} image has been changed`);
          }
        });
        if (changedAssets.length > 0) {
          const assetsMessage = changedAssets.join(", ");
          contractHistoryMessage +=
            (isContractFileChange ? ", " : " (") + assetsMessage;
        }
        if (isContractFileChange || changedAssets.length > 0) {
          contractHistoryMessage += ")";
        }
      } else if (method === "post") {
        notificationMessage = `An Encoder has submitted a new Contract #${contractItem.contract_number} for your review.`;
        contractHistoryMessage = `New Contract Submission by ${name}`;
      }

      pushHistory({
        contract_number: contractItem.contract_number,
        content: contractHistoryMessage,
        company: "UTASI",
      });

      notify({
        sender: uid,
        receiver: "",
        user_role: "UTASI_MANAGER",
        contract_number: contractItem.contract_number,
        notification: notificationMessage,
      });
      break;
    }

    // case "MANAGER": {
    case "ENCODER": {
      notificationMessage = `ENCODER ${name} added invoice files`;
      contractHistoryMessage = `ENCODER ${name} added invoice files`;
      pushHistory({
        contract_number: contractItem.contract_number,
        content: contractHistoryMessage,
        company: "LRMC",
      });
      break;
    }
    default:
      break;
  }

  return response;
};
