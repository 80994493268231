import React, { useEffect, useState } from "react";
import logo from "../../assets/logo.png";
import userSVG from "../../assets/user.svg";
import bellSVG from "../../assets/bell.svg";
import { NavLink, useNavigate } from "react-router-dom";
import classes from "./MainNavigation.module.css";
import { useDispatch, useSelector } from "react-redux";
import NotificationsMenu from "../UI/NotificationsMenu";
import { getAuthToken, getTokenData } from "../../utils/auth";
import axios from "axios";
import { notificationActions } from "../../store/notification-slice";
import { Button } from "bootstrap";
const apiURL = process.env.REACT_APP_API_URL;

const MainNavigation = ({ role }) => {
  const navigate = useNavigate();
  let userRole = role;
  if (userRole === "UTASI_ENCODER") {
    userRole = "ENCODER";
  }

  const hasUnread = useSelector((state) => state.notification.hasUnread);
  const [showNotifications, setShowNotifications] = useState(false);

  const profileClickHandler = (event) => {
    event.preventDefault();
    navigate("profile");
  };

  function showNotificationClickHandler() {
    setShowNotifications(!showNotifications);
  }
  const dispatch = useDispatch();

  useEffect(() => {
    //useEffect used to check if a user has unread notifications
    const { uid } = getTokenData();
    const token = getAuthToken();

    const requestBody = {
      user_id: uid,
      user_role: userRole,
    };
    async function getNotifications() {
      //pull all notifications
      const response = await axios.post(`${apiURL}/notif`, requestBody, {
        headers: { Authorization: `Bearer ${token}` },
      });
      //check if notifications has unread and set state accordingly
      dispatch(
        notificationActions.setHasUnread(
          response.data.some((data) => data.isRead === 0) ? true : false
        )
      );
    }

    getNotifications();
  }, [userRole, dispatch]);

  return (
    <header className={classes.header}>
      <div className={classes.block}>
        <div className={classes.logo}>
          <NavLink to="/" end>
            <img className={classes.image} src={logo} alt="LRMC LOGO" />
          </NavLink>
        </div>
        <nav>
          <ul className={classes.list}>
            {userRole === "ENCODER" && (
              <>
                <li>
                  <NavLink
                    to="/contracts/track"
                    className={({ isActive }) =>
                      isActive ? classes.active : undefined
                    }
                  >
                    Submissions & Assignments
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contracts/new"
                    className={({ isActive }) =>
                      isActive ? classes.active : undefined
                    }
                  >
                    New Contracts
                  </NavLink>
                </li>
              </>
            )}
            {/* Add user role for dashboard */}
            {(userRole === "UTASI_MANAGER" || userRole === "LRMC_MANAGER") && (
              <>
                <li>
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) =>
                      isActive ? classes.active : undefined
                    }
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contracts"
                    className={({ isActive }) =>
                      isActive ? classes.active : undefined
                    }
                  >
                    Contracts
                  </NavLink>
                </li>
              </>
            )}
            {userRole === "ADMIN_ADMIN" && (
              <>
                <li>
                  <NavLink
                    to="/dashboard"
                    className={({ isActive }) =>
                      isActive ? classes.active : undefined
                    }
                  >
                    Dashboard
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/contracts"
                    className={({ isActive }) =>
                      isActive ? classes.active : undefined
                    }
                  >
                    Contracts
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/users"
                    className={({ isActive }) =>
                      isActive ? classes.active : undefined
                    }
                  >
                    Users
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
      <div className={classes.block}>
        <div className={classes.notification}>
          <img
            id="bell"
            src={bellSVG}
            alt="Bell Icon"
            onClick={showNotificationClickHandler}
          />
          {hasUnread && <div className={classes.circle}></div>}
        </div>
        {showNotifications && (
          <NotificationsMenu
            isOpen={showNotifications}
            onClose={showNotificationClickHandler}
          />
        )}
        <img
          className={classes.profile}
          src={userSVG}
          alt="User Icon"
          onClick={profileClickHandler}
        />
      </div>
    </header>
  );
};

export default MainNavigation;
