import React, { Suspense, useEffect } from "react";
import {
  Await,
  defer,
  useActionData,
  useLoaderData,
  useNavigate,
} from "react-router-dom";
import ContractForm from "../../components/ContractForm";
import { getAuthToken } from "../../utils/auth";
import axios from "axios";
import Loading from "../../components/UI/Loading";
import { useDispatch } from "react-redux";
import { alert } from "../../utils/alert";
import ContractHistory from "../../components/ContractHistory";
const apiURL = process.env.REACT_APP_API_URL


const EditContractPage = () => {
  const { contractData, contractHistory } = useLoaderData();
  const actionData = useActionData();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    //Check if edit is succesful
    if (actionData && actionData.status === 200) {
      //Display an alert
      alert("Contract Edited Succesfully!", "green", dispatch);
      //Navigate back to contracts
      navigate("/contracts/");
    }
  }, [actionData, navigate, dispatch]);

  return (
    <main>
      <Suspense fallback={<Loading />}>
        <Await resolve={contractData}>
          {(loadedContractData) => (
            <ContractForm method="patch" item={loadedContractData} />
          )}
        </Await>
        <Await resolve={contractHistory}>
          {(loadedHistoryData) => (
            <ContractHistory history={loadedHistoryData} />
          )}
        </Await>
      </Suspense>
    </main>
  );
};

export default EditContractPage;

//API function to load the contracts for a specific submission
const loadContract = async (params) => {
  const token = getAuthToken();

  const requestBody = {
    sales_order_code: params.contractNumber,
  };
  const response = await axios.post(
    `${apiURL}/contract`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  //Fix data to follow the date and time formats and the initial flags
  return {
    ...response.data.contract,
    start_date: new Date(response.data.contract.start_date)
      .toISOString()
      .split("T")[0],
      end_date:  new Date(response.data.contract.end_date)
      .toISOString()
      .split("T")[0],
    assets: response.data.contract.assets.map((asset) => {
      return {
        ...asset,
        asset_date_created: new Date(asset.asset_date_created)
          .toISOString()
          .split("T")[0],
        asset_live_date: new Date(asset.asset_live_date)
          .toISOString()
          .split("T")[0],
          asset_end_date: new Date(asset.asset_end_date)
          .toISOString()
          .split("T")[0],
        asset_image_change: false,
        asset_file_change: false,
        or_file_change: false,
      };
    }),
  };
};

//API function to load the history of a specific contract submission
export async function loadHistory(params) {
  const token = getAuthToken();

  const requestBody = {
    contract_number: params.contractNumber,
  };
  const response = await axios.post(
    `${apiURL}/contract/gethistory`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response.data;
}

//loader function for edit contract defered to navigate before loading the response data
export const loader = ({ params }) => {
  return defer({
    contractData: loadContract(params),
    contractHistory: loadHistory(params),
  });
};
