import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import classes from "./PieChart.module.css";

const PieChart = (props) => {
  const { chartData, chartRef } = props;

  useEffect(() => {
    const { chartData } = props;

    // Clear the existing chart instance if it exists
    if (chartRef.current) {
      echarts.dispose(chartRef.current);
    }

    if (chartData && Object.keys(chartData).length > 0) {
      const myChart = echarts.init(chartRef.current);

      const data = Object.entries(chartData).map(([name, value]) => ({
        name,
        value,
      }));

      // Use the use method to enable the datalabels plugin
      //echarts.use([require("echarts/extension/dataTool"), require("echarts/extension/bmap/bmap")]);

      const option = {
        tooltip: {
          trigger: "item",
        },
        title: {
          text: `Revenue Breakdown in Millions - ${new Date().toLocaleString(
            "default",
            {
              month: "long",
            }
          )} ${new Date().getFullYear()}`,
          left: "center",
          textStyle: {
            color: "#0070c0",
            fontSize: 14,
          },
        },
        legend: {
          bottom: "10%",
          left: "center",
        },
        series: [
          {
            name: "Revenue Breakdown in Millions",
            type: "pie",
            color: ["#4472c4", "#ed7d31", "#a5a5a5"],
            radius: ["40%", "60%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              position: "inside",
              //formatter: "{b}: {c}M",
              formatter: "{c}%",
              fontSize: 20,
            },
            value: {
              show: true,
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 25,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: true,
            },
            data,
          },
        ],
      };

      myChart.setOption(option);
      chartRef.current = myChart;
    }
  }, [props]);

  const handleDownloadClick = () => {
    const dataURL = chartRef.current.getDataURL({
      type: "png",
      pixelRatio: 2,
      backgroundColor: "#fff",
    });

    // Create a link and trigger the download
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "pie_chart.png";
    link.click();
  };

  return (
    <div className={classes.main}>
      {/* <button className={classes.dButton2} onClick={handleDownloadClick}>
        Download
      </button> */}
      <div
        ref={chartRef}
        style={{
          width: "100%",
          height: "350px",
          justifyContent: "center",
          display: "flex",
          position: "relative",
        }}
      />
    </div>
  );
};

export default PieChart;
