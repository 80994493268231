import React, { useState, useEffect } from "react";
import { Link, useNavigate, useSubmit, useLocation } from "react-router-dom";
import classes from "./SubmissionTable.module.css";
import FeedbackModal from "./UI/FeedbackModal";
import { useSelector } from "react-redux";
import DisapproveModal from "./UI/DisapproveModal";
import { SUBMISSION_HEADERS } from "../utils/table-headers";
import { titleCase, toPHPValue } from "../utils/converters";
import edit from "../assets/actions/edit.svg";
import feedback from "../assets/actions/feedback.svg";
import approve from "../assets/actions/approve.svg";
import disapprove from "../assets/actions/disapprove.svg";
import returns from "../assets/actions/return.svg";
import ApproveModal from "./UI/ApproveModal";
import Papa from "papaparse";
import { getTokenData } from "../utils/auth";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import datesData from './dates.json';
import axios from "axios";
import { format } from 'date-fns';

const SubmissionTable = ({ tableData, statusFilter  }) => {
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const { name, company } = getTokenData();
  const apiURL = process.env.REACT_APP_API_URL;

  //Download selection

  const [selectedRows, setSelectedRows] = useState([]);
  const toggleRowSelection = (contractNumber) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(contractNumber)
        ? prevSelectedRows.filter((row) => row !== contractNumber)
        : [...prevSelectedRows, contractNumber]
    );
  };

  const handleDownloadSelected = () => {

    //Logs Download
    fetch(`${apiURL}/analytics/savedlhistory`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          "content": `Contracts downloaded by ${name}`,
          "company": `${company}`
      })
  })
  
     .then(response => {
      if (!response.ok) {
          throw new Error('Failed to log download');
        }
        console.log('Download logged successfully');
      })
      .catch(error => {
        console.error('Error logging download:', error);
      });
    //Logs Download end

    const selectedData = tableData.filter((row) =>
      selectedRows.includes(row.contract_number)
    );

    const csv = Papa.unparse(selectedData, {
      header: true,
      skipEmptyLines: true,
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `selected_submissions.csv`;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  //Download selection end

  const [feedbackModal, setFeedbackModal] = useState();
  const [disapproveModal, setDisapproveModal] = useState();
  const [approveModal, setApproveModal] = useState();
  const [assetFileSubmitted, setAssetFileSubmitted] = useState(false); 
  const [orFileSubmitted, setOrFileSubmitted] = useState(false); 
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // filter

  const [filteredData, setFilteredData] = useState(tableData);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const statusFilter = searchParams.get("status");
  
    if (statusFilter === "COMPLETE" || statusFilter === "INVOICED") {
      // Filter the data based on the status
      let filtered;
      if (startDate && endDate) {
        filtered = tableData.filter((row) => {
          const rowDate = new Date(row.date_submitted);
          return row.status === statusFilter && rowDate >= startDate && rowDate <= endDate;
        });
      } else {
        filtered = tableData.filter((row) => row.status === statusFilter);
      }
      setFilteredData(filtered);
    } else {
      // If no filter is applied, show all data
      setFilteredData(tableData);
    }
  }, [tableData, location.search, startDate, endDate]);

  // filter end


  // Download Button
  const handleDownloadAll = () => {

    //Logs Download
    fetch(`${apiURL}/analytics/savedlhistory`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          "content": `Contracts downloaded by ${name}`,
          "company": `${company}`
      })
  })
  
     .then(response => {
      if (!response.ok) {
          throw new Error('Failed to log download');
        }
        console.log('Download logged successfully');
      })
      .catch(error => {
        console.error('Error logging download:', error);
      });
      //Logs Download end

    const csv = Papa.unparse(filteredData, {
      header: true,
      skipEmptyLines: true,
    });

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `submission_table.csv`; // You can customize the filename
    document.body.appendChild(a);
    a.click();

    // Cleanup
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const navigate = useNavigate();
  const userRole = useSelector((state) => state.user.role);
  const submit = useSubmit();

  const closeModalHandler = () => {
    setDisapproveModal(null);
    setFeedbackModal(null);
    setApproveModal(null);
  };

  const feedbackClickHandler = (row) => {
    setFeedbackModal({
      contractNumber: row.contract_number,
      feedback: row.feedback,
    });
  };

  // Callback to handle asset file submission
  const handleAssetFileSubmission = () => {
    setAssetFileSubmitted(true);
  };

  // Callback to handle asset file submission
  const handleOrFileSubmission = () => {
    setOrFileSubmitted(true);
  };

   // useEffect to reset assetFileSubmitted when the component unmounts
   useEffect(() => {
    return () => {
      setAssetFileSubmitted(false);
    };
  }, []);

  useEffect(() => {
    return () => {
      setOrFileSubmitted(false);
    };
  }, []);

  const handleConfirm = (row) => {
    let status;
    if (userRole === "UTASI_MANAGER") {
      status = "FOR APPROVAL";
    } else if (userRole === "LRMC_MANAGER") {
      status = "FOR INVOICING"
    } else if (userRole === "UTASI_ENCODER") {
       // Check if the current status is "PENDING" and either an asset file or an or file has been submitted
    if (row.status === "INVOICED" &&  orFileSubmitted) {
      status = "ISSUED OR";
    } else if (row.status === "PENDING" && assetFileSubmitted) {
      status = "INVOICED";
    } else {
      status = row.status;
    }
  }

    console.log(status);
    if (status) {
      submit(
        {
          status: status,
          contractNumber: row.contract_number,
          submittedBy: row.submitted_by,
        },
        { action: "/contracts/submissions", method: "patch" }
      );
    }
    closeModalHandler();
  };
  const approveClickHandler = (row) => {
    setApproveModal(row);
  };

  const disapproveClickHandler = (row) => {
    setDisapproveModal({ ...row, role: userRole });
  };

  const customFormat = (row, key, assetFileSubmitted, orFileSubmitted, index) => {
    if (key === "total_amount") {
      return toPHPValue(row[key]);
    }
    if (key === "contract_number") {
      return (
        <Link
          className={classes.link}
          to={`${encodeURIComponent(row[key].replace("/", "%2F"))}`}
        >
          {row[key]}
        </Link>
      );
    }
    if (key === "date_submitted") {
      if (startDate && endDate) {
        const rowDate = new Date(row[key]);
  
        if (rowDate >= startDate && rowDate <= endDate) {
          return row[key].substring(0, 10);
        }
  
        return null; 
      }
      
    return row[key].substring(0, 10);
  }

  if (key === "end_dates") {
    const endDateFromJson = datesData.find(date => date.id === row.id)?.date;
    const endDate = endDateFromJson ? new Date(endDateFromJson).toLocaleDateString() : "";
  
    return endDate;
  }
      
    // STATUS
    if (key === "status") {
      switch (userRole) {
        case "UTASI_ENCODER": {
          if (
            // row[key] !== "FOR INVOICING" &&
            // row[key] !== "RETURNED" &&
            // row[key] !== "PENDING"
            row[key] === "INVOICED"
          ) {
            return (
              <div className={`${classes.done} ${classes.status}`}>INVOICED</div>
            );
          } else if (row[key] === "FOR INVOICING" && row.invoice_submitted) {
            return (
              <div className={`${classes.invoiced} ${classes.status}`}>
                Done
              </div>
            );
          } else
            return (
              <div
                className={`${
                  classes[row[key].toLowerCase().replace(" ", "-")]
                } ${classes.status}`}
              >
                {titleCase(row[key])}
              </div>
            );
        }
        default:
          return (
            <div
              className={`${
                classes[row[key].toLowerCase().replace(" ", "-")]
              } ${classes.status}`}
            >
              {titleCase(row[key])}
            </div>
          );
      }
    }
    if (key === "actions") {
      const editButton = (
        <span
          className={classes["action-btn"]}
          onClick={() =>
            navigate(
              `edit/${encodeURIComponent(
                row.contract_number.replace("/", "%2F")
              )}`
            )
          }
        >
          <img src={edit} alt="edit-btn" />
          <p>Edit</p>
        </span>
      );
      const feedbackButton = (
        <span
          className={classes["action-btn"]}
          onClick={() => feedbackClickHandler(row)}
        >
          <img src={feedback} alt="feedback-btn" />
          <p>Feedback</p>
        </span>
      );

      const approveButton = (
        <span
          className={classes["action-btn"]}
          onClick={() => approveClickHandler(row)}
        >
          <img src={approve} alt="approve-btn" />
          <p>Approve</p>
        </span>
      );

      const returnButton = (
        <span
          className={classes["action-btn"]}
          onClick={() => disapproveClickHandler(row)}
        >
          <img
            src={userRole === "LRMC_MANAGER" ? disapprove : returns}
            alt="return-btn"
          />
          <p>{userRole === "LRMC_MANAGER" ? "Disapprove" : "Return"}</p>
        </span>
      );

      switch (userRole) {
        case "UTASI_ENCODER": {
          if (row.status === "PENDING") {
            return <div className={classes["action-div"]}>{editButton}</div>;
          } else if (row.status === "RETURNED") {
            return (
              <div className={classes["action-div"]}>
                {editButton}
                {feedbackButton}
              </div>
            );
            // ADDING INVOICE TO ENCODER
          } else if (row.status === "FOR INVOICING") {
            return <div className={classes["action-div"]}>{editButton}</div>;
          } else if (row.status === "INVOICED") {
            return <div className={classes["action-div"]}>{editButton}</div>;
          } else if (row.status === "ISSUED OR") {
            return <div className={classes["action-div"]}>Complete</div>;
          } else return;
        }
        case "UTASI_MANAGER": {
          if (row.status === "PENDING") {
            return (
              <div className={classes["action-div"]}>
                {approveButton}
                {returnButton}
              </div>
            );
          }
          if (row.status === "DISAPPROVED") {
            return (
              <div className={classes["action-div"]}>
                {returnButton}
                {feedbackButton}
              </div>
            );
          }
          if (row.status === "FOR APPROVAL") {
            return <p style={{ margin: 0 }}>Awaiting LRMC Approval</p>;
          }
          if (row.status === "INVOICED") {
            return <div className={classes["action-div"]}>FOR OR</div>;
          }
          // added
          if (row.status === "ISSUED OR") {
            return <div className={classes["action-div"]}>Complete</div>;
          }
          break;
        }

        case "LRMC_MANAGER": {
          if (row.status === "FOR APPROVAL")
            return (
              <div className={classes["action-div"]}>
                {approveButton}
                {returnButton}
              </div>
            );
          if (row.status === "INVOICED") {
            return <div className={classes["action-div"]}>FOR OR</div>;
          }
           if (row.status === "ISSUED OR") {
            return <div className={classes["action-div"]}>Complete</div>;
          }
          break;
        }
        // SUPER ADMIN
        case "ADMIN_ADMIN": {
          if (row.status === "FOR APPROVAL")
            return (
              <div className={classes["action-div"]}>
                {approveButton}
                {returnButton}
              </div>
            );
          if (row.status === "ISSUED OR") {
            return <div className={classes["action-div"]}>Complete</div>;
          }
          if (row.status === "INVOICED") {
            return <div className={classes["action-div"]}>FOR OR</div>;
          }
          break;
        }
        default:
          return null;
      }
    }

    return row[key];
  };

  return (
    <>
    <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(props) => (
              <TextField
                {...props}
                variant="standard"
                margin="dense"
                helperText=""
                sx={{
                  "& .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root": {
                    marginTop: "16px",
                  },
                }}
              />
            )}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(props) => (
              <TextField
                {...props}
                variant="standard"
                margin="dense"
                helperText=""
                sx={{
                  "& .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root": {
                    margin: "16px",
                    padding: "16px",
                    height: "75px",
                  },
                  "& .MuiButtonBase-root.MuiIconButton-root" : {
                    padding: "4px !important"
                  }
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
      {approveModal && (
        <ApproveModal
          approve={approveModal}
          onClose={closeModalHandler}
          onConfirm={handleConfirm}
          contractNumber={approveModal.contract_number}
          submittedBy={approveModal.submitted_by}
        />
      )}
      {feedbackModal && (
        <FeedbackModal feedback={feedbackModal} onClose={closeModalHandler} />
      )}
      {disapproveModal && (
        <DisapproveModal
          onClose={closeModalHandler}
          role={disapproveModal.role}
          contractNumber={disapproveModal.contract_number}
          submittedBy={disapproveModal.submitted_by}
        />
      )}
      <div className="button-container-selected">
      <button className="download-btn-select" onClick={handleDownloadSelected}>Download Selected</button>
      </div>
      <div className="button-container">
      <button className="download-btn" onClick={handleDownloadAll}>Download All</button>
      </div>
      {filteredData && (
        <div className={classes["table-bg"]}>

          <table className={classes.table}>
            <thead>
            {/* <tr>
                <th colSpan={SUBMISSION_HEADERS.length}>
                 
                </th>
              </tr> */} 
              <tr>
                {/*<div></div>
                {SUBMISSION_HEADERS.map((header) => (
                  <th
                    className={
                      header.key === "status" || header.key === "actions"
                        ? classes.center
                        : ""
                    }
                    key={header.key}
                  >
                    {header.header}
                  </th>
                ))}
                  */}
                
                <th className={classes.center}>
                </th>
                <th>Contract#</th>
            {SUBMISSION_HEADERS.slice(1).map((header) => (
              <th
                className={
                  header.key === "status" || header.key === "actions"
                    ? classes.center
                    : ""
                }
                key={header.key}
              >
                {header.header}
              </th>
            ))}
              </tr>
            </thead>
            <tbody>
              {filteredData
              .filter((row) => customFormat(row, "date_submitted", assetFileSubmitted, orFileSubmitted) !== null)
              .map((row, index) => {
                const endDateFromJson = datesData.dates[index]?.date;
                const endDate = endDateFromJson ? format(new Date(endDateFromJson), 'yyyy-MM-dd') : "";
                return (
                  <tr key={index}>
                  <td className={classes.center}>
                    {/* Checkbox in Contract# column */}
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(row.contract_number)}
                        onChange={() => toggleRowSelection(row.contract_number)}
                      />
                    </label>
                  </td>
                    {SUBMISSION_HEADERS.map((header) => (
                      <td
                        className={
                          header.key === "status" || header.key === "actions"
                            ? classes.center
                            : ""
                        }
                        key={header.key}
                      >
                        {header.key === "end_dates" ? endDate : customFormat(row, header.key)}
                      </td>
                      ))}
                      
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default SubmissionTable; 
