import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./styles/FillRateReportPage.module.css";
import LineChart from "../../components/Dashboard/LineChart";
import { FILL_RATE_HEADERS } from "../../utils/table-headers";
import FillRateDownload from "./FillRateDatePicker";



const FillRateReportPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [fillRateData, setFillRateData] = useState({
    Trains:[],
    Stations: [],
    "Pillars & Viaducts": []
  });
  const [trainsData, setTrainsData] = useState([]);
  const [stationsData, setStationsData] = useState([]);
  const [pillarsViaduct, setPillarsViaduct] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(0);

  const navigate = useNavigate();
  const titles = [
    "Train Fill Rate Report",
    "Station Assets Fill Rate Report",
    "Pillar & Viaduct Fill Rate Report",
  ];
  const [title, setTitle] = useState(titles[0]);
  const radioLabels = ["Trains", "Stations", "Pillars & Viaducts"];


  async function fetchData() {
    try {
      const response = await fetch(`${apiURL}/analytics/fillrate`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      if (response.ok) {
        const data = await response.json();
  
        console.log("Fill Rate Data:", data);
  
        setFillRateData({
          Trains: data.Trains,
          Stations: data.Stations,
          "Pillars & Viaducts": data["Pillars & Viaducts"],
        });
      } else {
        console.log("Error fetching data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData(radioLabels[selectedRadio] === "Trains");
  }, [selectedRadio])
  
  function radioInputChangeHandler(event) {
    const selectedIndex = parseInt(event.target.value, 10);
    setSelectedRadio(selectedIndex); 
    setTitle(titles[event.target.value]);
  }
  function backClickHandler() {
    navigate("..");
  }
  
  function downloadClickHandler() {
    // Combine headers and data from all radio buttons
    const allHeaders = ["Name", ...FILL_RATE_HEADERS];
    const allData = [
      ...fillRateData.Trains,
      ...fillRateData.Stations,
      ...fillRateData["Pillars & Viaducts"],
    ];

    // Convert data to CSV format
    const csvData = convertDataToCSV(allHeaders, allData);

    // Create a Blob and create a link to trigger the download
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "fill_rate_data.csv";
    link.click();
  }

  function convertDataToCSV(headers, data) {
    // Example CSV format (customize based on your data structure)
    let csvString = "";

    // Add header row
    csvString += headers.join(",") + "\n";

    // Add data rows
    data.forEach((item) => {
      csvString += `${item.name},${item.value.join(",")}\n`;
    });

    return csvString;
  }
  const [aspectRatio, setAspectRatio] = useState(null);
  const [reload, setReload] = useState(false);

  //useEffect that checks if the user is resizing
  useEffect(() => {
    function handleResize() {
      const { innerWidth, innerHeight } = window;
      const newAspectRatio = innerWidth / innerHeight;
      setAspectRatio(newAspectRatio);
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //useEffect that reloads the chart once user is done resizing
  useEffect(() => {
    let identifier;

    if (aspectRatio !== null) {
      identifier = setTimeout(() => {
        setReload((r) => !r);
      }, 100);
    }

    return () => {
      clearTimeout(identifier);
    };
  }, [aspectRatio, setReload]);

  return (
    <main className={classes.main}>
      <div className={classes.navigation}>
        <div className={classes.radios}>
          {/*<p>Tick to display</p>  I moved it under radion button */}
          <div className={classes.radio}>
            {radioLabels.map((label, index) => (
              <label className={classes["radio-button"]} key={index}>
                <input
                  type="radio"
                  name="radio"
                  defaultChecked={index === 0 ? true : false}
                  value={index}
                  onChange={radioInputChangeHandler}
                />
                {label}
              </label>
            ))}
          </div>
          {/*<p>Tick to display</p>*/}
        </div>
        <div className={classes.buttons}>
          <button onClick={backClickHandler}>Back to Main Menu</button>
          <FillRateDownload />
          {/* <button onClick={downloadClickHandler}>Download</button> */}
        </div>
      </div>
      <LineChart reload={reload} title={title} chartData={ selectedRadio === 0 
       ? fillRateData.Trains
       : selectedRadio === 1
       ? fillRateData.Stations
       : fillRateData["Pillars & Viaducts"]
      }
       />
      <table className={classes.table}>
        <thead>
          <tr>
            {FILL_RATE_HEADERS.map((month, index) => (
              <th key={index}>{month}</th>
            ))}
          </tr>
        </thead>
        <tbody> 
        {selectedRadio === 0 //Alphabetical Order
        ? fillRateData.Trains
        .sort((a, b) => a.name.localeCompare(b.name)) // Sort by asset name
        .map((item, index) => (
          <tr key={index}>
            <td className={classes.name}>{item.name}</td>
            {item.value.map((val, innerIndex) => (
              <td key={innerIndex}>{val}</td>
            ))}
          </tr>
          ))
          : selectedRadio === 1
          ? fillRateData.Stations
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort by asset name
          .map((item, index) => (
            <tr key={index}>
            <td className={classes.name}>{item.name}</td>
            {item.value.map((val, innerIndex) => (
              <td key={innerIndex}>{val}</td>
            ))}
          </tr>
          ))
          : fillRateData["Pillars & Viaducts"]
          .sort((a, b) => a.name.localeCompare(b.name)) // Sort by asset name
          .map((item, index) => (
            <tr key={index}>
            <td className={classes.name}>{item.name}</td>
            {item.value.map((val, innerIndex) => (
              <td key={innerIndex}>{val}</td>
            ))}
          </tr>
          ))}
          </tbody>
          </table>
    </main>
  );
};

export default FillRateReportPage;
