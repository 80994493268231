import axios from "axios";
import React from "react";
import { redirect, useLoaderData } from "react-router-dom";
import UserForm from "../../components/User/UserForm";
import { getAuthToken } from "../../utils/auth";
import classes from "../Background.module.css";
const apiURL = process.env.REACT_APP_API_URL


const EditUserPage = () => {
  const data = useLoaderData();
  return (
    <main className={classes.main}>
      <UserForm user={data} method={"post"} title={"Edit User"} />
    </main>
  );
};

export default EditUserPage;

//loader function to load user details
export const loader = async ({ params }) => {
  const token = getAuthToken();
  const requestBody = { user_id: params.uid };

  //API function to get specific user details based on uid
  const response = await axios.post(
    `${apiURL}/users/user`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  const userData = response.data.user_info;

  //Build user data form the response
  const user = {
    role: userData[0].role,
    uid: params.uid,
    contactNumber: userData[0].contact_number,
    imageUrl: userData[0].photo,
    company: userData[0].company,
    firstName: userData[0].first_name,
    lastName: userData[0].last_name,
    email: userData[0].email,
  };
  return user;
};

//action to submit updates to user details
export const action = async ({ params, request }) => {
  const data = await request.formData();
  const token = getAuthToken();
  const requestBody = {
    user_id: params.uid,
    firstname: data.get("firstName"),
    lastname: data.get("lastName"),
    email_addr: data.get("email"),
    role: data.get("role"),
    company: data.get("company"),
    contact_number: data.get("contactNumber"),
  };
  const response = await axios.post(
    `${apiURL}/users/userupd`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  console.log(response);

  return redirect("../..");
};
