import React, { useRef, useEffect } from "react";
import * as echarts from "echarts";
//import "echarts/lib/component/datalabels"; // Import datalabels module

const HorizontalBarChart = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const { chartData, title } = props;

    // Clear the existing chart instance if it exists
    if (chartRef.current) {
      echarts.dispose(chartRef.current);
    }

    if (chartData && chartData.length > 0) {

      const myChart = echarts.init(chartRef.current);

      const option = {
        title: {
          top: 32,
          text: title,
          left: "center",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["Actual", "Budget"],
        },
        toolbox: {
          show: true,
          orient: "vertical",
          left: "right",
          top: "center",
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ["line", "bar", "stack"] },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        grid: {
          top: "20%",
          left: "3%",
          right: "7%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: chartData.map((item) => item.label),
          barMinHeight: [30, 20],
          axisLabel: {
            rotate: -45,
            interval: 0,
            formatter: (value) => {
              // Format series labels with commas
              return value.toLocaleString();
            },
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Actual",
            type: "bar",
            barGap: 0,
            label: {
              // ...labelOption,
              show: true, // Show data labels
              position: "top", // Adjust the position as needed
              formatter: (params) => {
                // Format series labels with commas
                return params.value.toLocaleString();
              },
            },
            emphasis: {
              focus: "series",
            },

            /*data: chartData.map((item) => item.value[0]),*/
            data: chartData.map((item) => (parseFloat(item.value[0]) / 1000000).toFixed(2)),
            itemStyle: {
              color: "#0070c0",
              fontSize: "14px",
            },
          },
          {
            name: "Budget",
            type: "bar",
            label: {
              show: true,
              position: "top",
              formatter: (params) => {
                // Format series labels with commas
                return params.value.toLocaleString();
              },
            },
            emphasis: {
              focus: "series",
            },
            /*data: chartData.map((item) => item.value[1]),*/
            data: chartData.map((item) => (parseFloat(item.value[1]) / 1000000).toFixed(2)),
            itemStyle: {
              color: "#ffc000",
              fontSize: "14px",
            },
          },
        ],
      };

      myChart.setOption(option);
    }
  }, [props]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "1420px",
        height: "450px",
      }}
    >
      {props.chartData && props.chartData.length === 0 && (
        <p
          style={{
            marginTop: "60px",
            fontSize: "16px",
            fontWeight: "400",
            textAlign: "center",
            marginLeft: "57px",
          }}
        >
          No data available
        </p>
      )}
    </div>
  );
};

export default HorizontalBarChart;