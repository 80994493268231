import React from "react";
import ReactDOM from "react-dom";
import classes from "./ApproveModal.module.css";
import warning from "../../assets/warning.svg";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const Overlay = (props) => {
  const confirmHandler = () => {
    //Calls the function in the parent component to confirm the action
    props.onConfirm({
      contract_number: props.contractNumber,
      submitted_by: props.submittedBy,
    });
  };

  return (
    <div className={classes.modal}>
      <div className={classes["modal-content"]}>
        <img src={warning} alt="warning-svg" />
        <h1>Confirm Contract Approval</h1>
        <p>Approve Contract: {props.contractNumber}</p>
        <div className={classes.actions}>
          <button className={classes["btn-confirm"]} onClick={confirmHandler}>
            Confirm
          </button>
          <button className={classes["btn-close"]} onClick={props.onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
const ApproveModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <Overlay
          onClose={props.onClose}
          onConfirm={props.onConfirm}
          submittedBy={props.submittedBy}
          contractNumber={props.contractNumber}
        />,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default ApproveModal;
