import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

const LineChart = (props) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chartDom = chartRef.current;
    const {chartData} = props;

    const myChart = echarts.init(chartDom);
    const option = {
      title: {
        top: 2,
        text: props.title,
        left: "center",
        textStyle: {
          fontWeight: 400,
        },
      },
      tooltip: {
        trigger: "axis",
      },

      legend: {
        data: chartData.map((item) => item.name),
        bottom: 0,
        icon: "roundRect",
        orient: "horizontal",
        itemHeight: 5,

        formatter: function (name) {
          return name.length > 15 ? name.slice(0, 15) + "..." : name;
        },
      },
      xAxis: {
        type: "category",
        data: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        axisTick: {
          show: true,
        },
      },
      yAxis: {
        type: "value",
        interval: 10,
      },
      series: chartData.map((item, index) => ({
        name:  chartData.map((item) => item.name),
        type: "line",
        data:  chartData.map((item) => item.value),
        label: {
          show: true,
          position: "middle",
          fontSize: 17,
        },
        itemStyle: {
          color: `${
            (index === 0 && "#4472c4") ||
            (index === 1 && "#ed7d31") ||
            (index === 2 && "#a5a5a5") ||
            (index === 3 && "#ffc000") ||
            (index === 4 && "#5b9bd5") ||
            (index === 5 && "#70ad47") ||
            (index === 6 && "#264478") ||
            (index === 7 && "#9e480e")
          }`,
        },
        lineStyle: {
          width: 5,
        },
      })),
    };

    option && myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [props]);

  return (
    <div
      ref={chartRef}
      style={{
        width: "100%",
        height: "16rem",
        marginTop: "16px"
      }}
    />
  );
};

export default LineChart;