import React from "react";
import classes from "./SubNavigation.module.css";

//Header shown for pages under /dashboard path
const DashboardNavigation = ({ path }) => {
  let navText;
  if (path.startsWith("/dashboard/rate")) {
    navText = "Rate Card";
  }
  if (path.startsWith("/dashboard/bva")) {
    navText = "BvA – Current Month Revenue";
  }
  if (path.startsWith("/dashboard/rps")) {
    navText = "Revenue - Per Station";
  }
  if (path.startsWith("/dashboard/frr")) {
    navText = "Fill Rate Report";
  }
  return (
    <header className={classes.header}>
      <h1>{navText}</h1>
    </header>
  );
};

export default DashboardNavigation;
