import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import classes from "./FeedbackModal.module.css";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};
const Overlay = (props) => {
  const [isShowMore, setIsShowMore] = useState(false);
  const [numLines, setNumLines] = useState();

  function toggleClickHandler() {
    setIsShowMore(!isShowMore);
  }

  const textRef = useRef(null);

  //useEffect to limit the amount of lines initially displayed
  useEffect(() => {
    const textNode = textRef.current;
    const lineHeight = parseInt(getComputedStyle(textNode).lineHeight, 10);
    const textHeight = textNode.clientHeight;
    setNumLines(Math.round(textHeight / lineHeight));
  }, [setNumLines]);

  let message = props.message;
  if (numLines > 11 && !isShowMore) {
    message = props.message.split("\n").slice(0, 10).join("\n");
  }
  return (
    <div className={`${classes["modal"]} ${isShowMore ? classes.more : ""}`}>
      <div className={classes["modal-content"]}>
        <h2>{"Contract #" + props.title}</h2>
        <hr />
        <p
          className={`${isShowMore ? classes.scroll : ""} ${classes.message}`}
          ref={textRef}
        >
          {message}
          {numLines > 11 && (
            <p className={classes.toggle} onClick={toggleClickHandler}>
              {isShowMore ? "Show Less" : "Show More"}
            </p>
          )}
        </p>

        <hr />
        <div>
          <button className={classes["close-button"]} onClick={props.onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const FeedbackModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <Overlay
          title={props.feedback.contractNumber}
          message={props.feedback.feedback}
          onClose={props.onClose}
        />,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default FeedbackModal;
