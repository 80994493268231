import { Navigate, useRouteError } from "react-router-dom";
import MainNavigation from "../components/Navigation/MainNavigation";
import { getTokenData } from "../utils/auth";
import { getAuthToken } from "../utils/auth";

//Basic error page to show unexepcted errors throughout tht system
const ErrorPage = () => {
  const error = useRouteError();
  let title = "An error occured";
  let message = "Something went wrong!";

  if (error.status === 404) {
    title = "Page not found";
    message = "Could not find resource or page!";
  }

  const token = getTokenData();
  const userRole = token.company + "_" + token.role;
  const auth = getAuthToken();

  if (!auth) {
    return <Navigate to="login" />;
  }
  return (
    <>
      <MainNavigation role={userRole} />
      <main style={{ marginTop: "4.8rem" }}>
        <h1>{title}</h1>
        <p>{message}</p>
      </main>
    </>
  );
};

export default ErrorPage;
