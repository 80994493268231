import React from "react";
import { Outlet } from "react-router-dom";
import UserNavigation from "../../components/Navigation/UserNavigation";

const UserRoot = () => {
  return (
    <>
      <UserNavigation />
      <Outlet />
    </>
  );
};

export default UserRoot;
