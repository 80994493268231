import alertSlice from "../store/alert-slice";

//Simplified dispatch into a reusable function
export const alert = (message, color, dispatch, duration = 4000) => {
  dispatch(
    alertSlice.actions.addAlert({
      message: message,
      duration: duration,
      id: Math.random(),
      color: color,
    })
  );
};
