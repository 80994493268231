import React, { useEffect, useState } from "react";
import VerticalBarChart from "../../components/Dashboard/VerticalBarChart";
import classes from "./styles/CurrentMonthRevenuePage.module.css";
import { useNavigate } from "react-router-dom";
import BVADownload from "./BVADatePicker";


const CurrentMonthRevenuePage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [bvaData, setBvaData] = useState({
    Revenue: [],
    "Fill Rate": []
  });
  const [selectedData, setSelectedData] =  useState([]);
  const [revenueData, setRevenueData] = useState([]);
  const [fillRateData, setFillRateData] = useState([]);
   
  const radioLabels = ["Revenue", "Fill Rate"];
  const [selectedRadio, setSelectedRadio] = useState(0);

  const [aspectRatio, setAspectRatio] = useState(null);
  const [reload, setReload] = useState(false);

  // fetchData from API
  async function fetchData(isRevenue) {
    const endpoint = isRevenue ? "Revenue" : "Fill Rate";
    try {
      const response = await fetch(`${apiURL}/analytics/rev_bva`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        setBvaData({ ...bvaData, [endpoint]: data[endpoint]});
        setSelectedData(isRevenue ? data.Revenue : data["Fill Rate"])
    } else {
        console.error("error fetching data");
      } 
    } catch (error) {
        console.error("Error fetching data:", error)
      }
    }
  

  useEffect(() => {
    fetchData(radioLabels[selectedRadio] === "Revenue");
  }, [selectedRadio])

  function radioInputChangeHandler(event) {
    setSelectedRadio(parseInt(event.target.value, 10));
  }


  //useEffect that checks if the user is resizing
  useEffect(() => {
    function handleResize() {
      const { innerWidth, innerHeight } = window;
      const newAspectRatio = innerWidth / innerHeight;
      setAspectRatio(newAspectRatio);
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //useEffect that reloads the chart once user is done resizing
  useEffect(() => {
    let identifier;

    if (aspectRatio !== null) {
      identifier = setTimeout(() => {
        setReload((r) => !r);
      }, 100);
    }

    return () => {
      clearTimeout(identifier);
    };
  }, [aspectRatio, setReload]);



  const navigate = useNavigate();

  function backClickHandler() {
    navigate("..");
  }

  function downloadClickHandler() {
    // Fetch both revenue and Fill Rate data
    const revenueData = bvaData.Revenue;
    const fillRateData = bvaData["Fill Rate"];
  
    // Combine data
    const combinedData = combineData(revenueData, fillRateData);
  
    // Convert combined data to CSV
    const csvData = convertDataToCSV(combinedData);
  
    // Create a Blob and create a link to trigger the download
    const blob = new Blob([csvData], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "currentmonth_data.csv";
    link.click();
  }
  
  function combineData(revenueData, fillRateData) {
    const combinedData = [];
  
    // Assuming the data structures are similar for both revenue and Fill Rate
    for (let i = 0; i < revenueData.length; i++) {
      combinedData.push({
        label: revenueData[i].label,
        revenueActual: revenueData[i].value[0],
        revenueBudget: revenueData[i].value[1],
        fillRateActual: fillRateData[i].value[0],
        fillRateBudget: fillRateData[i].value[1],
      });
    }
  
    return combinedData;
  }
  
  function convertDataToCSV(data) {
    // Example CSV format (customize based on your data structure)
    let csvString = "Label,Revenue_Actual,Revenue_Budget,FillRate_Actual,FillRate_Budget\n";
    data.forEach((item) => {
      csvString += `${item.label},${item.revenueActual},${item.revenueBudget},${item.fillRateActual},${item.fillRateBudget}\n`;
    });
  
    return csvString;
  }

  return (
    <main className={classes.main}>
      <div className={classes.navigation}>
        <div className={classes.radios}>
          <div className={classes.radio}>
            {radioLabels.map((label, index) => (
              <label className={classes["radio-button"]} key={index}>
                <input
                  type="radio"
                  name="radio"
                  defaultChecked={index === 0 ? true : false}
                  value={index}
                  onChange={radioInputChangeHandler}
                />
                {label}
              </label>
            ))}
          </div>
            {/*<p>Tick to display</p>*/}
        </div>
        <div className={classes.buttons}>
          <button onClick={backClickHandler}>Back to Main Menu</button>
          <BVADownload />
          {/* <button onClick={downloadClickHandler}>Download</button> */}
        </div>
      </div>
     <div className={classes.currmonthTable}>
     <VerticalBarChart key={reload} chartData={selectedData} />{/* HERE */}
      <div  className={classes.tableContainer}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th></th>
            <th>Actual (in Mil)</th>
            <th>Budget (in Mil)</th>
          </tr>
        </thead>
        <tbody>
          {selectedData.map((item, index) => (
            <tr key={index}>
              <td className={classes.label}>{item.label}</td>
              <td className={classes.actual}>{item.value[0]}</td>
              <td className={classes.budget}>{item.value[1]}</td>
              {/*<td className={classes.actual}>{parseFloat(item.value[0]).toFixed(2)}</td>
              <td className={classes.budget}>{parseFloat(item.value[1]).toFixed(2)}</td>*/}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      {/*<VerticalBarChart key={reload} chartData={selectedData} //I moved it above the table/>*/}
      </div>
    </main>
  );
  }


export default CurrentMonthRevenuePage;
