import React, { useEffect } from "react";
import {
  json,
  useActionData,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LoginForm from "../components/Login/LoginForm";
import CryptoJS from "crypto-js";
import axios from "axios";
import forge from "node-forge";
import { Buffer } from "buffer";
import { useDispatch } from "react-redux";
import { alert } from "../utils/alert";
const apiURL = process.env.REACT_APP_API_URL


const AuthenticationPage = () => {
  const data = useActionData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();


  //useEffect to check if a login was successful
  useEffect(() => {
    if (data && data.status === 200) {
      console.log(data);
      alert("Login Sucess!", "green", dispatch);
      if (data.data.countpass === 1) {
        alert(
          "Newly Created Accounts must change password",
          "yellow",
          dispatch,
          10000
        );
        //navigate to change password on first login for new user accounts
        navigate("/profile/password", { state: { from: location.pathname } });
      }
      //navigate to the system
      else navigate("/");
    }
  }, [data, navigate, dispatch, location]);

  return <LoginForm />;
};

export default AuthenticationPage;

//Login action to check if entered credentials is valid
export const action = async ({ request }) => {
  const data = await request.formData();

  const requestBody = {
    email: data.get("email"),
    password: data.get("password"),
  };

  const key = process.env.REACT_APP_KEY;
  const iv = process.env.REACT_APP_IV;

  const response = await axios
    .post(
      `${apiURL}/auth/getToken`,
      requestBody
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const errorObject = {
        status: error.response.status,
        statusText: error.response.statusText,
      };
      return errorObject;
    });
  if (response.status !== 200) {
    return json({
      message: "Invalid User Credentials",
      status: response.status,
    });
  }

  const resData = response.data;
  const dataBuffer = Buffer.from(resData.encryptedData, "hex");
  const ivBuffer = Buffer.from(iv, "hex");
  const cipher = forge.cipher.createDecipher("AES-CBC", key);
  cipher.start({ iv: forge.util.createBuffer(ivBuffer) });
  cipher.update(forge.util.createBuffer(dataBuffer));
  cipher.finish();
  const decryptedData = cipher.output.getBytes();
  const parts = decryptedData.split("___");

  const user = {
    role: parts[1].toUpperCase(),
    uid: parts[2],
    company: resData.company,
    email: data.get("email"),
    name: resData.firstname + " " + resData.lastname,
  };

  console.log(user);
  const userString = JSON.stringify(user);
  const encryptedUser = CryptoJS.AES.encrypt(userString, parts[0]).toString();

  sessionStorage.setItem("token", parts[0]);
  sessionStorage.setItem("user", encryptedUser);

  return { ...response, data: { ...response.data, email: data.get("email") } };
};
