import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./SubNavigation.module.css";
import backSVG from "../../assets/back.svg";

//Header shown under the path /contracts
const ContractNavigation = ({ path }) => {
  const normalizedPath = path.replace(/\/$/, ""); //Remove the last "/" of a path
  const url = window.location.href;
  const titleFilter = url;
  let naxText;
  let isViewContract = false;

  if (normalizedPath.startsWith("/contracts/new")) {
    naxText = "New Contracts";
  }
  if (normalizedPath.startsWith("/contracts/track")) {
    naxText = "Submissions & Assignments";
  }
  if (normalizedPath.startsWith("/contracts/submissions")) {
    naxText = "Contracts";//Manage Submissions
  }
  if (titleFilter === "http://localhost:3000/contracts/submissions?status=INVOICED") {
    naxText = "Invoiced Contracts";
  }
  if (titleFilter === "http://localhost:3000/contracts/submissions?status=COMPLETE") {
    naxText = "Invoices and ORs";
  }
  if (normalizedPath.startsWith("/contracts/submissions/")) {
    isViewContract = true;
  }
  if (normalizedPath.startsWith("/contracts/submissions/edit")) {
    isViewContract = true;
  }
  if (normalizedPath.startsWith("/contracts/track/edit")) {
    isViewContract = true;
  }
  if (normalizedPath.startsWith("/contracts/track/")) {
    isViewContract = true;
  }

  const navigate = useNavigate();
  return (
    <header className={classes.header}>
      {!isViewContract ? (
        <h1>{naxText}</h1>
      ) : (
        <div
          className={classes["edit-nav"]}
          onClick={() => navigate("/contracts")}
        >
          <div className={classes["edit-back"]}>
            <img src={backSVG} alt="back-btn" />
            <p>Back</p>
          </div>
          <div className={classes.line}></div>
          <h2>{path.includes("edit") ? "Edit Contract" : "View Contract"}</h2>
        </div>
      )}
    </header>
  );
};

export default ContractNavigation;
