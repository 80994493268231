import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useSubmit } from "react-router-dom";
import MainNavigation from "../../components/Navigation/MainNavigation";
import userSlice from "../../store/user-slice";
import { protectRoute, getTokenData } from "../../utils/auth";

const RootLayout = () => {
  const submit = useSubmit();
  const location = useLocation();
  const path = location.pathname;
  const dispatch = useDispatch();
  const navigate = useNavigate();


  //useEffect to protect route and to check if user has tokens on first load
  useEffect(() => {
    const token = getTokenData();
    if (!token) {
      console.log("NO USER TOKEN");
      submit(null, { action: "/logout", method: "post" });
    }
    const company_role = token.company + "_" + token.role;

    dispatch(
      userSlice.actions.setUser({
        role: company_role,
      })
    );
    
    protectRoute(company_role, path, navigate, submit);
  }, [path, submit, dispatch, navigate]);

  const userRole = useSelector((state) => state.user.role);
  return (
    <>
      {/* style={{
        position: "relative",
        marginTop: "4.8rem",
        // display: "flex",
        // height: "100%",
        // flexDirection: "row",
      }}
    > */}
      <MainNavigation role={userRole}/>
      <Outlet />
    </>
  );
};

export default RootLayout;
