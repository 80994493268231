import React, { Suspense } from "react";
import axios from "axios";
import { Await, defer, useLoaderData } from "react-router-dom";
import { getAuthToken } from "../../utils/auth";
import Loading from "../../components/UI/Loading";
import RateCard from "../../components/RateCard/RateCard";
const apiURL = process.env.REACT_APP_API_URL


const RateCardPage = () => {
  const { rateCardData } = useLoaderData();
  return (
    <main>
      <Suspense fallback={<Loading />}>
        <Await resolve={rateCardData}>
          {(loadedRateCardData) => <RateCard data={loadedRateCardData} />}
        </Await>
      </Suspense>
    </main>
  );
};

export default RateCardPage;

//defered loader to navigate to ratecard while waiting for data
export const loader = () => {
  return defer({ rateCardData: loadRateCard() });
};


//API function to load all rate cards
export async function loadRateCard() {
  const token = getAuthToken();
  const response = await axios.get(
    `${apiURL}/ratecard`,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  const obj = response.data;

  const trainArr = [];
  const stationArr = [];
  const othersArr = [];


  //Sort rate card to the desired requirements
  for (const year in obj) {
    obj[year].forEach((item, index) => {
      if (item.category === "Train") {
        trainArr.push(item);
      } else if (item.category === "Station") {
        stationArr.push(item);
      } else {
        othersArr.push(item);
      }
    });
    const sortedArr = [...trainArr, ...stationArr, ...othersArr];

    obj[year] = sortedArr;

    trainArr.length = 0;
    stationArr.length = 0;
    othersArr.length = 0;
  }

  return obj;
}
