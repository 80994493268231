import React, { useState } from "react";
import classes from "./RateCardTable.module.css";
import Papa from "papaparse";
import { alert } from "../../utils/alert";
import { useDispatch } from "react-redux";
import { toPHPValue } from "../../utils/converters";

const RateCardTable = (props) => {
  const [csvData, setCsvData] = useState(null);
  const [csvFileName, setCsvFileName] = useState();
  const dispatch = useDispatch();
  const columns = [
    { key: "category", header: "Category" },
    { key: "description", header: "Description" },
    { key: "monthly", header: "Monthly" },
    { key: "quarterly", header: "Quarterly" },
    { key: "semiannual", header: "Semi Annual" },
    { key: "annual", header: "Annual" },
  ];

  const fileChangeHandler = (event) => {
    const file = event.target.files[0];
    if (file) {
      setCsvFileName(file.name);
    } else {
      setCsvFileName(undefined);
    }
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const desiredKeys = [
          "annual",
          "category",
          "description",
          "monthly",
          "quarterly",
          "semiannual",
        ];

        //Checks if the csv file contains the correct headers
        if (
          Object.keys(results.data[0]).includes("description") &&
          Object.keys(results.data[0]).includes("monthly") &&
          Object.keys(results.data[0]).includes("quarterly") &&
          Object.keys(results.data[0]).includes("semiannual") &&
          Object.keys(results.data[0]).includes("annual") &&
          Object.keys(results.data[0]).includes("category")
        ) {
          //Cleans the csv file to remove empty spaces and excess characters
          const cleanedData = results.data.map((obj) => {
            const cleanedObj = {};
            desiredKeys.forEach((key) => {
              if (obj[key] !== "") {
                cleanedObj[key] = obj[key];
              }
            });
            return cleanedObj;
          });
          //Filters out entries that have empty fields
          const cleanerData = cleanedData.filter((obj) => {
            if (!Object.values(obj).some((val) => val.trim() !== "")) {
              console.log("Filtered out object:", obj);
              return false;
            }
            return true;
          });
          setCsvData(cleanerData);
        } else {
          setCsvFileName(undefined);
          setCsvData(undefined);
          alert("Invalid File", "red", dispatch);
        }
      },
    });
  };

  const uploadClickHandler = () => {
    //Calls the upload function in the parent component
    props.uploadFile(csvData);
    setCsvData(null);
    setCsvFileName(undefined);
  };

  const customStyling = (row, key) => {
    if (key !== "category" && key !== "description") {
      return toPHPValue(row[key]).slice(3);
    }
    return row[key];
  };
  return (
    <>
      {props.data && props.data.length !== 0 ? (
        <table className={classes.table}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>{column.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.data.map((row, index) => (
              <tr
                key={index}
                className={classes[row["category"].toLowerCase()]}
              >
                {columns.map((column) => (
                  <td
                    className={classes[column.key]}
                    key={`${column.key}-${index}`}
                    style={{
                      textAlign:
                      column.key === "monthly" || column.key === "quarterly" || column.key === "semiannual" || column.key === "annual"
                          ? "right"
                          : column.key === "category"
                          ? "center"
                          : "left",
                    }}
                  >
                    {customStyling(row, column.key)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={classes.block}>
          <div className={classes.input}>
            <label htmlFor="ratecard-file">Choose File</label>
            <p>{csvFileName ? csvFileName : "No File Chosen"}</p>
            <input
              id="ratecard-file"
              type="file"
              accept=".csv"
              onChange={fileChangeHandler}
            />
          </div>
          <button disabled={!csvData} onClick={uploadClickHandler}>
            Upload
          </button>
        </div>
      )}
    </>
  );
};

export default RateCardTable;