import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "../utils/auth";
import classes from "./UserTable.module.css";
import edit from "../assets/actions/edit.svg";
import remove from "../assets/actions/remove.svg";
import warning from "../assets/warning.svg";
const apiURL = process.env.REACT_APP_API_URL


const columns = [
  { key: "fullname", header: "Name" },
  { key: "email_addr", header: "Email" },
  { key: "role", header: "Role" },
  { key: "company", header: "Company" },
  { key: "actions", header: "Actions" },
];

const UserTable = (props) => {
  const [tableData, setTableData] = useState(props.data);
  const [searchTerm, setSearchTerm] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [isOpen, setIsOpen] = useState();
  const navigate = useNavigate();

  const searchHandler = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    //Filter data according to search term
    const filteredData = props.data.filter((user) =>
      Object.values(user).some((value) =>
        value.toLowerCase().includes(term.toLowerCase())
      )
    );
    setTableData(filteredData);
  };

  const roleFilterHandler = (event) => {
    const role = event.target.value;
    setRoleFilter(role);
    const filteredData = props.data.filter((user) =>
      user.role.toLowerCase().includes(role.toLowerCase())
    );
    setTableData(filteredData);
  };

  const editUserHandler = async (row) => {
    navigate(`edit/${row["user_id"]}`);
  };

  //API function to to remove a specific user
  const removeUserHandler = async (row) => {
    const requestBody = { user_id: row["user_id"] };
    const token = getAuthToken();

    const response = await axios.post(
      `${apiURL}/users/userdel`,
      requestBody,
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (response.status === 200) {
      //call the update function to update the data from the parent
      props.update();
      navigate("/users");
    }
  };

  const columnStyling = (row, key) => {
    if (key === "actions") {
      return (
        <div className={classes["action-div"]}>
          <span
            className={classes["action-btn"]}
            onClick={() => editUserHandler(row)}
          >
            <img src={edit} alt="edit-btn" />
            <p>Edit</p>
          </span>
          <span
            className={classes["action-btn"]}
            onClick={(event) => {
              event.stopPropagation();
              setIsOpen(row);
            }}
          >
            <img src={remove} alt="remove-btn" />
            <p>Remove</p>
          </span>
        </div>
      );
    }
    return row[key];
  };

  const modalRef = useRef(null);


  //useEffect to handle clicking outside the confirmation modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        modalRef.current &&
        !modalRef.current.contains(event.target)
      ) {
        setIsOpen(undefined);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={classes.overlay}>
          <div className={classes.modal} ref={modalRef}>
            <div className={classes["modal-content"]}>
              <img src={warning} alt="warning-svg" />
              <h1>Confirm Remove User</h1>
              <p>Remove User: {isOpen["fullname"]}</p>
              <div className={classes.actions}>
                <button
                  className={classes["btn-confirm"]}
                  onClick={() => removeUserHandler(isOpen)}
                >
                  Confirm
                </button>
                <button
                  className={classes["btn-cancel"]}
                  onClick={() => setIsOpen(undefined)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.actions}>
        <input
          className={classes["search-bar"]}
          placeholder="Search User"
          onChange={searchHandler}
          value={searchTerm}
        />
        <select
          className={classes.select}
          value={roleFilter}
          onChange={roleFilterHandler}
        >
          <option value="">Filter by Role</option>
          <option value="MANAGER">Manager</option>
          <option value="ENCODER">Encoder</option>
        </select>
        <button
          className={classes.add}
          onClick={() => {
            navigate("add");
          }}
        >
          Add User
        </button>
      </div>

      <div className={classes["table-bg"]}>
        <table className={classes.table}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th key={column.key}>{column.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                {columns.map((column) => (
                  <td key={column.key}>{columnStyling(row, column.key)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UserTable;
