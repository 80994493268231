import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import classes from "./NotificationsMenu.module.css";
import useClickOutside from "../../hooks/useClickOutside";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getAuthToken, getTokenData } from "../../utils/auth";
import Loading from "./Loading";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkHasUnread,
  markAllNotificationsAsRead,
  markNotificationAsRead,
} from "../../utils/api";
import { formatDateTime } from "../../utils/converters";
import { notificationActions } from "../../store/notification-slice";
const { uid } = getTokenData();
const apiURL = process.env.REACT_APP_API_URL


const Overlay = React.forwardRef((props, ref) => {
  const [filter, setFilter] = useState("all");
  let userRole = useSelector((state) => state.user.role);
  if (userRole === "UTASI_ENCODER") {
    userRole = "ENCODER";
  }
  const { notifications } = props;
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  let filteredNotifications;

  //Checks if the filter for notification is for all or for unread
  if (notifications) {
    filteredNotifications = notifications.filter((notification) => {
      if (filter === "unread") {
        return notification.isRead === 0;
      } else {
        return true;
      }
    });
  }

  //Functions to mark all notification status to read
  async function readAllClickHandler() {
    markAllNotificationsAsRead(uid, userRole);
    dispatch(notificationActions.setHasUnread(false));
    props.onClose();
  }

  function notificationClickHandler(notification) {
    //Checks where the user will be navigated to depending on their role
    if (notification.contract_number)
      if (params.contractNumber !== notification.contract_number)
        if (userRole === "ENCODER")
          navigate(
            `contracts/track/edit/${encodeURIComponent(
              notification.contract_number.replace("/", "%2F")
            )}`
          );
        else
          navigate(
            `contracts/submissions/${
              notification.notification.includes(" approved") ? "edit/" : ""
            }${encodeURIComponent(
              notification.contract_number.replace("/", "%2F")
            )}`
          );
    //Marks the clicked notification as read
    markNotificationAsRead(notification.notif_id);
    const requestBody = {
      user_id: uid,
      user_role: userRole,
    };

    //Rechecks if there are anymore unread notifications
    checkHasUnread(requestBody, dispatch, notification.notif_id);
    props.onClose();
  }

  return (
    <div ref={ref} className={classes.menu}>
      <div className={classes.header}>
        <h3>Notifications</h3>
        <p onClick={readAllClickHandler}>Mark all as read</p>
      </div>
      <div className={classes.tab}>
        <p
          className={`${filter === "all" && classes.active}`}
          onClick={() => {
            setFilter("all");
          }}
        >
          All
        </p>
        <p
          className={`${filter === "unread" && classes.active}`}
          onClick={() => {
            setFilter("unread");
          }}
        >
          Unread
        </p>
      </div>

      {notifications ? (
        <ul>
          {filteredNotifications.length === 0 ? (
            <h6>No notifications at this time</h6>
          ) : (
            filteredNotifications.map((notification, index) => (
              <li
                key={index}
                className={classes.notification}
                onClick={() => notificationClickHandler(notification)}
              >
                <div className={classes.content}>
                  <p className={classes.message}>{notification.notification}</p>
                  <p className={classes.time}>
                    {formatDateTime(notification.created_at)}
                  </p>
                </div>
                {notification.isRead === 0 && (
                  <div className={classes.status}>
                    <span className={classes.circle}></span>
                  </div>
                )}
              </li>
            ))
          )}
        </ul>
      ) : (
        <Loading />
      )}
    </div>
  );
});

const NotificationsMenu = (props) => {
  const [notificationsData, setNotificationsData] = useState();
  const menuRef = useRef(null);

  const token = getAuthToken();

  //Uses custom hook if a click happens outside the reference ref
  useClickOutside(menuRef, () => {
    props.onClose();
  });

  let userRole = useSelector((state) => state.user.role);
  if (userRole === "UTASI_ENCODER") {
    userRole = "ENCODER";
  }

  useEffect(() => {
    //Function to get all notifications of a user
    async function getNotifications() {
      const requestBody = {
        user_id: uid,
        user_role: userRole,
      };
      const response = await axios.post(
        "https://lrmcapi.retailgate.tech:20306/notif",
        requestBody,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      //Sort notifications according to date
      const sortedNotifications = [...response.data].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      setNotificationsData(sortedNotifications);
    }

    getNotifications();
  }, [userRole, token]);

  return (
    <>
      {ReactDOM.createPortal(
        <Overlay
          onClose={props.onClose}
          ref={menuRef}
          notifications={notificationsData}
        />,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default NotificationsMenu;
