import classes from "./ContractForm.module.css";
import statusClasses from "./SubmissionTable.module.css";
import React, { useState, useEffect } from "react";
import { ASSET_HEADERS, CONTRACT_HEADERS } from "../utils/table-headers";
import { useSelector } from "react-redux";
import FeedbackModal from "./UI/FeedbackModal";
import { titleCase, toPHPValue } from "../utils/converters";
import feedbackSVG from "../assets/actions/feedback.svg";
import ImageModal from "./UI/ImageModal";
import { default as ReactSelect } from "react-select";

const ContractItem = ({ item, invoiceFile, orFile }) => {
  const userRole = useSelector((state) => state.user.role);
  const [feedbackModal, setFeedbackModal] = useState();
  const [imageModal, setImageModal] = useState();
  // const [selectedStations, setSelectedStations] = useState([]);
  const [assetStations, setAssetStations] = useState([]);

  const [newComment, setNewComment] = useState("");
  const [remarks, setRemarks] = useState(() => {
    const storedRemarks =
      JSON.parse(localStorage.getItem("submittedRemarks")) || [];
    return storedRemarks;
  });

  const addComment = () => {
    if (newComment.trim() !== "") {
      const updatedRemarks = [...remarks, newComment];
      setRemarks(updatedRemarks);
      setNewComment("");

      localStorage.setItem("submittedRemarks", JSON.stringify(updatedRemarks));
    }
  };

  const stationOptions = [
    { value: "station1", label: "Baclaran" },
    { value: "station2", label: "EDSA" },
    { value: "station3", label: "Libertad" },
    { value: "station4", label: "Gil Puyat" },
    { value: "station5", label: "Vito Cruz" },
    { value: "station6", label: "Quirino" },
    { value: "station7", label: "Pedro Gil" },
    { value: "station8", label: "United Nations" },
    { value: "station9", label: "Central Terminal" },
    { value: "station10", label: "Carriedo" },
    { value: "station11", label: "Doroteo Jose" },
    { value: "station12", label: "Bambang" },
    { value: "station13", label: "Tayuman" },
    { value: "station14", label: "Blumentritt" },
    { value: "station15", label: "Abad Santos" },
    { value: "station16", label: "R.Papa" },
    { value: "station17", label: "5th Avenue" },
    { value: "station18", label: "Monumento" },
    { value: "station19", label: "Balintawak" },
    { value: "station20", label: "Fernando Poe Jr." }, //roose
    { value: "station21", label: "MIA" },
    { value: "station22", label: "Asia World" },
    { value: "station23", label: "Ninoy Aquino" },
    { value: "station24", label: "Dr. Santos" },
    { value: "station25", label: "Redemptorist" },
  ];

  // Dropdown selection for asset breakdown
  const handleDropdownAsset = (assetOption) => {
    console.log("DROPPDOWNNASSET", assetOption);
    const updatedAssetStations = assetOption;
    setAssetStations(updatedAssetStations);
  };

  useEffect(() => {
    if (assetStations.length > 0) {
      localStorage.setItem("assetStations", JSON.stringify(assetStations));
    }
  }, [assetStations]);

  useEffect(() => {
    const storedAssetStations = localStorage.getItem("assetStations");
    if (storedAssetStations) {
      setAssetStations(JSON.parse(storedAssetStations));
    }
  }, []);

  //Updates the table headers according to the role

  let UPDATED_ASSET_HEADERS = ASSET_HEADERS;
  // if (userRole === "UTASI_MANAGER" || userRole === "LRMC_MANAGER") {
  if (
    userRole === "UTASI_ENCODER" ||
    userRole === "LRMC_MANAGER" ||
    userRole === "UTASI_MANAGER"
  ) {
    UPDATED_ASSET_HEADERS = [
      ...ASSET_HEADERS,
      { key: "asset_file", header: "Invoice File" },
      { key: "or_file", header: "OR File" },
    ];
  }

  const assetGroups = item.assets.reduce((groups, asset) => {
    const key = asset["asset_type"];
    groups[key] = groups[key] || { ids: [], quantity: 0 };
    groups[key].ids.push(asset["asset_id"]);
    groups[key].quantity += parseInt(asset["asset_quantity"]);
    return groups;
  }, {});
  const assetRows = Object.entries(assetGroups).map(([type, group]) => (
    <tr key={type}>
      <td>{group.ids.join(", ")}</td>
      <td>{type}</td>
      <td>{group.quantity}</td>
    </tr>
  ));

  // Custom Style for disabled dropdown
  const customStyles = {
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "black" : "#0000",
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "black" : "#0000",
      backgroundColor: "none",
      borderBottom: "solid 1px lightgray",
      marginTop: "4px",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "transparent",
    }),
    multiValueGeneric: (provided) => ({
      ...provided,
      color: "black",
      fontSize: "87%",
    }),
  };

  const customStyling = (data, key, index) => {
    if (key === "asset_invoice_numbers") {
      return item.assets.map((asset) => asset.asset_invoice_number).join(", ");
    }

    function toPHPValue(data, key) {
      if (key === "total_amount" || key === "asset_amount") {
        // Check if data is a number
        if (typeof data === "number") {
          return data.toLocaleString("en-US", {
            style: "currency",
            currency: "Php",
          });
        }
      }
      return data;
    }

    //ASSET STATIONS FUNCTION
    if (key === "asset_stations") {
      return item.assets.map((asset) => asset.asset_station).join(", ");
    }

    if (key === "total_amount" || key === "asset_amount") {
      return toPHPValue(data, key);
    }

    if (key === "asset_image") {
      function viewImageClickHandler() {
        setImageModal(data);
      }
      return (
        <img
          onClick={viewImageClickHandler}
          src={data}
          alt={`Asset #${index}`}
          className={classes["asset-image"]}
        />
      );
    }

    if (key === "asset_file") {
      const openAssetFileClickHandler = () => {
        window.open(data, index);
      };
      return (
        <>
          {data && (
            <button
              className={classes["view-file-btn"]}
              onClick={openAssetFileClickHandler}
            >
              View File
            </button>
          )}
        </>
      );
    }
    if (key === "or_file") {
      const openOrFileClickHandler = () => {
        window.open(data, index);
      };
      return (
        <>
          {data && (
            <button
              className={classes["view-file-btn"]}
              onClick={openOrFileClickHandler}
            >
              View File
            </button>
          )}
        </>
      );
    }
    return data;
  };

  const viewFileClickHandler = (event) => {
    event.preventDefault();

    const openedWindow = window.open(item.contract_file, item.contract_number);

    if (openedWindow) {
      openedWindow.focus();
    }
  };

  const closeModalHandler = () => {
    setFeedbackModal(null);
    setImageModal(null);
  };

  const feedbackClickHandler = (item) => {
    setFeedbackModal({
      contractNumber: item.contract_number,
      feedback: item.feedback,
    });
  };
  return (
    <>
      {feedbackModal && (
        <FeedbackModal feedback={feedbackModal} onClose={closeModalHandler} />
      )}
      {imageModal && (
        <ImageModal image={imageModal} onClose={closeModalHandler} />
      )}
      <div className={classes["status-feedback"]}>
        <p>Status</p>
        {userRole === "UTASI_ENCODER" ? (
          <>
            {item.status === "INVOICED" ? (
              <div className={`${statusClasses.done} ${statusClasses.status}`}>
                Invoiced
              </div>
            ) : ![
                "PENDING",
                "RETURNED",
                "DISAPPROVED",
                "FOR INVOICING",
              ].includes(item.status) ? (
              <div className={`${statusClasses.done} ${statusClasses.status}`}>
                Done
              </div>
            ) : (
              <div
                className={`${
                  statusClasses[item.status.toLowerCase().replace(" ", "-")]
                } ${statusClasses.status}`}
              >
                {titleCase(item.status)}
              </div>
            )}
          </>
        ) : (
          <div
            className={`${
              statusClasses[item.status.toLowerCase().replace(" ", "-")]
            } ${statusClasses.status}`}
          >
            {titleCase(item.status)}
          </div>
        )}

        <p>Feedback</p>
        <img
          src={feedbackSVG}
          alt="feedback-btn"
          onClick={() => feedbackClickHandler(item)}
        />
      </div>

      <div className={classes.contract}>
        <div className={classes["contract-details"]}>
          <div className={classes.group}>
            <table>
              {[0, 1].map((number) => (
                <React.Fragment key={number}>
                  <thead>
                    <tr>
                      {CONTRACT_HEADERS[number].map((header) => (
                        <th key={header.key}>{header.header}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {/* DROPDOWNS ITEM */}
                      {CONTRACT_HEADERS[number].map((header) => (
                        <td key={header.header}>
                          {customStyling(item[header.key], header.key)}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </React.Fragment>
              ))}
            </table>
            <button className={classes.btn} onClick={viewFileClickHandler}>
              View Contract File
            </button>

            {/* Remarks Section */}
            <div className={classes["remarks-section"]}>
              <label htmlFor="remarks">Remarks:</label>
              <textarea
                id="remarks"
                value={newComment}
                onChange={(event) => setNewComment(event.target.value)}
                placeholder="Add your remarks here..."
              />
              <button className={classes["btn"]} onClick={addComment}>
                Add Comment
              </button>
            </div>

            {remarks.length > 0 && (
              <div className={classes["remarks-text"]}>
                <label>Submitted Remarks:</label>
                <ul>
                  {remarks.map((comment, index) => (
                    <li key={index}>{comment}</li>
                  ))}
                </ul>
              </div>
            )}

            {/* Display Invoice and OR files if available */}
            {userRole === "UTASI_ENCODER" && invoiceFile && orFile && (
              <div>
                <p>Invoice File:</p>
                <button
                  className={classes["view-file-btn"]}
                  onClick={() => window.open(invoiceFile, item.contract_number)}
                >
                  View Invoice File
                </button>
                <p>OR File:</p>
                <button
                  className={classes["view-file-btn"]}
                  onClick={() => window.open(orFile, item.contract_number)}
                >
                  View OR File
                </button>
              </div>
            )}
          </div>
          <div className={classes.group}>
            <table className={classes["contract-details-assets"]}>
              <thead>
                <tr>
                  {CONTRACT_HEADERS[2].map((header) => (
                    <th key={header.key}>{header.header}</th>
                  ))}
                </tr>
              </thead>
              <tbody>{assetRows}</tbody>
            </table>
          </div>
        </div>
        <div className={classes["contract-assets"]}>
          <h2>Asset Breakdown</h2>
          <table>
            <thead>
              <tr>
                {UPDATED_ASSET_HEADERS.map((header) => (
                  <th key={header.key}>{header.header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {item.assets.map((asset, index) => (
                <tr key={index}>
                  {UPDATED_ASSET_HEADERS.map((header) => (
                    <td key={header.key}>
                      {customStyling(asset[header.key], header.key, index)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default ContractItem;
