import { Form, Link, useActionData, useNavigation } from "react-router-dom";
import classes from "./LoginForm.module.css";
import logo from "../../assets/logo.webp";

const LoginForm = () => {
  const data = useActionData();
  const navigation = useNavigation();
  const isSubmitting = navigation.state !== "idle";

  return (
    <div className={classes.background}>
      <div className={classes.login}>
        <div className={classes.logo}>
          <img src={logo} alt="logo" />
        </div>
        <Form method="post" className={classes.form}>
          <h3>Login</h3>
          {data && data.message && (
            <p className={classes.alert}>{data.message}</p> //Error text to display invalid login attempts
          )}
          <label htmlFor="email">Email</label>
          <input
            className={classes.email}
            id="email"
            type="email"
            name="email"
            required
          />
          <label htmlFor="password">Password</label>
          <input id="password" type="password" name="password" required />
          <button disabled={isSubmitting} className={classes["btn-login"]}>
            {isSubmitting ? "Processing..." : "Login"}
          </button>
          <Link to="/password">Forgot Password?</Link>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;
