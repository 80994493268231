import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Papa from 'papaparse';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { getTokenData } from "../../utils/auth";
import { format } from 'date-fns';

const RevenuePerStationDownload = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const apiURL = process.env.REACT_APP_API_URL;
  const { name, company } = getTokenData();

  const fetchData = async () => {
    const start = startDate?.toISOString();
    const end = endDate?.toISOString();

    try {
      const response = await fetch(`${apiURL}/analytics/exportrevperstation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start_date: start,
          end_date: end,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        // Use the data for rendering or further processing
        console.log(data);

        // Download CSV
        downloadCSV(data);
      } else {
        console.error('Error fetching data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const downloadCSV = (data) => {
    try {
      // Transform data to add headers and separate values
      const transformedData = data.data.map(item => ({
        Station: item.label,
        Actual: item.value[0],
        Budget: item.value[1],
        Percentage: item.value[2],
      }));

      //Logs Download
      fetch(`${apiURL}/analytics/savedlhistory`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "content": `Revenue - Per Station downloaded by ${name}`,
            "company": `${company}`
        })
    })
    
       .then(response => {
        if (!response.ok) {
            throw new Error('Failed to log download');
          }
          console.log('Download logged successfully');
        })
        .catch(error => {
          console.error('Error logging download:', error);
        });
      //Logs Downloads end

      // Create CSV
      const csvData = Papa.unparse(transformedData, {
        header: true,
        quotes: true,
      });
      //Logs Download end
  
      // Create and trigger download
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
  
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'revenue_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error parsing data for CSV:', error);
    }
  };
  
  

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              margin="dense" 
              helperText=""
              sx={{
                "& .MuiOutlinedInput-root.MuiInputBase-colorPrimary": {
                  marginTop: "16px", 
                  height: "75px",
                  border: "2px solid red !important",
                  padding: "10px"
                },
              }}
            />
          )}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              margin="dense" 
              helperText=""
              sx={{
                ".MuiInputBase-root .MuiFormControl-root.MuiTextField-root.MuiFormControl-root-MuiTextField-root": {
                  margin: "16px", 
                  padding: "16px",
                  height: "75px",
                },
              }}
            />
          )}
        />
      </LocalizationProvider>

      <Button variant="contained" onClick={fetchData} style={{
        backgroundColor: "#4472c4",
        color: "white",
        border: "none",
        borderRadius: "5px",
        padding: "4px 26px",
        paddingLeft: "10px",
        paddingRight: "10px",
        fontWeight: "400",
        marginRight: "16px",
        fontSize: "14px",
        marginTop: "16px",
        textTransform: "none",
        width: "100%"
      }}>
        Download
      </Button>
    </div>
  );
};

export default RevenuePerStationDownload;
