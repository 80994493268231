import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import ContractNavigation from "../../components/Navigation/ContractNavigation";

const ContractRoot = () => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <>
      <ContractNavigation path={path} />
      <Outlet />
    </>
  );
};

export default ContractRoot;
