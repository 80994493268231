import axios from "axios";
import React, { Suspense, useEffect } from "react";
import {
  Await,
  useActionData,
  useNavigate,
  useRouteLoaderData,
} from "react-router-dom";
import UserForm from "../components/User/UserForm";
import { getAuthToken, getTokenData } from "../utils/auth";
import Loading from "../components/UI/Loading";
import { useDispatch } from "react-redux";
import { alert } from "../utils/alert";
import classes from "./Background.module.css";
const apiURL = process.env.REACT_APP_API_URL


const EditProfilePage = () => {
  const { profileData } = useRouteLoaderData("profile");
  const data = useActionData();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //useEffect to check successful profile edit
  useEffect(() => {
    if (data && data.status === 200) {
      navigate("/profile");
      alert("Edited profile Successfully!", "green", dispatch);
    } else if (data && data.status === 200) {
      navigate("/profile");
      alert("Something went wrong!", "red", dispatch);
    }
  }, [data, navigate, dispatch]);
  return (
    <main className={classes.main}>
      <Suspense fallback={<Loading />}>
        <Await resolve={profileData}>
          {(loadedProfileData) => (
            <UserForm method="patch" user={loadedProfileData} />
          )}
        </Await>
      </Suspense>
    </main>
  );
};

export default EditProfilePage;

//Edit profile action that updates the user profile details on the database
export const action = async ({ request }) => {
  const data = await request.formData();
  console.log(data);
  const { uid } = getTokenData();
  const token = getAuthToken();
  const requestBody = {
    user_id: uid,
    firstname: data.get("firstName"),
    lastname: data.get("lastName"),
    email_addr: data.get("email"),
    role: data.get("role"),
    company: data.get("company"),
    contact_number: data.get("contactNumber"),
  };

  const response = await axios.post(
    `${apiURL}/users/userupd`,
    requestBody,
    { headers: { Authorization: `Bearer ${token}` } }
  );

  return response;
};
