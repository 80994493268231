//Convert file to base64
export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result;
      resolve(result);
    };
    reader.onerror = (error) => reject(error);
  });
};

//Convert date and time to  MM-DD-YYYY HH:MM AM/PM
export const formatDateTime = (d) => {
  const options = {
    timeZone: "UTC",
    month: "2-digit",
    day: "2-digit",
    year: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const date = new Date(d);
  const formattedDateTime = date
    .toLocaleString("en-US", options)
    .replace("/", "-")
    .replace("/", "-")
    .replace(",", "");
  return formattedDateTime;
};

//Convert string to title case
export const titleCase = (str) => {
  return str
    .toUpperCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
};

//Convert numerical value to return with PHP, comma, and decimals
export const toPHPValue = (number) => {
  return `PHP ${number.toLocaleString(undefined, {
    minimumFractionDigits: 2,
  })}`;
};
