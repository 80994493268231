import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Form,
  useActionData,
  useNavigate,
  useNavigation,
} from "react-router-dom";
import ChangePasswordForm from "../components/User/ChangePasswordForm";
import classes from "../components/Login/LoginForm.module.css";
import logo from "../assets/logo.webp";

const ForgotPasswordPage = () => {
  const data = useActionData();
  const navigate = useNavigate();
  const [emailIsValid, setEmailisValid] = useState(false);
  const navigation = useNavigation();
  const isSubmitting = navigation.state !== "idle";

  useEffect(() => {
    if (data) {
      setEmailisValid(data.success);
    }
  }, [data, navigate]);

  return (
    <div className={classes.background}>
      <div className={classes.login}>
        <div className={classes.logo}>
          <img src={logo} alt="logo" />
        </div>
        {!emailIsValid ? (
          <Form method="post" className={classes.form}>
            <h3>Forgot Password</h3>
            <label htmlFor="email">Enter Email Address</label>
            <input id="email" name="email" type="email" required />{" "}
            {!emailIsValid && data && (
              <p className={classes.alert}>Email does not exist.</p>
            )}
            <button disabled={isSubmitting}>Verify Email</button>
          </Form>
        ) : (
          <div className={classes.form}>
            <ChangePasswordForm
              email={data.email}
              maskedEmail={data.maskedEmail}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordPage;

//Forgot password action that updates the user's passwordƒ
export const action = async ({ request }) => {
  const data = await request.formData();
  const apiURL = process.env.REACT_APP_API_URL


  const requestBody = { email_addr: data.get("email") };
  const response = await axios
    .post(
      `${apiURL}/auth/reqchangepass`,
      requestBody
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const errorObject = {
        status: 400,
        success: false,
      };
      return errorObject;
    });

  if (response.status === 200) {
    const maskedEmail = data
      .get("email")
      .replace(/^(.).*([^\s])@/, "$1****$2@");

    return {
      success: response.data.success,
      email: data.get("email"),
      maskedEmail: maskedEmail,
    };
  } else {
  }
  return response;
};
