//HEADERS USED FOR CONTRACT DETAILS
export const CONTRACT_HEADERS = [
  [
    { key: "contract_number", header: "Contract Number" },
    { key: "asset_invoice_numbers", header: "Invoice Number/s" },
    { key: "asset_stations", header: "Station/s"},
  ],
  [
    { key: "start_date", header: "Starting Date" },
    { key: "end_date", header: "Ending Date" },
    { key: "total_amount", header: "Total Amount (VAT)" },
  ],
  [
    { key: "asset_id", header: "Asset ID" },
    { key: "asset_type", header: "Asset Type" },
    { key: "asset_quantity", header: "Asset Quantity" },
  ],
];

//HEADERS USED FOR ASSET BREAKDOWN
export const ASSET_HEADERS = [
  { key: "asset_date_created", header: "Date Created" },
  { key: "asset_live_date", header: "Live Date" },
  { key: "asset_end_date", header: "End Date"},
  { key: "asset_type", header: "Asset Type" },
  { key: "asset_id", header: "Asset ID" },
  { key: "asset_quantity", header: "Asset Quantity" },
  { key: "asset_amount", header: "Amount (VAT)" },
  { key: "asset_station", header: "Station",  dropdown: true },
  { key: "asset_image", header: "Image" },

];

//HEADERS USED FOR SUBMISSIONS TABLE
export const SUBMISSION_HEADERS = [
  { key: "contract_number", header: "Contract #" },
  { key: "client_name", header: "Client Name" },
  { key: "date_submitted", header: "Date Submitted" },
  { key: "contract_start", header: "Start Date" },
  { key: "contract_end", header: "Ending Date" },
  { key: "total_amount", header: "Total Amount" },
  { key: "status", header: "Status" },
  { key: "actions", header: "Actions" },
];

// Updated DASHBOARD_HEADERS
export const DASHBOARD_HEADERS = [
  { key: "", header: "" },
  { key: "actual", header: "Actual" },
  { key: "budget", header: "Budget" },
  { key: "per1", header: <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> },
  //{ key: "vlyear", header: "v.s. Last Year" },
  { key: "vlyear", header: <span>v.s.<br />Last<br />&nbsp;&nbsp;Year&nbsp;&nbsp;</span> },
  { key: "per2", header: <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> },
  //{ key: "vpmon", header: "v.s. Previous Month" },
  { key: "vpmon", header: <span>v.s.<br />Previous<br />Month</span> },
  { key: "per3", header: <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;%&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> },
];

export const DASHBOARD_LABEL = [
  { key: "ytdrev", label: "YTD Revenue" },
  { key: "ytdlrmcshare", label: "YTD LRMC Share" },
  { key: "curmon", label: "Current Month" },
  { key: "train", label: "Train" },
  { key: "station", label: "Station" },
  { key: "others", label: "Others" },
];



export const APPROVAL_HEADERS = [
  { key: "client", header: "Client" },
  { key: "date_submitted", header: "Date Submitted" },
  { key: "pending", header: "Days Pending" },
];



export const TOP_FIVE_HEADERS = [
  { key: "client", header: "Client" },
  { key: "principal", header: "Principal" },
  { key: "iandp", header: "I&P" },
  { key: "balance", header: "Balance" },
  { key: "overdue", header: "Overdue (in Days)" },
];

export const FILL_RATE_HEADERS = [
  "Asset Name",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const DASHBOARD = [
  { key: "actual", header: "Actual" },
  { key: "budget", header: "Budget" },
  { key: "percent1", header: "%" },
  { key: "vs1", header: "vs.Last Year" },
  { key: "percent2", header: "%" },
  { key: "vs2", header: "vs.Previous Month" },
  { key: "vs3", header: "%" },
];

export const REVENUE_BREAKDOWN = [
  { key: "train", header: "Train" },
  { key: "stations", header: "Stations" },
  { key: "streetlvl", header: "Street Level Pillars" },
];
