import React, { useEffect, useState } from "react";
import ContractForm from "../../components/ContractForm";
import { useActionData, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { alert } from "../../utils/alert";
import { getAuthToken } from "../../utils/auth";
import axios from "axios";
import classes from "../../components/ContractForm.module.css";
const apiURL = process.env.REACT_APP_API_URL


const NewContractPage = () => {
  const [isSearching, setIsSearching] = useState(false);
  const [contractNumber, setContractNumber] = useState("");
  const [contractItem, setContractItem] = useState();

  const actionData = useActionData();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //useEffect that checks if new contract sbumission is successful
  useEffect(() => {
    if (actionData && actionData.status === 200) {
      //Display an alert
      alert("Contract Submitted Succesfully!", "green", dispatch);
      //Navigate back to track
      navigate("/contracts/track");
    }
  }, [actionData, navigate, dispatch]);

  //Change handler for contract number search bar
  const contractNumberInputChangeHandler = (event) => {
    setContractNumber(event.target.value);
  };

  //Search contract function that runs when enter key is pressed
  const searchContractHandler = async (event) => {
    if (event.key === "Enter") {
      setIsSearching(true);
      const token = getAuthToken();

      const requestBody = {
        sales_order_code: contractNumber.trim(),
      };

      //API function to search the database from the entered contract number
      const response = await axios
        .post(
          `${apiURL}/contract`,
          requestBody,
          { headers: { Authorization: `Bearer ${token}` } }
        )
        .then((response) => {
          console.log(response);
          return response;
        })
        .catch(() => {
          return { data: { contract: null } };
        });

      console.log(response);

      //Different contract item is set for different responses from the server
      if (response.status !== 200) {
        setContractItem(undefined);
        alert("An Error Occured", "red", dispatch);
      } else if (response.data.contract === null) {
        setContractItem(undefined);
        alert("Contract Not Found", "red", dispatch);
      } else if (response.data.contract.assets[0].contract_id !== "") {
        setContractItem(undefined);
        alert(
          "Contract Submission Exists! Please check Track Submissions",
          "red",
          dispatch,
          7000
        );
      } else {
        setContractItem({
          ...response.data.contract,
          start_date: new Date(response.data.contract.start_date)
            .toISOString()
            .split("T")[0],
          assets: response.data.contract.assets.map((asset) => {
            return {
              ...asset,
              asset_date_created: new Date(asset.asset_date_created)
                .toISOString()
                .split("T")[0],
              asset_live_date: new Date(asset.asset_live_date)
                .toISOString()
                .split("T")[0],
            };
          }),
        });
        alert("Contract Found!", "green", dispatch);
      }
      setIsSearching(false);
      setContractNumber("");
    }
  };

  return (
    <main>
      <div className={classes.search}>
        <input
          className={classes["search-bar"]}
          onKeyDown={searchContractHandler}
          onChange={contractNumberInputChangeHandler}
          placeholder="Search Contract Number"
          disabled={isSearching}
          value={contractNumber}
          multiple={false}
        />
        {isSearching && <div className={classes.spinner}></div>}
      </div>
      <ContractForm method="post" item={contractItem} />
    </main>
  );
};

export default NewContractPage;
