import React, { useEffect, useState } from "react";
//import classes from "./ApprovalTable.module.css";
import { TOP_FIVE_HEADERS } from "../../utils/table-headers";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import classes from "./TopFiveTable.module.css";
import { getTokenData } from "../../utils/auth";

const TopFiveTable = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [top5Data, setTop5Data] = useState([]);
  const { name, company } = getTokenData();

  useEffect(() => {
    fetch(`${apiURL}/analytics/top5ar_tbl`)
      .then((response) => response.json())
      .then((data) => {
        const formattedData = data.map((item) => ({
          ...item,
          principal: item.principal.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          balance: item.balance.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        }));

        console.log("Formatted Data:", formattedData);

        const sortedData = formattedData.sort((a, b) => b.overdue - a.overdue);

        // Log the sorted data to inspect the order
        console.log("Sorted Data:", sortedData);

        setTop5Data(sortedData.slice(0, 5));
      })
      .catch((error) => console.error("Error fetching API data:", error));
  }, []);

  // Navigate Open button to submissions
  const navigate = useNavigate();
  function buttonClickHandler() {
    navigate("/contracts/submissions");
  }

  // Download logs start

  const downloadCSV = () => {
    //Logs Download
    fetch(`${apiURL}/analytics/savedlhistory`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "content": `Top 5 - AR List downloaded by ${name}`,
            "company": `${company}`
        })
    })
    
       .then(response => {
        if (!response.ok) {
            throw new Error('Failed to log download');
          }
          console.log('Download logged successfully');
        })
        .catch(error => {
          console.error('Error logging download:', error);
        });
        //Logs Download end
      
      // Generate and download CSV
      const csvData = [TOP_FIVE_HEADERS.map((header) => header.header).join(",")]; // CSV header
    
    top5Data.forEach((item) => {
      const rowData = TOP_FIVE_HEADERS.map((header) => item[header.key]).join(
        ","
      );
      csvData.push(rowData);
    });

    const csvContent = csvData.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "top5_data.csv");
    console.log(`Top 5 - AR List downloaded by ${name} ${company}`)
  };

  // Download logs end

{/*
  // Function to download CSV
  const downloadCSV = () => {

    const csvData = [TOP_FIVE_HEADERS.map((header) => header.header).join(",")]; // CSV header

    top5Data.forEach((item) => {
      const rowData = TOP_FIVE_HEADERS.map((header) => item[header.key]).join(
        ","
      );
      csvData.push(rowData);
    });

    const csvContent = csvData.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "top5_data.csv");
  };
  
  // Download CSV end 
*/}

  return (
  <>
    <div className={classes.top}>
      <p className={classes.title}>Top 5 - AR List</p>
      <div>
        <button onClick={downloadCSV}>Download</button>{" "}
        <button onClick={buttonClickHandler}>Open List</button>
      </div>
    </div>
    <table className={classes.table}>
      <thead>
        <tr>
          <th>#</th>
          {TOP_FIVE_HEADERS.map((header) => (
            <th key={header.key}>{header.header}</th>
          ))}
        </tr>
      </thead>
      {top5Data.length > 0 ? (
        <tbody>
          {top5Data.map((data, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              {TOP_FIVE_HEADERS.map((header) => (
                <td
                  key={header.key}
                  style={{
                    textAlign:
                      header.key === "principal" || header.key === "balance"
                        ? "right"
                        : header.key === "iandp" || header.key === "overdue"
                        ? "center"
                        : "left",
                  }}
                >
                  {data[header.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      ) : (
          <tbody>
            <tr>
              <td colSpan={TOP_FIVE_HEADERS.length}>No data available</td>
            </tr>
          </tbody>
        )}
      </table>
      <p className={classes.bottom}>Click open to go to Approval Page</p>
    </>
  );
};

export default TopFiveTable;
