import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Papa from 'papaparse';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { getTokenData } from "../../utils/auth";
import { format } from 'date-fns';

const BVADownload = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [error, setError] = useState(null); // New state for error message
  const { name, company } = getTokenData();
  const apiURL = 'https://lrmcapi.retailgate.tech:20306';

  const fetchData = async () => {
    const start = startDate?.toISOString();
    if (!startDate || !endDate) {
      setError('Please select Start and End dates');
      return;
    }

    const end = endDate?.toISOString();

    try {
      const response = await fetch(`${apiURL}/analytics/exportrev_bva`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start_date: start,
          end_date: end,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        // Download CSV
        downloadCSV(data);
      } else {
        console.error('Error fetching data');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setError(null); // Clear any previous error
  };

  const downloadCSV = (data) => {
    //Logs Download
    fetch(`${apiURL}/analytics/savedlhistory`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          "content": `BVA - Current Month Revenue downloaded by ${name}`,
          "company": `${company}`
      })
  })
  
     .then(response => {
      if (!response.ok) {
          throw new Error('Failed to log download');
        }
        console.log('Download logged successfully');
      })
      .catch(error => {
        console.error('Error logging download:', error);
      });
      //Logs Download end

    try {
      const transformedData = [];
  
      // Iterate over categories in "Revenue" and "Fill Rate"
      for (const categoryType in data) {
        if (Object.hasOwnProperty.call(data, categoryType)) {
          const categories = data[categoryType];
  
          let categoryTypeAdded = false;
  
          // Iterate over individual categories
          for (const category in categories) {
            if (Object.hasOwnProperty.call(categories, category)) {
              const categoryData = categories[category];
  
              // Check if categoryData is an object with total property
              if (categoryData && categoryData.total) {
                if (!categoryTypeAdded) {
                  // Add the categoryType label only once for each group
                  transformedData.push({
                    CategoryType: categoryType,
                    Category: '',
                    Actual: '',
                    Budget: '',
                  });
                  categoryTypeAdded = true;
                }
  
                const totalData = categoryData.total;
  
                // Add headers for each category
                transformedData.push({
                  CategoryType: '',
                  Category: category,
                  Actual: totalData.actual,
                  Budget: totalData.budget,
                });
  
                // Flatten subcategories
                for (const subcategory in categoryData) {
                  if (subcategory !== 'total' && Object.hasOwnProperty.call(categoryData, subcategory)) {
                    const subcategoryData = categoryData[subcategory];
                    transformedData.push({
                      CategoryType: '',
                      Category: `${category} - ${subcategory}`,
                      Actual: subcategoryData.actual,
                      Budget: subcategoryData.budget,
                    });
                  }
                }
              } else {
                // Handle differently if categoryData is not as expected
                console.error(`Data structure for "${category}" in ${categoryType} is not as expected.`);
              }
            }
          }
        }
      }
  
      // Create CSV
      const csvData = Papa.unparse(transformedData, {
        header: true,
        quotes: true,
      });
  
      // Create and trigger download
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
  
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'bva_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Error parsing data for CSV:', error);
    }
  };
  

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={(newValue) => setStartDate(newValue)}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              margin="dense"
              helperText=""
              sx={{
                "& .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root": {
                  marginTop: "16px",
                },
              }}
            />
          )}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={(newValue) => setEndDate(newValue)}
          renderInput={(props) => (
            <TextField
              {...props}
              variant="standard"
              margin="dense"
              helperText=""
              sx={{
                "& .MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root": {
                  margin: "16px",
                  padding: "16px",
                  height: "75px",
                },
                "& .MuiButtonBase-root.MuiIconButton-root" : {
                  padding: "4px !important"
                }
              }}
            />
          )}
        />
      </LocalizationProvider>

      <Button
        variant="contained"
        onClick={fetchData}
        style={{
          backgroundColor: "#4472c4",
          color: "white",
          border: "none",
          borderRadius: "5px",
          padding: "2px 26px",
          paddingLeft: "20px",
          paddingRight: "20px",
          fontWeight: "400",
          marginRight: "16px",
          fontSize: "14px",
          marginTop: "16px",
          textTransform: "none",
          width: "100%"
        }}
      >
        Download
      </Button>
      {/* Display error message */}
      {error && (
        <div style={{ color: 'red', marginTop: '8px' , fontSize: '15px'}}>
          {error}
        </div>
      )}
    </div>
  );
};

export default BVADownload;
