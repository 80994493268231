import React from "react";
import { Outlet } from "react-router-dom";
import ProfileNavigation from "../../components/Navigation/ProfileNavigation";

const ProfileRoot = () => {
  return (
    <>
      <ProfileNavigation />
      <Outlet />
    </>
  );
};

export default ProfileRoot;
