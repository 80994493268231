import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import DashboardNavigation from "../../components/Navigation/DashboardNavigation";

const DashboardRoot = () => {
  const location = useLocation();
  const path = location.pathname;
  const isPathDashboard = "/dashboard" !== path;
  return (
    <>
      {isPathDashboard && <DashboardNavigation path={path} />}
      <Outlet />
    </>
  );
};

export default DashboardRoot;
