import React, { useEffect, useState, useRef } from "react";
import classes from "./styles/DashboardPage.module.css";
import RadioNavigation from "../../components/Dashboard/RadioNavigation";
import DashboardTable from "../../components/Dashboard/DashboardTable";
import PieChart from "../../components/Dashboard/PieChart";
import ApprovalTable from "../../components/Dashboard/ApprovalTable";
import TopFiveTable from "../../components/Dashboard/TopFiveTable";
import { getTokenData } from "../../utils/auth";

const DashboardPage = () => {
  const apiURL = process.env.REACT_APP_API_URL;
  const [aspectRatio, setAspectRatio] = useState(null);
  const [reload, setReload] = useState(false);
  const chartRef = useRef(null); 
  const { name, company } = getTokenData();

  const [isPieChart, setIsPieChart] = useState({});
  //Download Button for Pie Chart
  const handleDownloadClick = (chartRef) => {
    const dataURL = chartRef.current.getDataURL({
      type: "png",
      pixelRatio: 2, 
      backgroundColor: "#fff", 
    });
  
    // Create a link and trigger the download
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "pie_chart.png";
    link.click();

    fetch(`${apiURL}/analytics/savedlhistory`, {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          "content": `Pie Chart downloaded by ${name}`,
          "company": `${company}`
      })
  })
  
     .then(response => {
      if (!response.ok) {
          throw new Error('Failed to log download');
        }
        console.log('Download logged successfully');
      })
      .catch(error => {
        console.error('Error logging download:', error);
      });
  };
  useEffect(() => {
    fetch(`${apiURL}/analytics/rev_pi`)
      .then((response) => response.json())
      .then((data) => setIsPieChart(data))
      .catch((error) => console.error("Error fetching API data:", error));
  }, []);



  //useEffect that checks if the user is resizing
  useEffect(() => {
    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      const newAspectRatio = innerWidth / innerHeight;
      setAspectRatio(newAspectRatio);
    };

    handleResize();

    const resizeListener = () => {
      handleResize();
      setReload((r) => !r);
    };

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    let identifier;

    if (aspectRatio !== null) {
      identifier = setTimeout(() => {
        setReload((r) => !r);
      }, 100);
    }

    return () => {
      clearTimeout(identifier);
    };
  }, [aspectRatio, setReload]);

  return (
    <main className={classes.grid}>
      <div className={classes.table}>
        <div>
        <DashboardTable />
        </div>
      </div>
      <div>
        <div className={classes.top}>
          <button className={classes.dButton2} onClick={() => handleDownloadClick(chartRef)}>Download</button>
        </div>
        <div className={classes.border}>
          <PieChart key={reload} chartData={isPieChart} chartRef={chartRef} />
        </div>
      </div>
      <div className={classes.section}style={{ }}>
        <div className={classes.border} style={{ marginRight: '20px' }}>
          <RadioNavigation />
        </div>
        <div className={classes.border} style={{ marginLeft: '-190px' }}>
          <TopFiveTable />
        </div>
      </div>
      <div className={classes.border} style={{ display: 'flex' }}>
        <ApprovalTable />
      </div>
    </main>
  );
};

export default DashboardPage;