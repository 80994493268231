import { redirect } from "react-router-dom";
import CryptoJS from "crypto-js";

//Get aut token from session storage
export const getAuthToken = () => {
  const token = sessionStorage.getItem("token");
  return token;
};

//Get user token from session storage
export const getUserToken = () => {
  const user = sessionStorage.getItem("user");
  return user;
};


//Checks if a user has an auth token on initial load
export const tokenLoader = () => {
  const token = getAuthToken();
  if (!token) {
    return redirect("/login");
  }
  return token;
};

//Decrypt the user token data
export const getTokenData = () => {
  const token = getAuthToken();
  if (!token) {
    console.log("GET TOKEN REDIRECT MISSING TOKEN");
    return redirect("/login");
  }
  const userToken = getUserToken();
  if (!userToken) {
    console.log("USER ROLE MISSING");
    return redirect("/login");
  }

  const decryptedUser = CryptoJS.AES.decrypt(userToken, token).toString(
    CryptoJS.enc.Utf8
  );

  const { role, uid, company, email, name } = JSON.parse(decryptedUser);

  return {
    role: role,
    uid: uid,
    company: company,
    email: email,
    name: name,
  };
};

//Protect the route on each navigation or load
export const protectRoute = (company_role, path, navigate, submit) => {
  const normalizedPath = path.replace(/\/$/, "");
  if (path === "/logout") {
    return navigate("/");
  }

  const navigationRules = {
    ADMIN_ADMIN: {
      "": "/users",
      "": "/dashboard",
      "/contracts": "/contracts/submissions",
      "": "/contracts/track",
    },
    UTASI_ENCODER: {
      "": "/contracts/track",
      "/contracts": "/contracts/track",
      "/contracts/submissions": null,
      "/users": null,
    },
    UTASI_MANAGER: {
      "": "/dashboard",
      "/contracts": "/contracts/submissions",
      "/contracts/track": null,
      "/users": null,
    },
    LRMC_MANAGER: {
      "": "/dashboard",
      "/contracts": "/contracts/submissions",
      "/contracts/track": null,
      "/users": null,
    },
  };
  if (
    company_role === "UTASI_MANAGER" ||
    company_role === "LRMC_MANAGER" ||
    company_role === "UTASI_ENCODER" ||
    company_role === "ADMIN_ADMIN"
  ) {
    const rule = navigationRules[company_role]?.[normalizedPath];
    if (rule !== undefined) {
      if (rule === null) {
        return submit(null, { action: "/logout", method: "post" });
      } else {
        return navigate(rule);
      }
    }
  } else {
    return submit(null, { action: "/logout", method: "post" });
  }
};
