import React from "react";
import { useLoaderData, useLocation } from "react-router-dom";
import ChangePasswordForm from "../components/User/ChangePasswordForm";
import { getTokenData } from "../utils/auth";
import classes from "./Background.module.css";

const ChangePasswordPage = () => {
  const { maskedEmail, email } = useLoaderData();
  const location = useLocation();
  const previousPath = location.state?.from ?? "";
  let otpValidity;
  if (previousPath === "/login") {
    otpValidity = true;
  }
  return (
    <main className={classes.main}>
      <div className={classes.shadow}>
        <ChangePasswordForm
          email={email}
          maskedEmail={maskedEmail}
          otpValidity={otpValidity}
        />
      </div>
    </main>
  );
};

export default ChangePasswordPage;

//loader to mask the current user's email
export const loader = () => {
  const { email } = getTokenData();
  const maskedEmail = email.replace(/^(.).*([^\s])@/, "$1****$2@");

  return { maskedEmail, email };
};
