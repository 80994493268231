import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import classes from "./DisapproveModal.module.css";
import { useSubmit } from "react-router-dom";
import { getAuthToken } from "../../utils/auth";
import axios from "axios";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const Overlay = (props) => {
  const [feedback, setFeedback] = useState("");
  const [encoder, setEncoder] = useState();
  const [feedbackIsTouched, setFeedbackIsTouched] = useState(false);
  const [encoderIsTouched, setEncoderIsTouched] = useState(false);
  const [encoderHasError, setEncoderHasError] = useState(false);
  const [feedbackHasError, setFeedbackHasError] = useState(false);
  const [encoders, setEncoders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const submit = useSubmit();
  const apiURL = process.env.REACT_APP_API_URL


  //useEffect to get list of encoders in the database
  useEffect(() => {
    const getEncoders = async () => {
      const token = getAuthToken();
      const response = await axios.get(
        `${apiURL}users/encoder`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEncoders(response.data);
    };

    getEncoders();
  }, [setEncoders]);

  //Changes the list of encoders to match the search query
  const filteredEncoders = encoders.filter((encoder) => {
    return encoder.fullname.toLowerCase().includes(searchQuery.toLowerCase());
  });

  const selectEncoderClickHandler = (encoder) => {
    setEncoder(encoder);
    setSearchQuery("");
  };

  //Change handler for search bar
  const searchEncoderChangeHandler = (event) => {
    if (event.target.value.charAt(0) === " ") {
      event.target.value = event.target.value.trimStart();
    }
    setSearchQuery(event.target.value);
  };

  //Change handler for feedback textarea
  const feedbackMessageChangeHandler = (event) => {
    if (event.target.value.charAt(0) === " ") {
      event.target.value = event.target.value.trimStart();
    }
    if (event.target.value.charAt(0) === "\n") {
      event.target.value = event.target.value.substring(1);
    }
    setFeedback(event.target.value);
  };

  //functions for onBlur event
  function selectEncoderBlurHandler() {
    setEncoderIsTouched(true);
  }
  function feedbackMessageBlurHandler() {
    setFeedbackIsTouched(true);
  }

  //Validation useEffect, Checks if there is no selected encoder or no feedback given
  useEffect(() => {
    if (feedback.trim() === "" && feedbackIsTouched) {
      setFeedbackHasError(true);
    } else {
      setFeedbackHasError(false);
    }
    if (searchQuery.trim() === "" && encoderIsTouched) {
      setEncoderHasError(true);
    } else {
      setEncoderHasError(false);
    }
  }, [feedback, feedbackIsTouched, searchQuery, encoderIsTouched]);

  const submitHandler = async (event) => {
    event.preventDefault();

    //Second validation check depending on the role
    if (props.role === "LRMC_MANAGER") {
      if (feedback.trim() === "") {
        setFeedbackIsTouched(true);
        return;
      }
    } else if (props.role === "UTASI_MANAGER") {
      if (feedback.trim() === "" && encoder === undefined) {
        setEncoderIsTouched(true);
        setFeedbackIsTouched(true);
        return;
      }
    }
    let status;

    //Sets the status for the contract depending on the role
    if (props.role === "UTASI_MANAGER") {
      status = "RETURNED";
    } else if (props.role === "LRMC_MANAGER") {
      status = "DISAPPROVED";
    } 



    if (status) {
      //Submit function to call the action in /contracts/submissions
      submit(
        {
          status: status,
          contractNumber: props.contractNumber,
          feedback: feedback,
          submittedBy: encoder ? encoder.user_id : props.submittedBy,
          name: encoder ? encoder.fullname : undefined,
        },
        { action: "/contracts/submissions", method: "patch" }
      );
    }
    props.onClose();
  };

  return (
    <div className={classes.modal}>
      <form className={classes["modal-content"]} onSubmit={submitHandler}>
        <h2>
          {props.role === "UTASI_MANAGER"
            ? "CONTRACT RETURN: "
            : "CONTRACT DISAPPROVAL: "}
          <p>{props.contractNumber}</p>
        </h2>
        <hr />
        {props.role === "UTASI_MANAGER" && (
          <div className={classes.search}>
            {!encoder && encoderHasError && (
              <p className={classes["error-text"]}>Select Encoder</p>
            )}
            {!encoder && (
              <input
                type="text"
                className={`${classes["search-bar"]} ${classes.input} ${
                  encoderHasError && classes.error
                }`}
                value={searchQuery}
                onChange={searchEncoderChangeHandler}
                onBlur={selectEncoderBlurHandler}
                placeholder="Search Encoder"
                autoComplete="off"
              />
            )}
            {searchQuery !== "" && (
              <ul className={classes.list}>
                {filteredEncoders.length === 0 ? (
                  <li>No Encoder Found</li>
                ) : (
                  filteredEncoders.slice(0, 5).map((encoder, index) => (
                    <li
                      key={index}
                      onClick={() => selectEncoderClickHandler(encoder)}
                    >
                      {encoder.fullname}
                    </li>
                  ))
                )}
              </ul>
            )}
            {encoder && (
              <div className={classes.selected}>
                <p>{encoder.fullname}</p>
                <button onClick={() => setEncoder(undefined)}>x</button>
              </div>
            )}
          </div>
        )}
        {feedbackHasError && (
          <p className={classes["error-text"]}>Enter Feedback</p>
        )}
        <textarea
          type="text"
          className={`${classes.textarea} ${classes.input} ${
            feedbackHasError && classes.error
          }`}
          onChange={feedbackMessageChangeHandler}
          onBlur={feedbackMessageBlurHandler}
          value={feedback}
          placeholder="Enter Feedback"
        />
        <hr />
        <div className={classes.actions}>
          <button className={classes["btn-submit"]}>Submit</button>
          <button className={classes["btn-close"]} onClick={props.onClose}>
            Close
          </button>
        </div>
      </form>
    </div>
  );
};
const DisapproveModal = (props) => {
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        document.getElementById("backdrop-root")
      )}
      {ReactDOM.createPortal(
        <Overlay
          onClose={props.onClose}
          contractNumber={props.contractNumber}
          role={props.role}
          submittedBy={props.submittedBy}
        />,
        document.getElementById("modal-root")
      )}
    </>
  );
};

export default DisapproveModal;
